<template>
  <section class="outContainer" id="home">
      <!-- 标题 -->
      <header>
          <p class="title">乐电智慧充电服务管理平台</p>
      </header>
      <main>
        <div class="topData">
          <!-- 统计、实时数据 -->
          <dv-border-box-12 style="height: 100%;width: 29%;display: flex;flex-wrap: wrap;">
            <div class="eachOne" v-for="(item,index) in statisticalData" :key="index">
                <img :src="item.img" alt="">
                <p>
                    <span>{{ item.name }}</span>
                    <b :style="item.style">{{ item.data }}</b>
                </p>
            </div>
          </dv-border-box-12>
          <!-- 地图 -->
          <div class="map" style="height: 100%;width: 40%; border-radius:25px;overflow: hidden;">
            <screen></screen>
          </div>
          <!-- 在充设备实时数据 -->
          <dv-border-box-12 style="height: 100%;width: 29%;">
            <div id="carouselTable" style="height: 100%;width: 100%;display: flex;flex-direction: column;overflow: auto;justify-content: space-between;">
              <h2 style="text-align: left;margin-bottom: 10px;">在充设备实时数据</h2>
              <div style="flex: 1;overflow: auto">
                <dv-scroll-board :config="chargingEuipData" style="height: calc(100% - 20px);"/>
                <!-- <el-table :data="tableData" style="width: 100%" height="100%">
                  <el-table-column prop="name" label="姓名"></el-table-column>
                  <el-table-column prop="age" label="年龄"></el-table-column>
                  <el-table-column prop="gender" label="性别"></el-table-column>
                </el-table> -->
              </div>
            </div>
          </dv-border-box-12>
        </div>
        <div class="botData">
          <!-- 场所收入top5 -->
          <dv-border-box-12 style="height: 100%;width: 29%;">
            <div id="topFiveChart" style="height: 100%;width: 100%;"></div>
          </dv-border-box-12>
          <!-- 每日用电量 -->
          <dv-border-box-12 style="height: 100%;width: 40%;">
            <div id="electricChart" style="height: 100%;width: 100%;"></div>
          </dv-border-box-12>
          <!-- 近七日收益趋势 -->
          <dv-border-box-12 style="height: 100%;width: 29%;">
            <div id="incomeChart" style="height: 100%;width: 100%;"></div>
          </dv-border-box-12>
        </div>
      </main>
      <i class="el-icon-full-screen" @click="fullScreen"></i>
  </section>
</template>
<script>
import screen from './chargePileMap.vue';
import * as echarts from 'echarts';
import 'echarts/extension/bmap/bmap';
// import { GetShowData } from '@/api/public.js';
export default{
  data(){
      return{
        tableData:[
          {name:'lmy',age:18,gender:'女'},
          {name:'lmy',age:19,gender:'女'},
          {name:'lmy',age:20,gender:'女'},
          {name:'lmy',age:21,gender:'女'},
          {name:'lmy',age:22,gender:'女'},
          {name:'lmy',age:23,gender:'女'},
          {name:'lmy',age:24,gender:'女'},
          {name:'lmy',age:25,gender:'女'},
          {name:'lmy',age:26,gender:'女'},
          {name:'lmy',age:27,gender:'女'},
          {name:'lmy',age:28,gender:'女'},
          {name:'lmy',age:29,gender:'女'},
          {name:'lmy',age:30,gender:'女'},
          {name:'lmy',age:31,gender:'女'},
          {name:'lmy',age:32,gender:'女'},
        ],
          topFiveData:{ // 场地收入前五
            data:[
              { name: '光阳国际',value: 3562.21 },
              { name: '紫金梦想广场',value: 2568 },
              { name: '浜河海棠景轩',value: 2241 },
              { name: '近江八园',value: 1900 },
              { name: '新城时代广场',value: 1765 },
            ],
            showValue:true
          },
          electricOption:{ // 每日用电量
            title: {
              text: '每日用电量',
              style:{
                fill:'#fff'
              }
            },
            xAxis: {
              name: '日期',
              data: ['2023-08-12', '2023-08-13', '2023-08-14', '2023-08-15', '2023-08-16', '2023-08-17', '2023-08-18','2023-08-12', '2023-08-13', '2023-08-14', '2023-08-15', '2023-08-16', '2023-08-17', '2023-08-18','2023-08-12', '2023-08-13', '2023-08-14', '2023-08-15', '2023-08-16', '2023-08-17', '2023-08-18','2023-08-12', '2023-08-13', '2023-08-14', '2023-08-15', '2023-08-16', '2023-08-17', '2023-08-18'],
              nameTextStyle: {
                fill: '#fff',
                fontSize: 10
              },
              axisLine:{ // x坐标轴
                style: {
                  stroke: '#fff',
                  lineWidth: 1
                }
              },
              axisTick:{
                show:false,
                style: {
                  stroke: '#fff',
                  lineWidth: 1
                }
              },
              axisLabel:{
                style: {
                  fill: '#fff',
                  fontSize: 10,
                  rotate: 0
                },
              },
              interval:200
            },
            yAxis: {
              splitLine:{
                  show:false
                },
              name: '用电量（kWh）',
              data: 'value',
              nameTextStyle: {
                fill: '#fff',
                fontSize: 10
              },
              axisLine:{
                style: {
                  stroke: '#fff',
                  lineWidth: 1
                }
              },
              axisTick:{
                style: {
                  stroke: '#fff',
                  lineWidth: 1
                }
              },
              axisLabel:{
                style: {
                  fill: '#fff',
                  fontSize: 10,
                  rotate: 0
                }
              }
            },
            series: [
              {
                data:[900,1200,865,875,968,888,1000,1100,1020,900,900,1200,865,875,968,888,1000,1100,1020,900,900,1200,865,875,968,888,1000,1100,1020,900,],
                type: 'bar',
                label: {
                  show: true,
                  position: 'center',
                  offset: [0, 0],
                  style: {
                    fill: '#fff'
                  }
                },
                gradient: {
                  color: ['#37a2da', '#67e0e3'],
                },
                barStyle:{
                  barBorderRadius: [20, 20, 0 ,0],
                }
              }
            ]
          },
          statisticalData:[ // 统计、实时数据 
              {
                  name:'累计收入（元）',
                  data:'49500.70',
                  img:require('../../static/img/totalAccount.png'),
                  style:"color:#ea9518",
              },
              {
                  name:'订单总数（笔）',
                  data:'8394',
                  img:require('../../static/img/orderAccount.png'),
                  style:"color:#ea9518",
              },
              {
                  name:'设备总数 / 枪口总数',
                  data:'84',
                  img:require('../../static/img/chargingAccount.png'),
                  style:'color:#ea9518'
              },
              {
                  name:'注册用户总数（人）',
                  data:'1908',
                  img:require('../../static/img/userAccount.png'),
                  style:'color:#ea9518'
              },
              {
                  name:'实时收入(元)',
                  data:'54.3',
                  img:require('../../static/img/todayAccount.png'),
                  style:'color:#46af4c'
              },
              {
                  name:'实时充电设备数',
                  data:'4',
                  img:require('../../static/img/chargeAccount.png'),
                  style:'color:#46af4c'
              },
          ],
          incomeTrend:{  // 收入趋势
              title: {
                text: '收入趋势',
                style:{
                  fill:'#fff'
                }
              },
              xAxis: {
                name: '日期',
                data: ['2023-08-12', '2023-08-13', '2023-08-14', '2023-08-15', '2023-08-16', '2023-08-17', '2023-08-18'],
                nameTextStyle: {
                  fill: '#fff',
                  fontSize: 10
                },
                axisLine:{
                  style: {
                    stroke: '#fff',
                    lineWidth: 1
                  }
                },
                axisTick:{
                  style: {
                    stroke: '#fff',
                    lineWidth: 1
                  }
                },
                axisLabel:{
                  style: {
                    fill: '#fff',
                    fontSize: 10,
                    rotate: 0
                  }
                }
              },
              yAxis: {
                name: '收益',
                data: 'value',
                nameTextStyle: {
                  fill: '#fff',
                  fontSize: 10
                },
                axisLine:{
                  style: {
                    stroke: '#fff',
                    lineWidth: 1
                  }
                },
                axisTick:{
                  show:false,
                  style: {
                    stroke: '#fff',
                    lineWidth: 1
                  }
                },
                axisLabel:{
                  style: {
                    fill: '#fff',
                    fontSize: 10,
                    rotate: 0
                  }
                },
              },
              series: [
                {
                  data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
                  type: 'line',
                  smooth: true,
                  label: {
                    show: true,
                    formatter: '{value}',
                    style:{
                      fill:'#fff'
                    }
                  },
                  lineArea: {
                    show: true,
                    gradient: ['rgba(55, 162, 218, 0.6)', 'rgba(55, 162, 218, 0)']
                  }
                }
              ]
          },
          chargingEuipData:{ // 在充设备实时数据
            header: ['设备号','端口号', '功率(W)', '耗电量(KW)','时长（分）'],
            align:['center','center','center','center','center',],
            columnWidth:[200,100,100,100,100,100],
            data: [
    //           [<el-tooltip class="item" effect="dark" content="Top Left 提示文字" placement="top-start">
    //   <span>上左</span>
    // </el-tooltip>],
              ['20018002',2,5180.2,21,248],
              ['20018002',2,5180.2,21,248],
              ['20018002',2,5180.2,21,248],
              ['20018002',2,5180.2,21,248],
              ['20018002',2,5180.2,21,248],
              ['20018002',2,5180.2,21,248],
              ['20018002',2,5180.2,21,248],
              ['20018002',2,5180.2,21,248],
            ]
          },
          timer:null,
          isFullScreen:false,  // 是否全屏显示
      }
  },
  components:{screen},
  mounted(){
    let token = this.$route.query.token;
    token = 'eyJ0cCI6Ik1ENSJ9.eyJqdGkiOiI0NzkzYzVlMS0yNTRkLTRmYWYtYTRiMi1hZjM4MTg5NzQxODYiLCJleHAiOiIyMDIzLTA2LTE5VDEzOjQ4OjE0Ljk5MTI4NiswODowMCIsIm5iZiI6IjIwMjMtMDYtMTlUMTA6NDg6MTQuOTkxMjg2KzA4OjAwIiwiaWF0IjoiMjAyMy0wNi0xOVQxMDo0ODoxNC45OTEyODYrMDg6MDAiLCJ1c2VyaWQiOjExLCJsb2dpbm5hbWUiOiJsbXkiLCJ1c2VybmFtZSI6IkxteSIsInB3ZCI6Ijk2RTc5MjE4OTY1RUI3MkM5MkE1NDlERDVBMzMwMTEyIiwicm9sZWlkIjoxLCJwaWQiOjB9.be9dbb3719d5b27b'; // 测试用token
    sessionStorage.setItem('token',token);
    let role=this.$route.query.role;
    sessionStorage.setItem('role',role);
    this.role=sessionStorage.getItem('role');
    this.init();
    this.getElectricInfo();
    this.getIncome();
    this.getTopFive();
    this.timer = setInterval(()=>{
      this.init();
    },30000)
  },
  beforeDestroy(){
    clearInterval(timer);
  },
  methods:{
    // 全屏显示、退出全屏
    fullScreen(){
      let all = document.getElementById("home");
      if(!this.isFullScreen){
        if (all.requestFullscreen) {
          all.requestFullscreen();
        } else if (all.mozRequestFullScreen) {
          all.mozRequestFullScreen();
        } else if (all.webkitRequestFullscreen) {
          all.webkitRequestFullscreen();
        } else if (all.msRequestFullscreen) {
          all.msRequestFullscreen();
        }
      }else{
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
      }
      this.isFullScreen = !this.isFullScreen;
    },
    // 在充设备实时数据
    getChargingData(data){
      let datas = [];
      data.forEach(equip => {
        let eachEquipData = [equip.EquipmentNum,equip.Port,equip.Power.toFixed(2),equip.ElectricNum.toFixed(2),equip.Time];
        datas.push(eachEquipData);
      })
      this.$nextTick(()=>{
        this.chargingEuipData = { // 在充设备实时数据
          header: ['设备号','端口号', '功率(KW)', '耗电量(度)','时长（分）'],
          align:['center','center','center','center','center',],
          data: datas
        }
      })
    },
    // 收入top5数据
    getTopFive(data){
      var chartDom = document.getElementById('topFiveChart');
      var myChart = echarts.init(chartDom);
      var option;
      let ydata = ['光阳国际', '紫金梦想广场', '浜河海棠景轩', '近江八园', '新城时代广场', ];
      let xdata = [3562.21, 2568, 2241, 1900, 1765, ];
      option = {
        title:{
          text:'场地月收入Top5',
          textStyle:{
            color:'#fff'
          }
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "80",
          right: "20",
          bottom: "20",
          top: "30",
          containLabel: false,
        },
        xAxis: {
          type: "value",
          show: false,
          nameTextStyle: {
            color: 'rgba(255, 255, 255, 0.8)',
            align: 'right'
          },
        },
        yAxis: {
          type: "category",
          data: ydata.reverse(),
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 70,
            width: 60,
            align: "left",
            overflow: "truncate",
            formatter: function (value, index) {
              let ind = index + 1;
              let count = index == 0 ? 5 :(index == 1 ? 4 :(index == 2 ? 3 : (index == 3 ? 2 : 1)));
              let data = `{${ind == 5 ? 'one' : (ind == 4 ? 'two' :(ind == 3 ? 'three' : 'five' ))}|${count}}{${ind == 1 ? 'a' : (ind == 2 ? 'b' :(ind == 3 ? 'c' : 'd' ))}|${value}}`;
              return data;
            },
            rich: { // 富文本
              a: {
                color: "#59c9f9",
              },
              b: {
                color: "#59c9f9",
              },
              c: {
                color: "#59c9f9",
              },
              d: {
                color: "#59c9f9",
              },
              // 第一名
              one: {
                backgroundColor: "#E86452",
                color: "white",
                width: 12,
                height: 16,
                padding: [1, 0, 0, 5],
                borderRadius: 10,
                fontSize: 11,
              },
              // 第二名
              two: {
                backgroundColor: "#FF9845",
                color: "white",
                width: 12,
                height: 16,
                padding: [1, 0, 0, 5],
                borderRadius: 10,
                fontSize: 11,
              },
              // 第三名
              three: {
                backgroundColor: "#F6BD16",
                color: "white",
                width: 12,
                height: 16,
                padding: [1, 0, 0, 5],
                borderRadius: 10,
                fontSize: 11,
              },
              // 一位数
              four: {
                backgroundColor: "rgba(0,0,0,0.15)",
                color: "white",
                width: 12,
                height: 16,
                padding: [1, 0, 0, 5],
                borderRadius: 10,
                fontSize: 11,
              },
              // 两位数
              five: {
                backgroundColor: "rgba(0,0,0,0.15)",
                color: "white",
                width: 16,
                height: 16,
                padding: [1, 0, 0, 1],
                borderRadius: 10,
                fontSize: 11,
              },
            },
          },
        },
        series: [
          {
            type: "bar",
            showBackground: true,
            label: {
              show: true,
              position: "inside",
              color: "#fff",
            },
            barWidth: 15,
            itemStyle: {
              color: "#5B8FF9",
              borderRadius:[0,5,5,0],
            },
            data: xdata.reverse(),
          }, 
        ],
      };
      window.addEventListener('resize', () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    // 收入趋势图标绘制
    getIncome(data){
      var chartDom = document.getElementById('incomeChart');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title:{
          text:'近七日收入',
          textStyle:{
            color:'#fff'
          }
        },
        tooltip: { // 鼠标悬浮，显示具体数值
          trigger: 'axis',
        },
        grid: {
          left: "50",
          right: "20",
          bottom: "40",
          top: "40",
          containLabel: false,
        },
        xAxis: {
          type: 'category',
          data: ['2024-03-11', '2024-03-12', '2024-03-13', '2024-03-14', '2024-03-15', '2024-03-16', '2024-03-17'],
          axisLabel:{
            color:'#fff'
          }
        },
        yAxis: {
          title:'收益（元）',
          type: 'value',
          axisLabel:{
            color:'#fff'
          }
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])
            },
          }
        ]
      };
      window.addEventListener('resize', () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    // 每日用电量图表绘制
    getElectricInfo(){
      let chartDom = document.getElementById('electricChart');
      let myChart = echarts.init(chartDom);
      let option;
      let dataAxis = ['2024-01-01', '2024-01-02', '2024-01-03', '2024-01-04', '2024-01-05', '2024-01-06', '2024-01-07', '2024-01-08', '2024-01-09', '2024-01-10', '2024-01-11', '2024-01-12', '2024-01-13', '2024-01-14', '2024-01-15', '2024-01-16', '2024-01-17', '2024-01-18', '2024-01-19', '2024-01-20', '2024-01-21', '2024-01-22', '2024-01-23', '2024-01-24', '2024-01-25', '2024-01-26', '2024-01-27', '2024-01-28', '2024-01-29', '2024-01-30', '2024-01-31'];
      let data = [220, 182, 191, 234, 290, 330, 310, 123, 552, 321, 90, 149, 210, 122, 133, 334, 198, 123, 125, 220, 123, 442, 321, 90, 149, 210, 122, 133, 334, 198, 123];
      option = {
        title: {
          text: '每日用电量',
          textStyle:{
            color:'#fff'
          }
        },
        tooltip: { // 鼠标悬浮，显示具体数值
          trigger: 'axis',
        },
        grid: {
          left: "50",
          right: "20",
          bottom: "40",
          top: "40",
          containLabel: false,
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {
            color: '#fff'
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          z: 10
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#fff'
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            type: 'bar',
            showBackground: true,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' }
                ])
              }
            },
            data: data
          }
        ]
      };
      const zoomSize = 6;
      myChart.on('click', function (params) {
        console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
        myChart.dispatchAction({
          type: 'dataZoom',
          startValue: dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
          endValue:
            dataAxis[Math.min(params.dataIndex + zoomSize / 2, data.length - 1)]
        });
      });
      // 监听窗口大小变化
      window.addEventListener('resize', () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    // 获取统计数据
    async init(){
      // await GetShowData().then(res=>{
      //   if(res.Success){
      //     this.statisticalData[0].data = res.Data.SumActualAmount;
      //     this.statisticalData[1].data = res.Data.OrderNum;
      //     this.statisticalData[2].data = `${res.Data.SumEquipmentNum} / ${res.Data.SumPortCount}`;
      //     this.statisticalData[3].data = res.Data.SumWUser;
      //     this.statisticalData[4].data = res.Data.SumDayAmount;
      //     this.statisticalData[5].data = res.Data.SumProcess;
      //     // this.getElectric(res.Data.orderElectricNumCharts);
      //     this.getIncome(res.Data.actualAmountCharts);
      //     this.getChargingData(res.Data.realTimeOrderDatas);
      //   }
      // })
    },
  },
}
</script>
<style scoped lang='scss'>
.el-icon-full-screen{
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  color: #fff;
  z-index: 10000;
  cursor: pointer;
  font-size: 20px;
}
*{
  box-sizing: border-box;
  margin: 0;
}
// 头部标题
header{
  position: relative;
  width: calc(100% - 20px);
  margin: 0 auto;
  height: 78px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../static/back/header.png") no-repeat;
  background-size: 100% 100%;
  .title{
      font-size: 0.15rem;
      font-family: "PANGMENZHENGDAO-3";
      color: #ffffff;
      line-height: 35px;
      letter-spacing:3px
  }
}
// 内容区
main{
  width: 100%;
  height: calc(100% - 78px) !important;
  margin: auto;
  background-position: center;
  background-size: 100% 100%;
  position: relative;
  padding:10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .secondTitle{
      width: 220px;
      height: 43px;
      background-image: url("../../static/back/secondTitle.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding-left: 60px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .titleContent{
          position: relative;
          letter-spacing: 1px;
          font-family: 'BQH';
          font-size: 0.09rem;
      }
  }
}
// 外层容器
.outContainer{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    color: #fff;
    background-image: url("../../static/back/bigBack.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
}
// 小框内容区
/deep/ .border-box-content{  
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
}
// 上部分
.topData{ 
  height: 49%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .eachOne{
          width: 50%;
          height: 0.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
              height:0.25rem;
              width:0.25rem;
              margin-right: 10px;
          }
          p{
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              font-size: 0.07rem;
              align-items: center;
              span{
                  font-size: 0.07rem;
              }
              b{
                  font-size: 0.155rem;
                  text-align: left;
              }
          }
  }
  .borderRight{
          height: 0.3rem;
          border: solid #fff 1px;
          margin: 0 10px;
  }
  .realtime{
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 0.07rem;
          p{
              width: 100%;
          }
          span{
              display: inline-block;
              width: 50%;
              text-align: center;
              &:nth-child(2){
                  color: #46af4c;
                  font-size: 0.13rem;
                  font-weight: 500;
              }
          }
          .el-icon-view{
              margin-left: 10px;
              cursor: pointer;
          }
  }
}
// 下部分
.botData{ 
  width: 100%;
  height: 49%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .charts{
      display: flex;
      flex-direction: column;
      width: 34%;
      height: 100%;
      /deep/ .row-item{
        height: 20% !important;
      }
  }
  .map{
      border-radius: 30px;
      overflow: hidden;
      width:30%;
      height: 100%;
  }
}
/deep/ .info-name{
  text-align: left !important;
}
</style>

