<template>
  <div class="outContainer">
    <div class="back">
      <i class="el-icon-back" @click="back"></i>
      <span>{{stationName}}预约安装记录</span>
    </div>
    <!-- 筛选条件栏 -->
    <div class="search">
        <el-input class="each" v-model="searchInfo.param.plateNumber" placeholder="车牌号" @input="searchCar"></el-input>
        <el-input class="each" v-model="searchInfo.param.ebikeDeviceId" placeholder="设备编号"></el-input>
        <el-input class="each" v-model="searchInfo.param.rfid" placeholder="RFID"></el-input>
        <el-select class='each' v-model="searchInfo.param.status" clearable placeholder="是否安装">
          <el-option
            v-for="item in isInstallOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <!-- 预约时间选择 -->
        <el-date-picker
         v-model="time"
         type="daterange"
         :default-time="['00:00:00', '23:59:59']"
         range-separator="至"
         start-placeholder="预约时间"
         end-placeholder="预约时间"
         @change='chooseAppointTime'
         class='timeSelect each'>
        </el-date-picker>
        <div class="btns">
          <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
          <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
          <!-- <el-button size="mini" @click="educe">导出</el-button> -->
        </div>
    </div>
    <!-- 表格 -->
    <div class="tableContainer">
      <el-table
      :data="tableList"
      height="100%"
      style="width: 100%;">
        <el-table-column align="center" label="车辆信息" prop="id">
            <template slot-scope="scope" >
              <p v-if="scope.row.ebikeUserTypeVO">{{ scope.row.ebikeUserTypeVO.plateNumber ? scope.row.ebikeUserTypeVO.plateNumber : '--' }}</p>
              <p v-if="scope.row.ebikeUserTypeVO">{{ scope.row.ebikeUserTypeVO.brand ? scope.row.ebikeUserTypeVO.brand : '--' }}</p>
              <p v-if="!scope.row.ebikeUserTypeVO"> -- </p>
            </template>
        </el-table-column>
        <el-table-column align="center" label="车主信息" prop="name">
            <template slot-scope="scope">
              <p v-if="scope.row.ebikeUserTypeVO">{{ scope.row.ebikeUserTypeVO.userName ? scope.row.ebikeUserTypeVO.userName : '--' }}</p>
              <p v-if="scope.row.ebikeUserTypeVO">{{ scope.row.ebikeUserTypeVO.userPhone ? scope.row.ebikeUserTypeVO.userPhone : '--' }}</p>
              <p v-if="!scope.row.ebikeUserTypeVO"> -- </p>
            </template>
        </el-table-column>
        <el-table-column align="center" label="预约时间" prop="appointmentTime">
          <template slot-scope="scope">
            <p>{{ scope.row.appointmentTime }}  {{  scope.row.appointmentPeriodStr  }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否安装" prop="statusName">
        </el-table-column>
        <el-table-column align="center" label="设备信息" prop="desc">
          <template slot-scope="scope">
            <p v-if="scope.row.ebikeUserTypeVO">黑匣子:{{ scope.row.ebikeUserTypeVO.deviceId ? scope.row.ebikeUserTypeVO.deviceId : '--' }}</p>
            <p v-if="scope.row.ebikeUserTypeVO">RFID:{{ scope.row.ebikeUserTypeVO.rfid ? scope.row.ebikeUserTypeVO.rfid : '--' }}</p>
            <p v-if="!scope.row.ebikeUserTypeVO"> -- </p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleBind(scope.row)" v-if="scope.row.status == 1">安装绑定</el-button>
            <el-button size="mini" @click="removeBind(scope.row,3)" v-if="scope.row.status == 2">解绑</el-button>
            <el-button size="mini" @click="appointAgain(scope.row)" v-if="scope.row.status == 7">重新预约</el-button>
            <p v-if="scope.row.status != 1 && scope.row.status != 2 && scope.row.status != 7">--</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <pagination v-bind:child-msg="searchInfo" @callFather="callFather"></pagination>
    <!-- 安装绑定 -->
    <el-dialog title="安装绑定" :visible.sync="equipBindVisible">
      <el-form ref="bindForm" :model="bindForm" :rules="bindRules">
        <el-form-item label="安装类型" label-width="100px" prop="installType">
          <el-radio-group v-model="bindForm.installType">
            <el-radio :label="1">安装RFID和黑匣子</el-radio>
            <el-radio :label="2">仅安装RFID</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="设备类型" label-width="100px" prop="deviceType" v-if="bindForm.installType == 1">
          <el-radio-group v-model="bindForm.deviceType">
            <el-radio :label="1">Pro-4G</el-radio>
            <!-- <el-radio :label="2">Air-WiFi</el-radio> -->
            <el-radio :label="3">Air-4G</el-radio>
            <el-radio :label="4">power-4G</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="设备编号" label-width="100px" prop="ebikeDeviceId" v-if="bindForm.installType == 1">
          <el-input v-model="bindForm.ebikeDeviceId" autocomplete="off" @input="writeDeviceId"></el-input>
        </el-form-item>
        <el-form-item label="RFID" label-width="100px" prop="rfid">
          <el-input v-model="bindForm.rfid" autocomplete="off" :disabled="bindForm.installType == 1"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="equipBindVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmBind">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import { getStationDetail,getInstallAppointmentList,eBikeBindDevice,unbindEbikeByDevice,createAppointment } from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
import { Row } from "element-ui";
export default {
  data() {
    return {
      searchInfo:{ // 筛选条件
        pageSize:10,
        pageNo:1,
        total:0,
        param:{
          appointmentEndTime: "",
          appointmentStartTime: "",
          ebikeDeviceId: "",
          status: "",
          plateNumber: "",
          siteId:"",
        }
      },
      bindForm:{ // 绑定设备表单数据
        installType:1,
        ebikeDeviceId:"",
        rfid:"",
        ebikeId:"",
        deviceType:4,
      },
      tableList:[], // 表格数据
      isInstallOptions:[ // 是否安装
        {value:1,label:'未安装'},
        {value:2,label:'已安装'},
        {value:5,label:'已过期'},
        {value:6,label:'已取消'},
        {value:7,label:'已解绑'},
      ],
      equipBindVisible:false,
      time:"",
      isInstall:"",
      stationName:"",
      bindRules:{
        ebikeDeviceId: [
          { required: true, message: '设备id不得为空', trigger: 'blur', }
        ],
        rfid: [
          { required: true, message: 'RFID不得为空', trigger: 'blur', }
        ],
        deviceType: [
          { required: true, message: '请选择设备类型', trigger: 'change', }
        ],
        installType: [
          { required: true, message: '请选择安装类型', trigger: 'blur', }
        ],
      }
    }
  },

  components: {Pagination},

  computed: {},

  mounted() {
    console.log(this.getToday())
    this.init();
    this.getDetail();
  },

  methods: {
    // 返回
    back(){
      this.$router.go(-1)
    },
    // 条件筛选事件
    handleSearch(type){
      if(type == 'clear'){
        this.time = '';
        this.searchInfo.param = {
          siteId:this.$route.query.id
        };
      }
      this.searchInfo.pageNo = 1;
      this.init();
    },
    // 初始化获取表格数据
    async init(){
      this.searchInfo.param.siteId = this.$route.query.id;
      await getInstallAppointmentList(this.searchInfo).then(res=>{
        if(res.success && res.data){
          this.tableList = res.data ? res.data.list : [];
          this.searchInfo.total = res.data.total;
          this.tableList.forEach(element => {
            let eachAppointTime = timeChange(element.appointmentTime);
            element.appointmentTime = `${eachAppointTime.year}-${eachAppointTime.month}-${eachAppointTime.day}`
          });
        }else{
          this.tableList = [];
          this.searchInfo.total = 0;
        }
      })
    },
    // 查询详情
    getDetail(){
      getStationDetail({param:this.$route.query.id}).then(res=>{
        if(res.success){
          this.stationName = res.data.name
        }
      })
    },
    // 搜索车辆
    searchCar(e){
      this.searchInfo.param.plateNumber = e;
      this.init();
    },
    // 选择预约时间范围
    chooseAppointTime(e){
      let startTime = timeChange(e[0]);
      this.searchInfo.param.appointmentStartTime = `${startTime.year}-${startTime.month}-${startTime.day}`;
      let endTime = timeChange(e[1]);
      this.searchInfo.param.appointmentEndTime = `${endTime.year}-${endTime.month}-${endTime.day}`;
    },
    // 选择安装时间范围
    chooseInstallTime(e){
      this.searchInfo.param.startInstallTime = timeChange(e[0]);
      this.searchInfo.param.ensInstallTime = timeChange(e[1]);
    },
    // 导出
    educe(){},
    // 打开安装绑定弹框
    handleBind(row){
      this.row = row;
      this.bindForm.appointmentRecordId = row.id;
      this.bindForm.ebikeId = row.ebikeUserTypeVO.id;
      this.equipBindVisible = true;
    },
    // 设备编号输入事件
    writeDeviceId(e){
      this.bindForm.rfid = this.bindForm.ebikeDeviceId ? `EAEBC${this.bindForm.ebikeDeviceId}` : "";
    },
    // 确认绑定
    confirmBind(){
      this.$refs["bindForm"].validate((valid) => {
        if(valid){
          this.bindForm.ebikeDeviceId = !this.bindForm.ebikeDeviceId || this.bindForm.ebikeDeviceId == "" ? null : this.bindForm.ebikeDeviceId;
          this.$confirm('确认进行绑定吗？').then(()=>{
            if(this.bindForm.installType == 2){
              this.bindForm.deviceType = null;
              this.bindForm.ebikeDeviceId = null;
            }
            eBikeBindDevice({param:this.bindForm}).then(res => {
              if(res.success){
                this.$message({
                  message:'绑定成功',
                  type:'success'
                });
                this.equipBindVisible = false;
                this.init();
                this.bindForm = { // 绑定设备表单数据
                  installType:1,
                  ebikeDeviceId:"",
                  rfid:"",
                  ebikeId:"",
                  deviceType:4,
                }
              }else{
                this.$message({
                  message:res.msg,
                  type:'fail'
                });
              }
            })
          })
        }
      })
    },
    // 解绑设备
    removeBind(row,type){
      this.$confirm('确认解绑吗？','提示').then(res=>{
        unbindEbikeByDevice({param:row.id}).then(res=>{
          if(res.success){
            this.$message({
              message:'解绑成功',
              type:'success'
            })
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
        })
      })
    },
    getToday(){
      const date = new Date()
      const Y = date.getFullYear() // 年
      const M = date.getMonth() + 1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1 // 月
      const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 日
      return Y + "-" + M + "-" + D;
    },
    // 重新预约
    appointAgain(row){
      this.$confirm('确认重新生成一条预约记录吗？').then(async ()=>{
        let today = this.getToday();
        let data = {
          "appointmentPeriod": 0,
          "appointmentTime": today,
          "appointmentType": 1,
          "ebikeId": row.ebikeId,
          "siteId": this.$route.query.id,
        }
        createAppointment({param:data}).then(res=>{
          if(res.success){
            this.$message({
              message:'操作成功',
              type:'success'
            })
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
        })
      })
      
    },
    // 分页插件事件
    callFather(pram){
      this.searchInfo.pageNo = pram.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped>

</style>
