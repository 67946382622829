<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="100px" style="width:95%;overflow:auto">
      <el-form-item label="角色名称" prop="name" >
        <el-input placeholder="请输入角色名" v-model="form.name" required>
        </el-input>
      </el-form-item>
      <el-form-item label="角色级别" prop="level" >
        <el-input placeholder="请输入纯数字（数字越大，则说明级别越低）" v-model="form.level" type="number" required>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain='true'>立即提交</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import {addRole,updateRole} from '@/api/public.js';

export default {
  props:['row'],
  data() {
    return {
      form:{},
      imageUrl:'',
      value:[],
      options:[],
      show:true,
      rules: {
        name: [
          { required: true, message: "请输入角色名", trigger: "blur" },
        ],
        level: [
          { required: true, message: "级别不得为空", trigger: "blur" },
        ],
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.form = this.row ? this.row : this.form;
  },

  methods: {
    // 关闭弹框
    close(){
       this.$emit('close');
    },
    // 新增
    add(){
      
      addRole({param:this.form}).then((res) => {
        if (res.success) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          setTimeout(() => {
            this.$emit('close');
          }, 1000);
        }else{
          this.$message({
            message: res.msg,
            type: "fail",
          });
        }
      })
    },
    // 编辑
    edit(){
      updateRole({param:this.form}).then((res) => {
        if (res.success) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          setTimeout(() => {
            this.$emit('close');
          }, 1000);
        }else{
          this.$message({
            message: res.msg,
            type: "fail",
          });
        }
      })
    },
    // 提交
    submit(form) {
      this.$refs["form"].validate(valid => {
         if (valid) {
           this.form.createTime = "";
           this.form.level = Number(this.form.level)
           this.$confirm('确认要提交吗？').then(() => {
             if(this.row){
               this.edit();
             }else{
               this.add();
             }
           })
         } else {
           return false;
         }
     });
    },
    
  }
};
</script>
<style lang="scss" scoped>
 .el-drawer{
   overflow:auto !important;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
