<!-- 设备管理 / 红外头盔识别管理 -->
<template>
    <div class="outContainer">
      <!-- 筛选条件栏 -->
      <div class="search">
        <el-cascader class="each" placeholder="所属商户 / 所属小区" v-model="unitId"
          clearable
          :options="managerUnitOptions"
          @change="chooseMerchant"
          :props="props"
          v-if="userInfo.roleLevel < 5"
        >
        </el-cascader>
        <el-select class='each' placeholder="安装小区" v-model="searchInfo.param.unitId" clearable  v-if="userInfo.roleLevel >= 5 && userInfo.roleLevel < 10">
          <el-option
            v-for="item in unitOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select class='each' placeholder="在线状态" v-model="searchInfo.param.status" clearable>
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
        <el-button size="mini" @click="openDrawer(null,'add')">新增</el-button>
      </div>
      <!-- 数据表格 -->
      <div class="tableContainer">
        <el-table
        :data="tableList"
        height="100%"
        style="width: 100%;">
          <af-table-column align="center" label="设备编号" prop="id">
          </af-table-column>
          <af-table-column align="center" label="道闸类型" prop="type">
            <template slot-scope="scope">
              <p>{{ scope.row.type == 1 ? '入口' : (scope.row.type == 2 ? '出口' : '--')}}</p>
            </template>
          </af-table-column>
          <af-table-column align="center" label="无网开闸超时时间" prop="timeout" width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.timeout ? Number(scope.row.timeout) + '秒': '--'}}</span>
            </template>
          </af-table-column>
          <el-table-column align="center" label="头盔识别" prop="photoStatus" width="150">
            <template slot-scope="scope">
              <div>
                <el-switch
                  inactive-text="是否开启："
                  :value="scope.row.photoStatus == 'on' ? true : false"
                  active-color="#13ce66"
                  @change="changeHelmetRecognition(scope.row)">
                </el-switch>
              </div>
              <p>置信度：{{ scope.row.trustPercent ? scope.row.trustPercent : 0 }}%</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="灯光及语音" prop="sound" width="200">
            <template slot-scope="scope">
              <p>灯光：{{ scope.row.light == 'on' ? '常开' : (scope.row.light == 'auto' ? '自动' : '--') }}</p>
              <div class="voiceContainer">
                语音：{{ scope.row.sound == 'on' ? '开启' : '关闭' }}
                <el-tooltip class="item" effect="dark" placement="right">
                  <div slot="content" class="content">
                    <p>
                      <span>等待提示音：</span> 
                      <span> {{ scope.row.triggerVoice ? scope.row.triggerVoice : '待配置' }}</span>
                    </p>
                    <p>
                      <span>开闸提示音：</span> 
                      <span>{{ scope.row.openVoice ? scope.row.openVoice : '待配置' }}</span>
                    </p>
                    <p>
                      <span>拒绝通行提示音：</span> 
                      <span>{{ scope.row.refuseVoice ? scope.row.refuseVoice : '待配置' }}</span>
                    </p>
                    <p>
                      <span>设备异常提示音：</span> 
                      <span> {{ scope.row.errorVoice ? scope.row.errorVoice : '待配置' }}</span>
                    </p>
                  </div>
                  <i class="el-icon-info" v-if="scope.row.sound == 'on'"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <af-table-column align="center" label="安装小区" prop="unitName">
          </af-table-column>
          <af-table-column align="center" label="具体安装位置" prop="adders">
          </af-table-column>
          <af-table-column align="center" label="在线状态" prop="status">
            <template slot-scope="scope">
              <p>{{ scope.row.status == 1 ? '在线' : (scope.row.status == 2 ? '离线' : '--')}}</p>
            </template>
          </af-table-column>
          <af-table-column align="center" label="版本" prop="version">
            <template slot-scope="scope">
              <p>硬件版本：{{ scope.row.hardWareVersion ? scope.row.hardWareVersion : '--'}}</p>
              <p>软件版本：{{ scope.row.version ? scope.row.version : '--'}}</p>
            </template>
          </af-table-column>
          <af-table-column align="center" label="安装时间" prop="createTime">
          </af-table-column>
          <el-table-column align="center" label="操作" fixed="right" width="270">
            <template slot-scope="scope">
              <el-button size="mini" @click="toChild(scope.row,'红外头盔通行记录')">通行记录</el-button>
              <el-button size="mini" @click="toChild(scope.row,'红外头盔通讯记录')">通讯记录</el-button>
              <el-popover placement="top" trigger="click" width="auto">
                <div style="text-align: right; margin: 0;display: flex;justify-content: space-between;flex-wrap: wrap;">
                  <span class="spanBtn" @click="disposition(scope.row)">配置</span>
                  <span class="spanBtn" @click="openBrake(scope.row)">开闸</span>
                  <span class="spanBtn" @click="openDrawer(scope.row,'edit')">编辑</span>
                  <span class="spanBtn" @click="handleDel(scope.row,'edit')">删除</span>
                </div>
                <el-button style="margin-left: 10px;" slot="reference" size="mini">更多</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
      <!-- 新增、编辑 -->
      <el-drawer
        :title="row ? '编辑' : '新增'"
        :visible.sync="addDrawer"
        size="50%">
        <add v-if="addDrawer" @close="handleClose"  ref='newForm' :row="row"></add>
      </el-drawer>
      <!-- 配置 -->
      <el-dialog title="配置（指令下发成功后需等待几分钟,设备才会生效,请耐心等待）" :visible.sync="open" width="60%" append-to-body>
        <el-form label-width="180px">
          <el-form-item label="设备编号">
            <el-input v-model="dispositionInfo.id" disabled></el-input>
          </el-form-item>
          <el-form-item label="无网开闸超时时间（秒）">
            <div style="display: flex;flex-direction: column;">
              <el-input v-model="dispositionInfo.timeout" placeholder="请输入无网开道闸超时时间"></el-input>
              <p class="remind">提示：若设置为0，则认为无网状态不开道闸</p>
            </div>
          </el-form-item>
          <el-form-item label="灯光配置">
            <el-radio-group v-model="dispositionInfo.light">
              <el-radio label="on">常开</el-radio>
              <el-radio label="auto">自动</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="语音播报是否开启">
            <el-radio-group v-model="dispositionInfo.sound">
              <el-radio label="on">是</el-radio>
              <el-radio label="off">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="dispositionInfo.sound == 'on'">
            <el-form-item label="等待语音播报内容">
            <el-input v-model="dispositionInfo.triggerVoice" placeholder="请输入语音播报内容(例：您好，请稍等)"></el-input>
            </el-form-item>
            <el-form-item label="开闸语音播报内容">
              <el-input v-model="dispositionInfo.openVoice" placeholder="请输入语音播报内容(例：闸已开，请通过)"></el-input>
            </el-form-item>
            <el-form-item label="拒绝通行语音播报内容">
              <el-input v-model="dispositionInfo.refuseVoice" placeholder="请输入语音播报内容(例：您好，请佩戴头盔)"></el-input>
            </el-form-item>
            <el-form-item label="设备异常语音播报内容">
              <el-input v-model="dispositionInfo.errorVoice" placeholder="请输入语音播报内容(例：设备异常)"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="close">关闭</el-button>
          <el-button @click="confirm">提交</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  import Pagination from '@/components/Pagination.vue';
  import add from './add.vue';
  import { getInfraredHelmetList,delInfraredHelmet,getNeighbourhoodList,closeBarrierGate,openInfraredHelmet,getMerchantList,editInfraredHelmet,dispositionInfraredHelmet } from '@/api/public.js';
  import { timeChange } from '@/api/publicFun.js';
  export default {
    data() {
      return {
        searchInfo:{ // 筛选条件
          pageSize:10,
          pageNo:1,
          total:1,
          param:{
            plasteNum:'',
            equipNum:'',
            photoStatus:null,
          }
        },
        tableList:[], // 表格数据列表
        row:null, // 当前操作数据具体信息
        addDrawer:false, // 是否显示新增弹框
        open:false, // 配置弹框是否显示
        dispositionInfo:{ // 配置信息
          timeout:5,
          isOpen:"",
          sound:'on',
        },
        unitOptions:[], // 小区选项
        statusOptions:[
          {value:1,label:'在线'},
          {value:2,label:'离线'},
        ],
        managerUnitOptions:[ // 所属商户、所属小区选项
          {
            value:1,
            label:'乐电',
            children:[
              { value:1,label:'光阳国际'},
              { value:2,label:'近江八园'},
            ]
          },
        ],
        unitId:"",
        props: { // 所属商户、小区 联动选择框配置
          value:'id',
          label:'name',
          lazy: true,
          checkStrictly:true,
          lazyLoad (node, resolve) {
            const { level } = node;
            console.log(node,987)
            setTimeout(() => {
              let data = {
                pageNo:1,
                pageSize:1000,
                param:{
                  name:null,
                  merchantId:node.value,
                }
              }
              if(level == 1){
                getNeighbourhoodList(data).then(res=>{
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  let nodes = [];
                  res.data.list.forEach(element=>{
                      nodes.push({
                        id: element.id,
                        name: element.name,
                        leaf: level >= 1,
                        children:null,
                      })
                  })
                  // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  resolve(nodes);
                })
                console.log(node,908)
              }
            }, 1000);
          }
        },
        userInfo:JSON.parse(localStorage.getItem('userInfo')),
      }
    },
  
    components: {add,Pagination},
  
    computed: {},
  
    mounted() {
      this.userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      this.init();
      this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : this.getNieghbourList();
    },
  
    methods: {
      // 条件筛选事件
      handleSearch(type){
        if(type == 'clear'){
          this.searchInfo.param = {};
          this.unitId = [];
        }else{
          this.searchInfo.param.unitId = this.searchInfo.param.unitId ? this.searchInfo.param.unitId : null;
        }
        this.searchInfo.pageNo = 1;
        this.init();
      },
      // 初始化获取表格数据
      async init(){
        await getInfraredHelmetList(this.searchInfo).then(res=>{
          if(res.success){
            this.tableList = res.data.list;
            this.searchInfo.total = res.data.total;
            this.tableList.forEach(element => {
              element.createTime = timeChange(element.createTime,'seconds');
            });
          }else{
            this.tableList = [];
            this.searchInfo.total = 0;
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
        })
      },
      // 获取小区列表
      getNieghbourList(){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:null,
          }
        }
        getNeighbourhoodList(data).then(res=>{
          if(res.success){
            this.unitOptions = res.data.list;
          }
        })
      },
      // 获取商户列表
      async getMerchantOptions(){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:'',
          }
        }
        await getMerchantList(data).then(res=>{
          if(res.success){
            this.managerUnitOptions = res.data.list;
            this.managerUnitOptions.forEach(element=>{
              element.children = [];
            })
          }else{
            this.managerUnitOptions = [];
          }
        })
      },
      // 选择商户、小区
      chooseMerchant(e){
        this.searchInfo.param.merchantId = e[0] ? e[0] : null;
        this.searchInfo.param.unitId = e[1] ? e[1] : null;
      },
      // 删除
      handleDel(row){
        this.$confirm('确认删除吗？').then(async () => {
          delInfraredHelmet({param:row.id}).then(res=>{
            if(res.success){
              this.$message({
                message:'操作成功',
                type:'success'
              })
              this.init();
            }else{
              this.$message({
                message:res.msg,
                type:'fail'
              })
            }
          })
        })
      },
      // 开启、关闭头盔识别
      changeHelmetRecognition(row){
        let data = {...row};
        data.photoStatus = data.photoStatus == 'on' ? 'off' : 'on';
        this.$confirm('确认开启/关闭头盔识别吗？').then(async ()=>{
          await editInfraredHelmet({param:data}).then(res=>{
            this.$message({
              message:res.success ? '操作成功' : res.msg,
              type:res.success ? 'success' : 'fail',
            })
          })
          this.init();
        })
      },
      // 开闸
      openBrake(row){
        this.$confirm('确认进行该操作吗？').then(async () => {
          let data = {
            param:{
              deviceId:row.id,
              voice:row.openVoice ? row.openVoice : '道闸已开，请快速通行',
            }
          }
          await openInfraredHelmet(data).then(res=>{
            if(res.success){
              this.$message({
                message:'指令下发成功，等待设备回复',
                type:'success'
              })
              this.init();
            }else{
              this.$message({
                message:res.msg,
                type:'fail',
              })
            }
          })
        })
      },
      // 前往子页面
      toChild(row,name){
        this.$router.push(
          {
            name:name, 
            query: {
              id: row.id,
            }
          }
        );
      },
      // 打卡侧边弹框
      openDrawer(row,type){
        this.row = row;
        this.addDrawer = true;
      },
      // 关闭弹框
      handleClose(){
        this.addDrawer = false;
        this.row = null;
        this.init();
      },
      // 打开配置弹框
      disposition(row){
        this.dispositionInfo = {...row};
        this.open = true;
      },
       // 关闭
       close(){
        this.open = false;
        this.init();
      },
      // 提交配置
      confirm(){
        this.$confirm('确认进行该操作吗？').then(async ()=>{
          this.dispositionInfo.deviceId = this.dispositionInfo.id;
          await dispositionInfraredHelmet({param:this.dispositionInfo}).then(res=>{
            this.$message({
              message:res.success ? '指令下发成功' : res.msg,
              type:res.success ? 'success' : 'fail',
            })
          })
          
          let updateData = {
            param:{
              id:this.dispositionInfo.id,
              openVoice:this.dispositionInfo.openVoice,
              refuseVoice:this.dispositionInfo.refuseVoice,
              errorVoice:this.dispositionInfo.errorVoice,
            }
          }
          await editInfraredHelmet(updateData).then((res)=>{
            if(!res.success){
              this.$message({
                message:res.msg,
                type:'fail',
              })
            }
          })
          this.init();
        })
      },
      // 头盔识别配置
      setHelmet(){},
      // 分页操作事件
      callFather(parm){
        this.searchInfo.pageNo = parm.currentPage;
        this.init();
      },
    },
  };
  </script>
  <style scoped lang="scss"> 
  .voiceContainer{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    .el-icon-info{
      cursor: pointer;
      margin-left: 5px;
    }
  }
  .content{
    p{
      line-height: 20px;
      font-size: 14px;
    }
  }
  </style>
  