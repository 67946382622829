<template>
    <div id="screen">
      <div id="map" style="width:50%;height:100%"></div>
    </div>
  </template>
  
  <script>
  import 'echarts/extension/bmap/bmap';
  import { getNeighbourhoodList } from '@/api/public.js';
  import iconImg from '@/static/img/location.png'; // 定位
  import overIcon from '@/static/img/overTemperature.png'; // 电池过温
  import leaveIcon from '@/static/img/batteryLeaveCar.png'; // 电瓶离车
  import otherIcon from '@/static/img/others.png'; // 其他
  export default {
    props: {
        value: {
            require: true,
        }
    },
    data() {
        return {
            markers:[],
            NeighbourRangeList:[], // 小区范围列表
            greenParkRangeList:[], // 绿色停车区范围
            yellowParkRangeList:[], // 黄色停车区范围
            timer:null,
            centerPoint:new BMapGL.Point(120.207461,30.260319), // 地图中心点
            zoomSize:13, // 地图初始缩放级别
            isMapHaveInit:false, // 判断地图是否是第一次加载
            notSolve:{},
        }
    },
    watch: {
        value: {
            deep: true,
            handler (val, oldVal) {
              if(val == 'backAll'){
                  this.isMapHaveInit = false;
                  this.init();
              }else{
                  // 删除指定的标识
                  let allMarkers = this.Map.getOverlays();
                  allMarkers.forEach(element=>{
                      if(element.type == 'notSolve'){
                          this.Map.removeOverlay(element);
                      }
                  })
                  this.notSolve = {...val};
                  this.addNotSolve();
              }
            }
        }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.init();
      this.timer= setInterval(()=>{
        this.Map.clearOverlays();
        this.init();
      },300000)
    },
    methods: {
      init(){
          let data = {
              pageNo:1,
              pageSize:10000,
              param:{
                  name:"",
              }
          }
          // 获取小区定位、范围以及停车区范围GPS数据
          getNeighbourhoodList(data).then(res=>{
              if(res.success){
                  this.NeighbourRangeList=[];
                  this.greenParkRangeList=[];
                  this.yellowParkRangeList=[];
                  this.markers=[];
                  res.data.list.forEach(element=>{
                      let each= [element.longitude,element.latitude];
                      each.push(element.id,element.name); // 同时保存该gps对应的小区信息，用于后续点击小区标点时传参
                      this.markers.push(each);
                      if(element.ranges){
                          element.ranges = JSON.parse(element.ranges);
                          this.NeighbourRangeList.push(element.ranges);
                      }else{
                          this.NeighbourRangeList.push([each])
                      }
                  })
                  
                  this.$nextTick(()=>{
                    this.map() ; // 判断是否是第一次加载地图，如果不是，则只需要重新绘制地图上的小区标点并监听点击事件
                  })
              }
          })
      },
      addNotSolve(){
          this.notSolve.data.forEach(ele=>{
              if(ele.latitude){
                  let gps = ele.latitude ? [ele.longitude,ele.latitude] : ele.latitude;
                  var point1 = new BMapGL.Point(gps[0], gps[1]);
                  if(this.notSolve.type == 2){
                      var myIcon1 = new BMapGL.Icon(overIcon, new BMapGL.Size(20,20));  //定义自己的标注
                  }else if(this.notSolve.type == 1){
                      var myIcon1 = new BMapGL.Icon(leaveIcon, new BMapGL.Size(20,20));  //定义自己的标注
                  }else if(this.notSolve.type == 3){
                      var myIcon1 = new BMapGL.Icon(otherIcon, new BMapGL.Size(20,20));  //定义自己的标注
                  }
                  var marker1 = new BMapGL.Marker(point1,{icon:myIcon1}); // 创建标注
                  marker1.type='notSolve'; // 给标点增加特定的属性标识
                  var content =`<div style='width:100%;'><div style='width:90%;margin:0 auto;'>` + `<div><span>车主：</span>` + ele.ownerName + `</div><div><span>电话：</    span>` + ele.ownerPhone  + `<div><span>车牌：</span>` + ele.plateNumber;
                  this.brokenInfo(marker1,content);
                  this.Map.addOverlay(marker1);
              }
          })
          this.notSolve = {
              data:[],
              type:0
          };
      },
      // 初始化地图
      map(){
          const dark = [{
             "featureType": "land",
             "elementType": "geometry",
             "stylers": {
                 "visibility": "on",
                 "color": "#091220ff"  // 091220ff "#02030c"
             }
          }, {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "on",
                  "color": "#113549ff"
              }
          }, {
              "featureType": "green",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "on",
                  "color": "#0e1b30ff"
              }
          }, {
              "featureType": "building",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "building",
              "elementType": "geometry.topfill",
              "stylers": {
                  "color": "#113549ff"
              }
          }, {
              "featureType": "building",
              "elementType": "geometry.sidefill",
              "stylers": {
                  "color": "#143e56ff"
              }
          }, {
              "featureType": "building",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#dadada00"
              }
          }, {
              "featureType": "subwaystation",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "on",
                  "color": "#113549B2"
              }
          }, {
              "featureType": "education",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "on",
                  "color": "#12223dff"
              }
          }, {
              "featureType": "medical",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "on",
                  "color": "#12223dff"
              }
          }, {
              "featureType": "scenicspots",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "on",
                  "color": "#12223dff"
              }
          }, {
              "featureType": "highway",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "on",
                  "weight": 4
              }
          }, {
              "featureType": "highway",
              "elementType": "geometry.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "highway",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#fed66900"
              }
          }, {
              "featureType": "highway",
              "elementType": "labels",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "highway",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "highway",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "highway",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "arterial",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "on",
                  "weight": 2
              }
          }, {
              "featureType": "arterial",
              "elementType": "geometry.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "arterial",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#ffeebb00"
              }
          }, {
              "featureType": "arterial",
              "elementType": "labels",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "arterial",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "arterial",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "local",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "on",
                  "weight": 1
              }
          }, {
              "featureType": "local",
              "elementType": "geometry.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "local",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "local",
              "elementType": "labels",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "local",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#979c9aff"
              }
          }, {
              "featureType": "local",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffffff"
              }
          }, {
              "featureType": "railway",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "subway",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "off",
                  "weight": 1
              }
          }, {
              "featureType": "subway",
              "elementType": "geometry.fill",
              "stylers": {
                  "color": "#d8d8d8ff"
              }
          }, {
              "featureType": "subway",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "subway",
              "elementType": "labels",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "subway",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#979c9aff"
              }
          }, {
              "featureType": "subway",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffffff"
              }
          }, {
              "featureType": "continent",
              "elementType": "labels",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "continent",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "continent",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "continent",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "city",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "city",
              "elementType": "labels",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "city",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "city",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "town",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "town",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "town",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#454d50ff"
              }
          }, {
              "featureType": "town",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffffff"
              }
          }, {
              "featureType": "road",
              "elementType": "geometry.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "poilabel",
              "elementType": "labels",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "districtlabel",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "road",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "road",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "road",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "district",
              "elementType": "labels",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "poilabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "poilabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "poilabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "manmade",
              "elementType": "geometry",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "districtlabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffffff"
              }
          }, {
              "featureType": "entertainment",
              "elementType": "geometry",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "shopping",
              "elementType": "geometry",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "nationalway",
              "stylers": {
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "6"
              }
          }, {
              "featureType": "nationalway",
              "stylers": {
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "7"
              }
          }, {
              "featureType": "nationalway",
              "stylers": {
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "8"
              }
          }, {
              "featureType": "nationalway",
              "stylers": {
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "9"
              }
          }, {
              "featureType": "nationalway",
              "stylers": {
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "10"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "6"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "7"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "8"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "9"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "10"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "6"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "7"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "8"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "9"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,10",
                  "level": "10"
              }
          }, {
              "featureType": "cityhighway",
              "stylers": {
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,9",
                  "level": "6"
              }
          }, {
              "featureType": "cityhighway",
              "stylers": {
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,9",
                  "level": "7"
              }
          }, {
              "featureType": "cityhighway",
              "stylers": {
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,9",
                  "level": "8"
              }
          }, {
              "featureType": "cityhighway",
              "stylers": {
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,9",
                  "level": "9"
              }
          }, {
              "featureType": "cityhighway",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,9",
                  "level": "6"
              }
          }, {
              "featureType": "cityhighway",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,9",
                  "level": "7"
              }
          }, {
              "featureType": "cityhighway",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,9",
                  "level": "8"
              }
          }, {
              "featureType": "cityhighway",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,9",
                  "level": "9"
              }
          }, {
              "featureType": "cityhighway",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,9",
                  "level": "6"
              }
          }, {
              "featureType": "cityhighway",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,9",
                  "level": "7"
              }
          }, {
              "featureType": "cityhighway",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,9",
                  "level": "8"
              }
          }, {
              "featureType": "cityhighway",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6,9",
                  "level": "9"
              }
          }, {
              "featureType": "subwaylabel",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "subwaylabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "tertiarywaysign",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "tertiarywaysign",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "provincialwaysign",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "provincialwaysign",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "nationalwaysign",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "nationalwaysign",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "highwaysign",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "highwaysign",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "village",
              "elementType": "labels",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "district",
              "elementType": "labels.text",
              "stylers": {
                  "fontsize": 20
              }
          }, {
              "featureType": "district",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "district",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "country",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "country",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "water",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "water",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "cityhighway",
              "elementType": "geometry.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "cityhighway",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "tertiaryway",
              "elementType": "geometry.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "tertiaryway",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#ffffff10"
              }
          }, {
              "featureType": "provincialway",
              "elementType": "geometry.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "provincialway",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "geometry.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "highway",
              "elementType": "labels.text",
              "stylers": {
                  "fontsize": 20
              }
          }, {
              "featureType": "nationalway",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "nationalway",
              "elementType": "labels.text",
              "stylers": {
                  "fontsize": 20
              }
          }, {
              "featureType": "provincialway",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "provincialway",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "provincialway",
              "elementType": "labels.text",
              "stylers": {
                  "fontsize": 20
              }
          }, {
              "featureType": "cityhighway",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "cityhighway",
              "elementType": "labels.text",
              "stylers": {
                  "fontsize": 20
              }
          }, {
              "featureType": "cityhighway",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "estate",
              "elementType": "geometry",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "tertiaryway",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "tertiaryway",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "fourlevelway",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "fourlevelway",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "scenicspotsway",
              "elementType": "geometry.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "scenicspotsway",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "universityway",
              "elementType": "geometry.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "universityway",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "vacationway",
              "elementType": "geometry.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "vacationway",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "fourlevelway",
              "elementType": "geometry",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "fourlevelway",
              "elementType": "geometry.fill",
              "stylers": {
                  "color": "#12223dff"
              }
          }, {
              "featureType": "fourlevelway",
              "elementType": "geometry.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "transportationlabel",
              "elementType": "labels",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "transportationlabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "transportationlabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "transportationlabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "educationlabel",
              "elementType": "labels",
              "stylers": {
                  "visibility": "on"
              }
          }, {
              "featureType": "educationlabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "educationlabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "educationlabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "transportation",
              "elementType": "geometry",
              "stylers": {
                  "color": "#113549ff"
              }
          }, {
              "featureType": "airportlabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "airportlabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "scenicspotslabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "scenicspotslabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "medicallabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "medicallabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "medicallabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "scenicspotslabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "airportlabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "entertainmentlabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "entertainmentlabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "entertainmentlabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "estatelabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "estatelabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "estatelabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "businesstowerlabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "businesstowerlabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "businesstowerlabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "companylabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "companylabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "companylabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "governmentlabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "governmentlabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "governmentlabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "restaurantlabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "restaurantlabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "restaurantlabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "hotellabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "hotellabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "hotellabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "shoppinglabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "shoppinglabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "shoppinglabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "lifeservicelabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "lifeservicelabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "lifeservicelabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "carservicelabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "carservicelabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "carservicelabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "financelabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "financelabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "financelabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "otherlabel",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "otherlabel",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "otherlabel",
              "elementType": "labels.icon",
              "stylers": {
                  "visibility": "off"
              }
          }, {
              "featureType": "manmade",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "manmade",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "transportation",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "transportation",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "education",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "education",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
              "featureType": "medical",
              "elementType": "labels.text.fill",
              "stylers": {
                  "color": "#2dc4bbff"
              }
          }, {
              "featureType": "medical",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }, {
             "featureType": "scenicspots",
             "elementType": "labels.text.fill",
             "stylers": {
                 "color": "#2dc4bbff"
             }
          }, {
              "featureType": "scenicspots",
              "elementType": "labels.text.stroke",
              "stylers": {
                  "color": "#ffffff00"
              }
          }]
          this.Map = !this.isMapHaveInit ? new BMapGL.Map("map",{
              style: {
                styleJson: [
                  {
                    featureType: "building",
                    elementType: "all",
                    stylers: {
                      visibility: "off",
                    },
                  },
                ],
              },
          }) : this.Map;   // 创建Map实例
          
          this.Map.setMapStyleV2({styleJson:eval("dark")});
          this.Map.enableScrollWheelZoom(true);  //开启鼠标滚轮缩放
          this.isMapHaveInit = true;
          let neighbors=[];
          if(this.markers.length > 0){
            this.markers.forEach((element,index)=>{
                neighbors.push({lng:element[0],lat:element[1]})
                var point = new BMapGL.Point(element[0], element[1]);
                var myIcon = new BMapGL.Icon(iconImg, new BMapGL.Size(20,20));  //定义自己的标注
                var marker = new BMapGL.Marker(point,{icon:myIcon}); // 创建标注
                this.Map.addOverlay(marker); // 将标注添加到地图中
                marker.addEventListener("click", (e) => {
                    var points=[];
                    this.NeighbourRangeList[index].forEach(ele=>{ // 当前标点的小区范围经纬度数组
                        points.push({lng:ele[0],lat:ele[1]})
                    })
                    if(points.length){ // 根据小区的范围，计算一个比较舒适的缩放范围
                        var view = this.Map.getViewport(eval(points));
                        this.centerPoint = view.center;
                        this.zoomSize = view.zoom;
                    }else{
                        this.centerPoint = new BMapGL.Point(marker.latLng.lng,marker.latLng.lat);
                        this.zoomSize = 19;
                    }
                    this.Map.centerAndZoom(this.centerPoint, this.zoomSize);  // 初始化地图,设置中心点坐标和地图级别
                    this.$emit('clickNeighbour',element[2],element[3]);
                });
            })
            if(neighbors.length > 1){  // 根据所有小区标点的Gps，计算一个比较舒适的初始缩放范围
                var view2 = this.Map.getViewport(eval(neighbors));
                this.zoomSize = view2.zoom;
                this.centerPoint = view2.center;
            }else{
                var points=[];
                console.log(this.NeighbourRangeList,9987)
                // this.NeighbourRangeList[0] = JSON.parse(this.NeighbourRangeList[0])
                this.NeighbourRangeList[0].forEach(ele => { // 当前标点的小区范围经纬度数组
                    points.push({lng:ele[0],lat:ele[1]})
                })
                if(points.length){ // 根据小区的范围，计算一个比较舒适的缩放范围
                    var view = this.Map.getViewport(eval(points));
                    this.centerPoint = view.center;
                    this.zoomSize = view.zoom;
                }else{
                    console.log(this.markers,999)
                    this.centerPoint = new BMapGL.Point(this.markers[0][0],this.markers[0][1]);
                    this.zoomSize = 18;
                }
                this.Map.centerAndZoom(this.centerPoint, this.zoomSize);  // 初始化地图,设置中心点坐标和地图级别
            }
          }
          this.Map.centerAndZoom(this.centerPoint, this.zoomSize);  // 初始化地图,设置中心点坐标和地图级别
          
          // 绘制小区范围
          this.NeighbourRangeList.forEach(ele=>{
              let neighbor=[];
              if(ele.length){
                  for(let i=0;i<ele.length;i++){
                      let eachPoint=new BMapGL.Point(ele[i][0],ele[i][1])
                      neighbor.push(eachPoint);
                  }
                  let polygon1 = new BMapGL.Polygon(neighbor, {strokeColor:"red", fillColor:'transparent',strokeWeight:2, strokeOpacity:0.5});  //创建多边形
                  this.Map.addOverlay(polygon1);  //增加多边形
              }
          })
          // 绘制黄色停车区范围
          this.yellowParkRangeList.forEach(yellow=>{
              let yellowPark=[];
              if(yellow.length){
                  for(let i=0;i<yellow.length;i++){
                      yellowPark.push(new BMapGL.Point(yellow[i][0],yellow[i][1]));
                  }
                  var polygon2 = new BMapGL.Polygon(yellowPark, {strokeColor:"yellow", fillColor:'transparent', strokeWeight:2, strokeOpacity:0.5});  //创建多边形
                  this.Map.addOverlay(polygon2);  //增加多边形
              }
          })
          // 绘制绿色停车区
          this.greenParkRangeList.forEach(green=>{
              if(green.length){
                  let greenPark=[];
                  for(let i=0;i<green.length;i++){
                      greenPark.push(new BMapGL.Point(green[i][0],green[i][1]));
                  }
                  var polygon3 = new BMapGL.Polygon(greenPark, {strokeColor:"green", fillColor:'transparent', strokeWeight:2, strokeOpacity:0.5});  //创建多边形
                  this.Map.addOverlay(polygon3);  //增加多边形
              }
          })

      },
      // 点击未处理异常标记点显示标点详细信息
      brokenInfo(marker,content) {
       var infoWindow = new BMapGL.InfoWindow(content, this.opts);
          marker.onclick=function(){
              marker.openInfoWindow(infoWindow);
          }
      },
      // 绘制标点
      addMarker(){
          this.markers.forEach((element,index)=>{
              var point = new BMapGL.Point(element[0], element[1]);
              var myIcon = new BMapGL.Icon(iconImg, new BMapGL.Size(20,20));  //定义自己的标注
              var marker = new BMapGL.Marker(point,{icon:myIcon}); // 创建标注
              this.Map.addOverlay(marker); // 将标注添加到地图中
              marker.addEventListener("click", (e) => {
                  var points=[];
                  this.NeighbourRangeList[index].forEach(ele=>{ // 当前标点的小区范围经纬度数组
                    points.push({lng:ele[0],lat:ele[1]})
                  })
                  if(points.length){ // 根据小区的范围，计算一个比较舒适的缩放范围
                      var view = this.Map.getViewport(eval(points));
                      this.centerPoint=view.center;
                      this.zoomSize=view.zoom;
                  }else{
                      this.centerPoint=new BMapGL.Point(marker.latLng.lng,marker.latLng.lat);
                      this.zoomSize=19;
                  }
                  this.Map.centerAndZoom(this.centerPoint, this.zoomSize);  // 初始化地图,设置中心点坐标和地图级别
                  this.$emit('clickNeighbour',element[2],element[3]);
              });
          })
          // 绘制小区范围
          this.NeighbourRangeList.forEach(ele=>{
              let neighbor=[];
              if(ele.length){
                  for(let i=0;i<ele.length;i++){
                      let eachPoint=new BMapGL.Point(ele[i][0],ele[i][1])
                      neighbor.push(eachPoint);
                  }
                  let polygon1 = new BMapGL.Polygon(neighbor, {strokeColor:"red", fillColor:'transparent',strokeWeight:2, strokeOpacity:0.5});  //创建多边形
                  this.Map.addOverlay(polygon1);  //增加多边形
              }
          })
          // 绘制黄色停车区范围
          this.yellowParkRangeList.forEach(yellow=>{
              let yellowPark=[];
              if(yellow.length){
                  for(let i=0;i<yellow.length;i++){
                      yellowPark.push(new BMapGL.Point(yellow[i][0],yellow[i][1]));
                  }
                  var polygon2 = new BMapGL.Polygon(yellowPark, {strokeColor:"yellow", fillColor:'transparent', strokeWeight:2, strokeOpacity:0.5});  //创建多边形
                  this.Map.addOverlay(polygon2);  //增加多边形
              }
          })
          // 绘制绿色停车区
          this.greenParkRangeList.forEach(green=>{
              if(green.length){
                  let greenPark=[];
                  for(let i=0;i<green.length;i++){
                      greenPark.push(new BMapGL.Point(green[i][0],green[i][1]));
                  }
                  var polygon3 = new BMapGL.Polygon(greenPark, {strokeColor:"green", fillColor:'transparent', strokeWeight:2, strokeOpacity:0.5});  //创建多边形
                  this.Map.addOverlay(polygon3);  //增加多边形
              }
          })
      }
    },
    beforeDestroy(){
      clearInterval();
    }
  };
  </script>
  <style scoped lang="scss">
    #screen{
      height: 99%;
      width: 99%;
      background: #001529;
    }
    /deep/ .anchorBL{
      display: none !important;
      z-index: 0 !important;
    }
    .dv-border-box-11{
      /deep/ .border-box-content{
        padding: 60px 30px 30px 30px !important;
        display: flex;
        justify-content: center;
      }
    }
  
  .radar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: red;
      position: relative;
      .ripple {
           width: 40px;
           height: 40px;
           border-radius: 50%;
           position: absolute;
           top: 50%;
           left: 50%;
           transform: translate(-50%,-50%);
           border: 1px solid red;
           animation: ripple 2s linear infinite;
      }
      @keyframes ripple {
          to {
           width: 150px;
           height: 150px;
           opacity: 0;
          }
      }
      .radar :nth-child(1) {
          animation-delay: 0.666s;
      }
      .radar :nth-child(2) {
          animation-delay: 1.322s;
      }
  }
  </style>
  