<!-- 设备管理 / 道闸管理 -->
<template>
  <div class="outContainer">
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-input class="each"  placeholder="感应功率" v-model="searchInfo.param.power" type="Number">
      </el-input>
      <el-cascader class="each" placeholder="所属商户 / 所属小区" v-model="unitId"
        clearable
        :options="managerUnitOptions"
        @change="chooseMerchant"
        :props="props"
        v-if="userInfo.roleLevel < 5"
      >
      </el-cascader>
      <el-select class='each' placeholder="安装小区" v-model="searchInfo.param.unitId" clearable  v-if="userInfo.roleLevel >= 5 && userInfo.roleLevel < 10">
        <el-option
          v-for="item in unitOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-select class='each' placeholder="在线状态" v-model="searchInfo.param.status" clearable>
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
      <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
      <el-button size="mini" @click="openDrawer(null,'add')">新增</el-button>
    </div>
    <!-- 数据表格 -->
    <div class="tableContainer">
      <el-table
      :data="tableList"
      height="100%"
      style="width: 100%;">
        <af-table-column align="center" label="设备编号" prop="id">
        </af-table-column>
        <af-table-column align="center" label="道闸类型" prop="type">
          <template slot-scope="scope">
            <p>{{ scope.row.type == 1 ? '入口' : (scope.row.type == 2 ? '出口' : '--')}}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="时间间隔（秒）" prop="identifyInterval">
          <template slot-scope="scope">
            <p>心跳间隔：{{ scope.row.heartbeatInterval ? Number(scope.row.heartbeatInterval) / 1000 : '--'}}</p>
            <p>读标签间隔：{{ scope.row.readInterval ? Number(scope.row.readInterval) / 1000 : '--'}}</p>
            <p>上报间隔：{{ scope.row.sendInterval ? Number(scope.row.sendInterval) / 1000 : '--'}}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="超时时间(秒)" prop="timeout">
          <template slot-scope="scope">
            <span>{{ scope.row.timeout ? Number(scope.row.timeout)/1000 : '--'}}</span>
          </template>
        </af-table-column>
        <af-table-column align="center" label="感应范围（米）" prop="power">
          <template slot-scope="scope">
            <span v-if="scope.row.power == 16">0.8</span>
            <span v-if="scope.row.power == 17">1</span>
            <span v-if="scope.row.power == 18">1.5</span>
            <span v-if="scope.row.power == 19">2</span>
            <span v-if="scope.row.power == 20">2.5</span>
            <span v-if="scope.row.power == 21">3</span>
            <span v-if="scope.row.power == 22">4</span>
            <span v-if="scope.row.power == 23">4.5</span>
            <span v-if="scope.row.power == 24">5</span>
            <span v-if="scope.row.power == 25">6</span>
            <span v-if="scope.row.power == 26">7</span>
            <span v-if="scope.row.power == 27">9</span>
            <span v-if="scope.row.power == 28">10</span>
            <span v-if="scope.row.power == 29">12</span>
            <span v-if="scope.row.power == 30">13</span>
            <span v-if="scope.row.power == 31">14</span>
            <span v-if="scope.row.power == 32">15</span>
            <span v-if="scope.row.power == 33">16</span>
            <span v-if="!scope.row.power">--</span>
          </template>
        </af-table-column>
        <el-table-column align="center" label="头盔识别" prop="cameraStatus" width="200">
          <template slot-scope="scope">
            <p>是否带摄像头：{{ scope.row.cameraStatus == 1 ? '是' : '否' }}</p>
            <div v-if="scope.row.cameraStatus == 1">
              <el-switch
                inactive-text="是否开启头盔识别："
                :disabled="scope.row.status != 1"
                :value="scope.row.photoStatus == 1 ? true : false"
                active-color="#13ce66"
                @change="changeHelmetRecognition(scope.row)">
              </el-switch>
            </div>
            <p v-if="scope.row.cameraStatus == 1">头盔识别置信度：{{ scope.row.trustPercent }}%</p>
          </template>
        </el-table-column>
        <af-table-column align="center" label="安装小区" prop="unitName">
        </af-table-column>
        <af-table-column align="center" label="具体安装位置" prop="adders">
        </af-table-column>
        <af-table-column align="center" label="在线状态" prop="status">
          <template slot-scope="scope">
            <p>{{ scope.row.status == 1 ? '在线' : (scope.row.status == 2 ? '离线' : '--')}}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="版本" prop="version">
          <template slot-scope="scope">
            <p>硬件版本：{{ scope.row.hardWareVersion ? scope.row.hardWareVersion : '--'}}</p>
            <p>软件版本：{{ scope.row.version ? scope.row.version : '--'}}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="安装时间" prop="createTime">
        </af-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="270">
          <template slot-scope="scope">
            <el-button size="mini" @click="toChild(scope.row,'道闸通行记录')">通行记录</el-button>
            <el-button size="mini" @click="toChild(scope.row,'道闸通讯记录')">通讯记录</el-button>
            <el-popover
                placement="top"
                trigger="click"
                width="auto"
                >
                <div style="text-align: right; margin: 0;display: flex;justify-content: space-between;flex-wrap: wrap;">
                  <span class="spanBtn" @click="disposition(scope.row)">配置</span>
                  <span class="spanBtn" @click="openBrake(scope.row)">开闸</span>
                  <span class="spanBtn" @click="restartBrake(scope.row)">重启</span>
                  <span class="spanBtn" @click="openDrawer(scope.row,'edit')">编辑</span>
                  <span class="spanBtn" @click="handleDel(scope.row,'edit')">删除</span>
                </div>
                <el-button style="margin-left: 10px;" slot="reference" size="mini">更多</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
    <!-- 新增、编辑 -->
    <el-drawer
      :title="row ? '编辑' : '新增'"
      :visible.sync="addDrawer"
      size="50%">
      <add v-if="addDrawer" @close="handleClose"  ref='newForm' :row="row"></add>
    </el-drawer>
    <!-- 配置 -->
    <el-dialog title="配置（指令下发成功后需等待几分钟,设备才会生效,请耐心等待）" :visible.sync="open" width="60%" :close-on-click-modal="false" append-to-body>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="感应范围" name="power">
          <el-form label-width="180px">
            <el-form-item label="设备编号">
              <el-input v-model="dispositionInfo.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="感应范围（米）">
              <el-select v-model="dispositionInfo.power" style="width: 100%;">
                <el-option
                  v-for="item in radiusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="时间间隔" name="interval">
          <el-form label-width="180px">
            <el-form-item label="设备编号">
              <el-input v-model="dispositionInfo.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="心跳时间间隔（秒）" v-if="userInfo && userInfo.roleLevel === 0">
              <el-input v-model="dispositionInfo.heartbeatInterval" type="Number" placeholder="请输入心跳时间间隔"></el-input>
            </el-form-item>
            <el-form-item label="读标签时间间隔（秒）">
              <el-input v-model="dispositionInfo.readInterval" type="Number" placeholder="请输入读标签时间间隔"></el-input>
            </el-form-item>
            <el-form-item label="上报时间间隔（秒）">
              <el-input v-model="dispositionInfo.sendInterval" type="Number" placeholder="请输入上报时间间隔"></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="超时时间" name="timeout">
          <el-form label-width="180px">
            <el-form-item label="设备编号">
              <el-input v-model="dispositionInfo.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="无网状态是否开闸">
              <el-radio-group v-model="dispositionInfo.isOpen">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="无网开闸超时时间（秒）" v-if="dispositionInfo.isOpen">
              <el-input v-model="dispositionInfo.timeout" placeholder="请输入无网开道闸超时时间"></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">关闭</el-button>
        <el-button @click="confirm">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination.vue';
import add from './add.vue';
import { getBarrierGateList,deleteBarrierGate,getNeighbourhoodList,closeBarrierGate,openBarrierGate,restartBarrierGate,setIntervalBarrierGate,setTimeoutBarrierGate,setPowerBarrierGate,getMerchantList,setPhotoStatus } from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
export default {
  data() {
    return {
      searchInfo:{ // 筛选条件
        pageSize:10,
        pageNo:1,
        total:1,
        param:{
          plasteNum:'',
          equipNum:'',
          cameraStatus:null,
          photoStatus:null,
        }
      },
      userInfo:JSON.parse(localStorage.getItem('userInfo')),
      tableList:[], // 表格数据列表
      row:null, // 当前操作数据具体信息
      addDrawer:false, // 是否显示新增弹框
      open:false, // 配置弹框是否显示
      dispositionInfo:{
        timeout:"",
        isOpen:"",
      }, // 配置信息
      title:'配置',
      unitOptions:[], // 小区选项
      statusOptions:[
        {value:1,label:'在线'},
        {value:2,label:'离线'},
      ],
      managerUnitOptions:[ // 所属商户、所属小区选项
        {
          value:1,
          label:'乐电',
          children:[
            { value:1,label:'光阳国际'},
            { value:2,label:'近江八园'},
          ]
        },
      ],
      isCameraOptions:[ // 是否携带摄像头、开启头盔识别选项
        {
          value:1,
          label:'携带摄像头',
          children:[
            {value:1,label:'头盔识别开启'},
            {value:0,label:'头盔识别关闭'},
          ]
        },
        {
          value:0,
          label:'无摄像头',
        },
      ],
      isCamera:[],
      unitId:"",
      props: { // 所属商户、小区 联动选择框配置
        value:'id',
        label:'name',
        lazy: true,
        checkStrictly:true,
        lazyLoad (node, resolve) {
          const { level } = node;
          console.log(node,987)
          setTimeout(() => {
            let data = {
              pageNo:1,
              pageSize:1000,
              param:{
                name:null,
                merchantId:node.value,
              }
            }
            if(level == 1){
              getNeighbourhoodList(data).then(res=>{
              // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                let nodes = [];
                res.data.list.forEach(element=>{
                    nodes.push({
                      id: element.id,
                      name: element.name,
                      leaf: level >= 1,
                      children:null,
                    })
                })
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                resolve(nodes);
              })
              console.log(node,908)
            }
          }, 1000);
        }
      },
      activeName:'power', // 配置tab卡
      radiusOptions:[ // 感应范围选项
        {value:16,label:0.8},
        {value:17,label:1},
        {value:18,label:1.5},
        {value:19,label:2},
        {value:20,label:2.5},
        {value:21,label:3},
        {value:22,label:4},
        {value:23,label:4.5},
        {value:24,label:5},
        {value:25,label:6},
        {value:26,label:7},
        {value:27,label:9},
        {value:28,label:10},
        {value:29,label:12},
        {value:30,label:13},
        {value:31,label:14},
        {value:32,label:15},
        {value:33,label:16},
      ],
    }
  },

  components: {add,Pagination},

  computed: {},

  mounted() {
    this.userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    this.init();
    this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : this.getNieghbourList();
  },

  methods: {
    // 条件筛选事件
    handleSearch(type){
      if(type == 'clear'){
        this.searchInfo.param = {};
        this.unitId = [];
        this.isCamera = [];
      }else{
        this.searchInfo.param.unitId = this.searchInfo.param.unitId ? this.searchInfo.param.unitId : null;
      }
      this.searchInfo.pageNo = 1;
      this.init();
    },
    // 初始化获取表格数据
    async init(){
      await getBarrierGateList(this.searchInfo).then(res=>{
        if(res.success){
          this.tableList = res.data.list;
          this.searchInfo.total = res.data.total;
          this.tableList.forEach(element => {
            element.createTime = timeChange(element.createTime,'seconds');
          });
        }else{
          this.tableList = [];
          this.searchInfo.total = 0;
          this.$message({
            message:res.msg,
            type:'fail',
          })
        }
      })
    },
    // 获取小区列表
    getNieghbourList(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:null,
        }
      }
      getNeighbourhoodList(data).then(res=>{
        if(res.success){
          this.unitOptions = res.data.list;
        }
      })
    },
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.managerUnitOptions = res.data.list;
          this.managerUnitOptions.forEach(element=>{
            element.children = [];
          })
        }else{
          this.managerUnitOptions = [];
        }
      })
    },
    // 选择商户、小区
    chooseMerchant(e){
      this.searchInfo.param.merchantId = e[0] ? e[0] : null;
      this.searchInfo.param.unitId = e[1] ? e[1] : null;
    },
    // 删除
    handleDel(row){
      this.$confirm('确认删除吗？').then(async () => {
        deleteBarrierGate({param:row.id}).then(res=>{
          if(res.success){
            this.$message({
              message:'操作成功',
              type:'success'
            })
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
        })
      })
    },
    // 开启、关闭头盔识别
    changeHelmetRecognition(row){
      let data = {
        param:{
          deviceId:row.id,
          photoStatus:row.photoStatus == 0 ? 1 : 0
        }
      }
      this.$confirm('确认开启/关闭头盔识别吗？').then(async ()=>{
        await setPhotoStatus(data).then(res=>{
          this.$message({
            message:res.success ? '指令下发成功，等待设备回复' : res.msg,
            type:res.success ? 'success' : 'fail',
          })
        })
        this.init();
      })
    },
    // 开闸
    openBrake(row){
      this.$confirm('确认进行该操作吗？').then(async () => {
        await openBarrierGate({param:row.id}).then(res=>{
          if(res.success){
            this.$message({
              message:'指令下发成功，等待设备回复',
              type:'success'
            })
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
        })
      })
    },
    // 关闸
    closeBrake(row){
      this.$confirm('确认进行该操作吗？').then(async () => {
        await closeBarrierGate({param:row.id}).then(res=>{
          if(res.success){
            this.$message({
              message:'指令下发成功，等待设备回复',
              type:'success'
            })
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
        })
      })
    },
    // 重启
    restartBrake(row){
      this.$confirm('确认进行该操作吗？').then(async () => {
        await restartBarrierGate({param:row.id}).then(res=>{
          if(res.success){
            this.$message({
              message:'指令下发成功，等待设备回复',
              type:'success'
            })
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
        })
      })
    },
    // 前往子页面
    toChild(row,name){
      this.$router.push(
        {
          name:name, 
          query: {
            id: row.id,
            cameraStatus: row.cameraStatus ? row.cameraStatus : 0
          }
        }
      );
    },
    // 打卡侧边弹框
    openDrawer(row,type){
      this.row = row;
      this.addDrawer = true;
    },
    // 关闭弹框
    handleClose(){
      this.addDrawer = false;
      this.row = null;
      this.init();
    },
    // 打开配置弹框
    disposition(row,title){
      this.dispositionInfo = {...row};
      this.dispositionInfo.heartbeatInterval = this.dispositionInfo.heartbeatInterval ? this.dispositionInfo.heartbeatInterval / 1000 : this.dispositionInfo.heartbeatInterval;
      this.dispositionInfo.readInterval = this.dispositionInfo.readInterval ? this.dispositionInfo.readInterval / 1000 : this.dispositionInfo.readInterval;
      this.dispositionInfo.sendInterval = this.dispositionInfo.sendInterval ? this.dispositionInfo.sendInterval / 1000 : this.dispositionInfo.sendInterval;
      this.dispositionInfo.timeout = this.dispositionInfo.timeout ? this.dispositionInfo.timeout / 1000 : this.dispositionInfo.timeout;
      this.title = title;
      this.open = true;
    },
     // 关闭
     close(){
      this.open = false;
      this.init();
    },
    // 提交配置
    confirm(){
      this.$confirm('确认进行该操作吗？').then(()=>{
        this.dispositionInfo.deviceId = this.dispositionInfo.id;
        if(this.activeName == 'power'){
          this.setPower();
        }else if(this.activeName == 'interval'){
          this.setInterval();
        }else{
          this.setTimeout();
        }
      })
    },
    // 设置时间间隔
    async setInterval(){
      this.dispositionInfo.heartbeatInterval = Number(this.dispositionInfo.heartbeatInterval) * 1000;
      this.dispositionInfo.readInterval = Number(this.dispositionInfo.readInterval) * 1000;
      this.dispositionInfo.sendInterval = Number(this.dispositionInfo.sendInterval) * 1000;
      await setIntervalBarrierGate({param:this.dispositionInfo}).then(res=>{
        if(res.success){
          this.$message({
            message:'提交成功,指令下发成功后需等待几分钟,设备才会生效,请耐心等待',
            type:'success'
          })
          this.init();
          this.open = false;
        }else{
          this.$message({
            message:res.msg,
            type:'fail'
          })
        }
      })
    },
    // 设置功率
    async setPower(){
      this.dispositionInfo.power = Number(this.dispositionInfo.power);
      await setPowerBarrierGate({param:this.dispositionInfo}).then(res=>{
        if(res.success){
          this.$message({
            message:'提交成功,指令下发成功后需等待几分钟,设备才会生效,请耐心等待',
            type:'success'
          })
          this.init();
          this.open = false;
        }else{
          this.$message({
            message:res.msg,
            type:'fail'
          })
        }
      })
    },
    // 设置超时
    async setTimeout(){
      this.dispositionInfo.timeout = Number(this.dispositionInfo.timeout) * 1000;
      await setTimeoutBarrierGate({param:this.dispositionInfo}).then(res=>{
        if(res.success){
          this.$message({
            message:'提交成功,指令下发成功后需等待几分钟,设备才会生效,请耐心等待',
            type:'success'
          })
          this.init();
          this.open = false;
        }else{
          this.$message({
            message:res.msg,
            type:'fail'
          })
        }
      })
    },
    // 分页操作事件
    callFather(parm){
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped lang="scss"> 
</style>
