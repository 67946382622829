<template>
    <div class="outContainer">
      <!-- 返回 -->
      <div class="back">
          <i class="el-icon-back" @click="back"></i>
          <span>停车列表</span>
      </div>
      <!-- 新增 -->
      <div class="add">
        <el-button type="primary" size="mini" @click="handleAdd">新增</el-button>
      </div>
      <!-- 内容区 -->
      <div class="tableContainer">
        <!-- 数据表格 -->
        <el-table
          :data="tableList"
          height="100%"
          style="width: 100%;"
        >
          <!-- 数据展示区 -->
          <el-table-column align='center' label="区域名称" prop="name">
          </el-table-column>
          <el-table-column align='center' label="区域类型" prop="type">
            <template slot-scope="scope">
              <p>{{ scope.row.type == 1 ? '停放区' : '禁行区' }}</p>
            </template>
          </el-table-column>
          <el-table-column align='center' label="备注" prop="remark">
          </el-table-column>
          <el-table-column align='center' label="停车区范围" prop="ranges">
            <template slot-scope="scope">
              <el-button size="mini" @click="checkRange(scope.row)">查看范围</el-button>
            </template>
          </el-table-column>
          <el-table-column align='center' label="操作" fixed="right" width="220">
            <template slot-scope="scope">
              <div style="margin-bottom: 5px;">
                <el-button size="mini" @click="handleAddInductor(scope.row,'add')" >新增感应器</el-button>
                <el-button size="mini" @click="handleAddSwitch(scope.row,'add')" >新增4G开关</el-button>
              </div>
              <div>
                <el-button size="mini" @click="handleCheckDevice(scope.row,scope.$index)" >查看关联设备</el-button>
                <el-button size="mini" @click="handleDel(scope.row)" >删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 停车区范围弹框 -->
      <el-dialog title="停车区范围" :visible.sync="open" width="900px" append-to-body>
        <div id="map-container" style="width: 100%; height: 400px;"></div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel">关闭</el-button>
        </div>
      </el-dialog>
      <!-- 新增 -->
      <el-drawer title="新增区域"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose"
        size="50%">
        <add v-if="addDrawer" @close='handleClose'  ref='newForm' :row="neighborInfo"></add>
      </el-drawer>
      <el-drawer title="新增感应器"
        :visible.sync="addInductorDrawer"
        :direction="direction"
        :before-close="handleClose"
        size="50%">
        <addInductor v-if="addInductorDrawer" @close='handleClose' ref='newForm' :row="row"></addInductor>
      </el-drawer>
      <el-drawer title="新增4G开关"
        :visible.sync="addSwitchDrawer"
        :direction="direction"
        :before-close="handleClose"
        size="50%">
        <addSwitch v-if="addSwitchDrawer" @close='handleClose' ref='newForm' :row="row"></addSwitch>
      </el-drawer>
      <!-- 配置 -->
      <el-dialog title="配置" :visible.sync="dispositionDialog" width="60%" append-to-body>
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane label="感应范围" name="power">
            <p class="remind">（指令下发成功后需重启才生效）</p>
            <el-form label-width="180px">
              <el-form-item label="设备编号">
                <el-input v-model="dispositionInfo.id" disabled></el-input>
              </el-form-item>
              <el-form-item label="感应范围（米）">
                <el-select v-model="dispositionInfo.power" style="width: 100%;">
                  <el-option
                    v-for="item in radiusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="时间间隔" name="interval">
            <p class="remind">（指令下发成功后需等待几分钟,设备才会生效,请耐心等待）</p>
            <el-form label-width="180px">
              <el-form-item label="设备编号">
                <el-input v-model="dispositionInfo.id" disabled></el-input>
              </el-form-item>
              <el-form-item label="心跳时间间隔（秒）" v-if="userInfo && userInfo.roleLevel === 0">
                <el-input v-model="dispositionInfo.heartbeatInterval" placeholder="请输入心跳时间间隔"></el-input>
              </el-form-item>
              <el-form-item label="读标签时间间隔（秒）">
                <el-input v-model="dispositionInfo.readInterval" placeholder="请输入读标签时间间隔"></el-input>
              </el-form-item>
              <el-form-item label="上报时间间隔（秒）">
                <el-input v-model="dispositionInfo.sendInterval" placeholder="请输入上报时间间隔"></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dispositionDialog = false">关闭</el-button>
          <el-button @click="confirm">提交</el-button>
        </div>
      </el-dialog>
      <!-- 关联设备 -->
      <el-dialog title="关联设备" :visible.sync="deviceDialog" width="80%" @close="closeDialog" append-to-body>
        <!-- 感应器 -->
        <h3>感应器</h3>
        <el-table 
         :data="inductorList"
         :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
         row-key="id"
         :header-cell-style="{ background:'#ddd' }"
         style="margin-bottom: 10px;"
         border>
          <el-table-column align='center' label="设备编号" prop="id">
          </el-table-column>
          <el-table-column align="center" label="方向类型" prop="directionType">
            <template slot-scope="props">
              <p v-if="props.row.directionType == 0">主→副:进入</p>
              <p v-if="props.row.directionType == 0">副→主:离开</p>
              <p v-if="props.row.directionType == 1">主→副:离开</p>
              <p v-if="props.row.directionType == 1">副→主:进入</p>
              <p v-if="!props.row.directionType && props.row.directionType != 0">--</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="时间间隔（秒）" prop="identifyInterval">
            <template slot-scope="props">
              <p>心跳间隔:{{ props.row.heartbeatInterval ? Number(props.row.heartbeatInterval) / 1000 : '--'}}</p>
              <p>读标签间隔:{{ props.row.readInterval ? Number(props.row.readInterval)/1000 : '--'}}</p>
              <p>上报间隔:{{ props.row.sendInterval ? Number(props.row.sendInterval)/1000 : '--'}}</p>
            </template>
          </el-table-column>
          <el-table-column align='center' label="感应范围（米）" prop="power">
            <template slot-scope="props">
              <span v-if="props.row.power == 16">0.8</span>
              <span v-if="props.row.power == 17">1</span>
              <span v-if="props.row.power == 18">1.5</span>
              <span v-if="props.row.power == 19">2</span>
              <span v-if="props.row.power == 20">2.5</span>
              <span v-if="props.row.power == 21">3</span>
              <span v-if="props.row.power == 22">4</span>
              <span v-if="props.row.power == 23">4.5</span>
              <span v-if="props.row.power == 24">5</span>
              <span v-if="props.row.power == 25">6</span>
              <span v-if="props.row.power == 26">7</span>
              <span v-if="props.row.power == 27">9</span>
              <span v-if="props.row.power == 28">10</span>
              <span v-if="props.row.power == 29">12</span>
              <span v-if="props.row.power == 30">13</span>
              <span v-if="props.row.power == 31">14</span>
              <span v-if="props.row.power == 32">15</span>
              <span v-if="props.row.power == 33">16</span>
              <span v-if="!props.row.power">--</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="版本" prop="version">
            <template slot-scope="props">
              <p>硬件版本：{{ props.row.hardWareVersion ? props.row.hardWareVersion : '--'}}</p>
              <p>软件版本：{{ props.row.version ? props.row.version : '--'}}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="在线状态" prop="status">
            <template slot-scope="props">
              <p>{{ props.row.status == 1 ? '在线' : (props.row.status == 2 ? '离线' : '--')}}</p>
            </template>
          </el-table-column>
          <el-table-column align='center' label="具体安装位置" prop="adders">
          </el-table-column>
          <el-table-column align="center" label="安装时间" prop="createTimeStr">
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right" width="200">
            <template slot-scope="props">
              <p style="margin-bottom: 5px;">
                <el-button size="mini" @click="toChild(props.row,'感应器感应记录')">感应记录</el-button>
                <el-button size="mini" @click="toChild(props.row,'感应器通讯记录')">通讯记录</el-button>
              </p>
              <el-button size="mini" @click="disposition(props.row)">配置</el-button>
              <el-button size="mini" @click="handleRestart(props.row)" v-if="props.row.mainId">重启</el-button>
              <el-popover
                  placement="top"
                  trigger="click"
                  width="auto"
                  v-if="!props.row.mainId"
                  >
                  <div style="text-align: right; margin: 0;display: flex;justify-content: space-between">
                    <span class="spanBtn" @click="handleRestart(props.row)">重启</span>
                    <span class="spanBtn" @click="handleAddInductor(props.row,'edit')">编辑</span>
                    <span class="spanBtn" @click="delInductor(props.row)">删除</span>
                  </div>
                  <el-button style="margin-left: 10px;" slot="reference" size="mini">更多</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <!-- 4G开关 -->
        <h3>4G开关</h3>
        <el-table
         :data="switchList"
         :header-cell-style="{ background:'#ddd' }"
         border>
          <el-table-column align="center" label="4G开关编号" prop="id"></el-table-column>
          <el-table-column align="center" label="开关名称" prop="name"></el-table-column>
          <el-table-column align="center" label="在线状态" prop="online">
            <template slot-scope="props">
              {{ props.row.online == 1 ? '在线' : ( props.row.online == 2 ? '离线' : '--' )}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="开启状态" prop="status">
            <template slot-scope="props">
              <el-switch
                :disabled="props.row.online == 2"
                :value="props.row.status == 1 ? true : false"
                active-color="#13ce66"
                @change="changeStatus(props.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" label="版本" prop="version">
            <template slot-scope="props">
              <p>硬件版本：{{ props.row.hardWareVersion ? props.row.hardWareVersion : '--' }}</p>
              <p>软件版本：{{ props.row.version ? props.row.version : '--' }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="创建时间" prop="createTimeStr">
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right" width="200">
            <template slot-scope="props">
              <p style="margin-bottom: 5px;">
                <el-button size="mini" @click="checkCommunicate(props.row)">通讯记录</el-button>
              </p>
              <p>
                <el-button size="mini" @click="handleAddSwitch(props.row,'edit')">编辑</el-button>
                <el-button size="mini" @click="delSwitch(props.row,props.$index)">删除</el-button>
              </p>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </template>
  
  <script>
  require('echarts/extension/bmap/bmap');
  import { getUnitAreaList,delUnitArea,setPowerInductor,setIntervalInductor,restartInductor,lockSwitch,delWifiSwitch,deleteInductor } from '@/api/public.js';
  import edit from './edit.vue';
  import add from './add.vue';
  import addInductor from './addInductor.vue';
  import addSwitch from './addSwitch.vue';
  import { timeChange } from '@/api/publicFun.js';
  export default {
    data() {
      return {
        tableList:[], // 数据表格信息
        addDrawer:false, // 是否打开新增区域弹框
        addInductorDrawer:false, // 是否打开新增感应器弹框
        addSwitchDrawer:false, // 是否打开新增4G开关弹框
        direction:'rtl', // 弹窗打开方向
        searchInfo:{ // 查询条件
          param:''
        },
        row:{}, // 打开弹窗携带的参数
        neighborInfo:{}, // 小区信息
        open:false, // 停车区范围弹框
        Polygon:[],
        map:null,
        dispositionInfo:{ // 配置信息
          timeout:-1,
        },
        activeName:'power', // 配置弹框当前激活的tab选项
        userInfo:JSON.parse(localStorage.getItem('userInfo')), // 当前登录账号用户信息
        dispositionDialog:false,
        radiusOptions:[ // 感应范围选项
          {value:16,label:0.8},
          {value:17,label:1},
          {value:18,label:1.5},
          {value:19,label:2},
          {value:20,label:2.5},
          {value:21,label:3},
          {value:22,label:4},
          {value:23,label:4.5},
          {value:24,label:5},
          {value:25,label:6},
          {value:26,label:7},
          {value:27,label:9},
          {value:28,label:10},
          {value:29,label:12},
          {value:30,label:13},
          {value:31,label:14},
          {value:32,label:15},
          {value:33,label:16},
        ],
        inductorList:[],
        switchList:[],
        deviceDialog:false,
        index:null,
      }
    },
  
    components: {
      add,
      edit,
      addInductor,
      addSwitch,
    },
  
    computed: {},
  
    mounted() {
      this.searchInfo.param = this.$route.query.neighborInfoId;
      this.neighborInfo.id = this.$route.query.neighborInfoId;
      this.neighborInfo.merchantId = this.$route.query.merchantId;
      this.init();
    },
  
    methods: {
        // 初始化
        async init(){
          await getUnitAreaList(this.searchInfo).then(res=>{
            if(res.success){
              this.tableList = res.data;
              this.tableList.forEach(async element => {
                element.createTimeStr = timeChange(element.createTime,'seconds');
                if(element.inductors && element.inductors.length > 0){
                  element.inductors.forEach(inductor => {
                    inductor.createTimeStr = timeChange(inductor.createTime,'seconds');
                    inductor.children = [inductor.auxiliaryInductor]
                  })
                }
                if(element.switchDevices && element.switchDevices.length > 0){
                  element.switchDevices.forEach(eachEwitch => {
                    eachEwitch.createTimeStr = timeChange(eachEwitch.createTime,'seconds');
                  })
                }
              });
              if(this.index || this.index == 0){
                this.$nextTick(()=>{
                  this.tableList = [...this.tableList];
                  this.handleCheckDevice(this.tableList[this.index],this.index);
                })
              }
            }else{
              this.tableList = [];
            }
          })
          this.tableList = [...this.tableList];
        },
        // 返回上一页handleDel
        back(){
            this.$router.go(-1);
        },
        // 删除
        handleDel(row){
          this.$confirm('确认删除吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(res=>{
            delUnitArea({param:row.id}).then(res=>{
              if(res.success){
                this.$message({
                  message:'删除成功',
                  type:'success'
                });
                this.init();
              }else{
                this.$message({
                  message:res.msg,
                  type:'fail',
                })
                this.init();
              }
            })
          })
          
        },
        // 查看停车区
        checkRange(row){
          this.open = true;
          /* 初始化地图 start */
          this.Polygon = JSON.parse(row.ranges);
          let arr=[];
          for(let i = 0;i<this.Polygon.length;i++){
            arr.push(new BMapGL.Point(this.Polygon[i][0],this.Polygon[i][1]));
          }
          this.$nextTick(()=>{
            this.map = new BMapGL.Map("map-container"); // 创建地图实例
            var point = new BMapGL.Point(this.Polygon[0][0],this.Polygon[0][1]); // 设置中心点坐标
            this.map.centerAndZoom(point, 20); // 地图初始化，同时设置地图展示级别
            this.map.setMapType(BMAP_NORMAL_MAP);
            this.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
            this.map.clearOverlays();
            var polygon = new BMapGL.Polygon(arr, {strokeColor:"blue", strokeWeight:2, strokeOpacity:0.5});  // 创建多边形
            this.map.addOverlay(polygon);  // 增加多边形
          })
          /* 初始化地图 end */
        },
        // 关闭查看范围地图
        cancel(){
          this.open = false;
        },
        // 打开新增区域
        handleAdd(){
          this.row = this.neighborInfo;
          this.addDrawer=true;
        },
        // 打开新增感应器
        handleAddInductor(row,type){
          if(type == 'add'){
            this.row.unitId = this.neighborInfo.id;
            this.row.areaId = row.id;
            this.row.merchantId = this.neighborInfo.merchantId;
          }else{
            this.row = {...row};
            this.row.unitId = this.neighborInfo.id;
            this.row.areaId = row.areaId;
            this.row.merchantId = this.neighborInfo.merchantId;
          }
          this.addInductorDrawer = true;
        },
        // 打开新增4G开关
        handleAddSwitch(row,type){
          if(type == 'add'){
            this.row.unitId = this.neighborInfo.id;
            this.row.parentId = row.id;
            this.row.merchantId = this.neighborInfo.merchantId;
          }else{
            this.row = {...row};
            this.row.unitId = this.neighborInfo.id;
            this.row.merchantId = this.neighborInfo.merchantId;
          }
          this.addSwitchDrawer = true;
        },
        // 打开查看关联设备
        handleCheckDevice(row,index){
          if(row.inductors || row.switchDevices){
            this.index = index;
            this.inductorList = row.inductors ? [...row.inductors] : [];
            this.inductorList.forEach(element=>{
              element.children = element.auxiliaryInductor ? [element.auxiliaryInductor] : [];
            })
            this.switchList = row.switchDevices ? [...row.switchDevices] : [];
            this.deviceDialog = true;
          }else{
            this.$message({
              message:'该区域无关联设备',
              type:'fail'
            })
          }
        },
        // 关闭查看关联设备弹框回调
        closeDialog(){
          this.index = null;
        },
        // 操作关闭
        handleClose(){
            this.row = {};
            this.init();
            this.addDrawer = false;
            this.addInductorDrawer = false;
            this.addSwitchDrawer = false;
        },
        // 操作成功
        handleDrawersuccess(){
            this.init();
            this.addDrawer = false;
            this.addInductorDrawer = false;
            this.addSwitchDrawer = false;
        },
        // 打开、关闭开关
        changeStatus(e){
          this.$confirm('确认进行该操作吗？').then(async () => {
            const loading = this.$loading({
              lock: true,
              text: '操作中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = {
              param:{
                switchLock:e.status == 1 ? 0 : 1,
                wifiId:e.id,
              }
            }
            await lockSwitch(data).then(res=>{
              if(res.success && res.data.result){
                this.$message({
                  message:'操作成功',
                  type:'success'
                })
              }else{
                this.$message({
                  message:'操作失败',
                  type:'fail'
                })
              }
              this.init();
              loading.close();
            })
          })
        },
        // 查看4G开关通讯记录
        checkCommunicate(row){
          this.$router.push({path:'/inductorManage/switchCommuication',query:{id:row.id}})
        },
        // 删除开关
        delSwitch(row){
          this.$confirm('确认删除吗？').then(async () => {
            await delWifiSwitch({ param:row.id }).then(res=>{
              if(res.success){
                this.$message({
                  message:'操作成功',
                  type:'success'
                })
              }else{
                this.$message({
                  message:res.msg,
                  type:'fail'
                })
              }
              this.init();
            })
          })
        },
        // 删除感应器
        delInductor(row){
          this.$confirm('确认删除吗？').then(async () => {
            await deleteInductor({param:row.id}).then(res=>{
              if(res.success){
                this.$message({
                  message:'删除成功！',
                  type:"success"
                })
              }else{
                this.$message({
                  message:res.msg,
                  type:"fail"
                })
              }
            })
            this.init();
          })
        },
        // 前往子页面
        toChild(row,name){
          this.$router.push({name:name, query: {id: row.id}});
        },
        // 重启感应器
        handleRestart(row){
          this.$confirm('确认进行该操作吗？').then(async () => {
            await restartInductor({param:row.id}).then(res=>{
              if(res.success){
                this.$message({
                  message:'操作成功',
                  type:'success'
                })
                this.init();
              }else{
                this.$message({
                  message:res.msg,
                  type:'error',
                })
              }
            })
          })
        },
        // 打开配置
        disposition(row){
          this.dispositionInfo = {...row};
          this.dispositionInfo.heartbeatInterval = this.dispositionInfo.heartbeatInterval ? this.dispositionInfo.heartbeatInterval / 1000 : this.dispositionInfo.heartbeatInterval;
          this.dispositionInfo.readInterval = this.dispositionInfo.readInterval ? this.dispositionInfo.readInterval / 1000 : this.dispositionInfo.readInterval;
          this.dispositionInfo.sendInterval = this.dispositionInfo.sendInterval ? this.dispositionInfo.sendInterval / 1000 : this.dispositionInfo.sendInterval;
          this.dispositionDialog = true;
        },
        // 提交配置
        confirm(){
          this.$confirm('确认进行该操作吗？').then(async () => {
            this.dispositionInfo.deviceId = this.dispositionInfo.id;
            if(this.activeName == 'power'){
              this.dispositionInfo.power = Number(this.dispositionInfo.power);
              await setPowerInductor({param:this.dispositionInfo}).then(res=>{
                if(res.success){
                  this.$message({
                    message:'提交成功,指令下发成功后需等待几分钟,设备才会生效,请耐心等待',
                    type:'success'
                  })
                  this.init();
                  this.dispositionDialog = false;
                }else{
                  this.$message({
                    message:res.msg,
                    type:'fail'
                  })
                }
              })
            }else{
              this.dispositionInfo.heartbeatInterval = Number(this.dispositionInfo.heartbeatInterval) * 1000;
              this.dispositionInfo.readInterval = Number(this.dispositionInfo.readInterval) * 1000;
              this.dispositionInfo.sendInterval = Number(this.dispositionInfo.sendInterval) * 1000;
              await setIntervalInductor({param:this.dispositionInfo}).then(res=>{
                if(res.success){
                  this.$message({
                    message:'提交成功,指令下发成功后需等待几分钟,设备才会生效,请耐心等待',
                    type:'success'
                  })
                  this.init();
                  this.dispositionDialog = false;
                }else{
                  this.$message({
                    message:res.msg,
                    type:'fail'
                  })
                }
              })
            }
          })
        },
    },
  };
  </script>
  <style scoped lang="scss">
  /deep/ .el-dialog{
      max-height: 80% !important;
      overflow: auto;
      display: flex;
      flex-direction: column;
      .el-dialog__body{
          flex: 1;
      }
  }
    .remind{
      color: red;
      text-align: center;
      margin-bottom: 20px;
    }
    .nested-table {
      width: 100%;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
    }
    /deep/ .el-table .row-expand-cover .cell .el-table__expand-icon {
      display: none !important;
    }
    // 返回上一页 
    .backToNeighborList{
      display: flex;
      justify-content: space-between;
      padding: 0 0 15px;
      .el-icon-back{
          font-size: 25px;
          cursor: pointer;
      }
    }
    /* 添加 */
    .add{
      width: 100%;
      // background-color: white;
      height: 60px;
      width: 100%;
      padding: 10px 0;
      position: relative;
      button{
        float: right;
        margin:0 10px 0 0 ;
        text-align: center;
      }
    }
  </style>
  