<template>
    <div class="outContainer">
      <div class="back">
        <i class="el-icon-back" @click="back"></i>
        <span>{{this.$route.query.id}}</span>
      </div>
      <!-- 筛选条件栏 -->
      <div class="search">
        <el-select class='select each' v-model="searchInfo.param.type" clearable placeholder="通讯类型" @change="changeType">
          <el-option
            v-for="item in communicationOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="tableContainer">
        <el-table
        :data="tableList"
        height="100%"
        style="width: 100%;">
          <af-table-column align="center" label="指令" prop="action">
            <template slot-scope="scope">
              <p v-if="scope.row.action == 'gate'">道闸控制({{ scope.row.cmd == 'open' ? '开闸' : (scope.row.cmd == 'off' ? '关闸' : '--') }})</p>
              <p v-if="scope.row.action == 'heartbeat'">心跳</p>
              <p v-if="scope.row.action == 'config'">配置</p>
              <p v-if="scope.row.action == 'voice'">语音播报</p>
              <p v-if="scope.row.action == 'detection'">头盔识别</p>
            </template>
          </af-table-column>
          <af-table-column align="center" label="头盔识别" prop="picUpload" width="200">
            <template slot-scope="scope">
              <!-- RFID感应 -->
              <div v-if="scope.row.action == 'detection'">
                <p v-if="!scope.row.scores && scope.row.errorType == 0">未开启头盔识别</p>
                <!-- 头盔置信度数组 -->
                <div v-if="scope.row.helmetData.length > 0 && scope.row.scores">
                  <p v-for="(item,index) in scope.row.helmetData" :key="index">{{ item.label }} {{ item.value }}%</p>
                </div>
              </div>
              <div v-if="scope.row.action == 'detection' && scope.row.errorType">
                {{ scope.row.errorTypeStr }}
              </div>
              <!-- 其他指令 -->
              <p v-if="scope.row.action != 'detection'">--</p>
            </template>
          </af-table-column>
          <af-table-column align="center" label="语音播报内容" prop="voice">
            <template slot-scope="scope">
              <p>{{ scope.row.voice ? scope.row.voice : '--' }}</p>
            </template>
          </af-table-column>
          <af-table-column align="center" label="无网开闸超时时间" prop="timeout">
            <template slot-scope="scope">
              <p>{{ scope.row.timeout || scope.row.timeout == 0 ? ( scope.row.timeout == 0 ? '无网不开道闸' : Number(scope.row.timeout) + "秒" ) : '--' }}</p>
            </template>
          </af-table-column>
          <af-table-column align="center" label="语音开启状态" prop="sound">
            <template slot-scope="scope">
              <p>{{ scope.row.sound ? ( scope.row.sound == 'on' ? '开启' : '关闭' ) : '--' }}</p>
            </template>
          </af-table-column>
          <af-table-column align="center" label="等待提示音内容" prop="triggerVoice">
            <template slot-scope="scope">
              <p>{{ scope.row.triggerVoice ? scope.row.triggerVoice : '--' }}</p>
            </template>
          </af-table-column>
          <af-table-column align="center" label="灯光开启状态" prop="light">
            <template slot-scope="scope">
              <p>{{ scope.row.light ? ( scope.row.light == 'on' ? '常开' : '自动' ) : '--' }}</p>
            </template>
          </af-table-column>
          <af-table-column align="center" label="版本" prop="version">
            <template slot-scope="scope">
              <p>固件版本：{{ scope.row.version ? scope.row.version : '--' }}</p>
              <p>软件版本：{{ scope.row.hardwareVersion ? scope.row.hardwareVersion : '--' }}</p>
            </template>
          </af-table-column>
          <af-table-column align="center" label="是否成功" prop="result">
            <template slot-scope="scope">
              <p>{{ scope.row.result == 0 ? '成功' : (scope.row.isSuccess == -1 ? '失败' : '--')}}</p>
            </template>
          </af-table-column>
          <af-table-column align="center" label="创建时间" prop="createDatetime"></af-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <el-col :span="24" class="toolbar pagination">
        <el-pagination
          background
          layout=" prev , next , jumper"
          @prev-click="prevpage"
          @next-click="nextpage"
          @current-change="handleCurrentChange"
          :page-size="10"
          style="float:right;"
        ></el-pagination>
      </el-col>
    </div>
  </template>
  
  <script>
  import {getInfraredHelmetCommunication} from '@/api/public.js';
  import {timeChange} from '@/api/publicFun.js';
  import Pagination from '@/components/Pagination.vue';
  export default {
    data() {
      return {
        searchInfo:{
          pageNo:1,
          pageSize:10,
          param:{
            deviceId:'',
            type:null,
          }
        },
        tableList:[],
        communicationOptions:[
          {value:1,label:'心跳'},
          {value:2,label:'头盔识别'},
          {value:3,label:'配置'},
          {value:4,label:'道闸控制'},
          {value:5,label:'语音播报'},
        ],
        carInfo:{},
      }
    },
  
    components: {Pagination},
  
    computed: {},
  
    mounted() {
      this.searchInfo.param.deviceId = this.$route.query.id;
      this.init();
    },
  
    methods: {
      back(){
        this.$router.go(-1)
      },
      changeType(e){
        this.searchInfo.pageNo = 1;
        this.searchInfo.param.type = e && e != "" ? e : null;
        this.init();
      },
      async init(){
        await getInfraredHelmetCommunication(this.searchInfo).then(res=>{
          if(res.success){
            if( !res.data.list || res.data.list.length == 0){
              this.tableList = [];
              this.$message({
                message:'没有更多通讯记录了',
                type:'remind'
              })
            }else{
              this.tableList = res.data.list;
              this.tableList.forEach(element => {
                element.createDatetime = timeChange(element.createDatetime,'seconds');
                // 转换头盔置信度数组
                element.scores = element.scores ? JSON.parse(element.scores) : element.scores;
                element.labels = element.labels ? JSON.parse(element.labels) : element.labels;
                element.helmetData = [];
                if(element.scores && element.labels){
                  element.scores.forEach((ele,ind)=>{
                    if( element.labels[ind] && element.labels[ind].indexOf('no') == -1){ // 如果该项不包含‘no’，则对应的数据是 佩戴头盔置信度
                      element.helmetData.push({label:'佩戴头盔置信度：',value:(Number(ele)*100).toFixed(2)});
                    }else{ // 如果该项包含‘no’，则对应的数据是 未佩戴头盔置信度
                      element.helmetData.push({label:'未佩戴头盔置信度：',value:(Number(ele)*100).toFixed(2)});
                    }
                    ele = {...ele};
                  })
                }
              });
            }
          }else{
            this.tableList = [];
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
        })
      },
      // 上一页
      prevpage(val){
        this.searchInfo.pageNo = val;
        this.init();
      },
      // 下一页
      nextpage(val){
        this.searchInfo.pageNo = val;
        this.init();
      },
      handleCurrentChange(val){
        this.searchInfo.pageNo = val;
        this.init();
      }
    },
  };
  </script>
  <style scoped>
  
  </style>
  