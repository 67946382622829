<template>
  <div class="home" id="home">
    <!-- 标题 -->
    <div class="firstTitle">
      <dv-decoration-8 :color="['#6cafcd']" :reverse="true" style="width:calc(100% / 2 - 310px);height:100%;" />
        <dv-decoration-11 :color="['#7de7ff']" style="width:600px;height:100%;color:#fff;margin: 0 10px;box-sizing: border-box;text-align: center;font-size: 0.11rem;font-weight: 600;">
              乐电电车管家&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;大数据中心
        </dv-decoration-11>
      <dv-decoration-8 :color="['#6cafcd']" style="width:calc(100% / 2 - 310px);height:100%;" />
    </div>
    <!-- 内容区 -->
    <div class="box">
      <!-- 左： -->
      <div class="leftContent">
        <h4  class="neighborName" v-if="neighbourhoodId && neighbourhoodId != ''">{{ NeighbourhoodName }}
          <button @click="backAll">返回</button>
        </h4>
        <!-- 统计数据 -->
        <dv-border-box-13 class="statisticData" :color="['#8eebf3']">
          <div style="width:100%;height:100%;display: flex;">
            <div class="top" style="width: 100%;height: 100%;display: flex;flex-direction: column; justify-content: space-around;padding: 0.11rem;">
                <h4 class="staticsTitle" style="text-align: left;">统计数据</h4>
                <!-- 统计 -->
                <div class="staticsBox">
                  <div class="eachOne" v-if="userInfo && userInfo.roleLevel <= 5 && neighbourhoodId == '' ">
                    <span>入驻社区</span>
                    <div class="eachStatics">
                      <dv-digital-flop :config="countCommunity" :style="{width:'0.38rem',height:'0.38rem'}" />
                    </div>
                  </div>
                  <div class="eachOne" v-if="userInfo && userInfo.roleLevel <= 5 && neighbourhoodId == '' ">
                    <span>入驻小区</span>
                    <div class="eachStatics">
                      <dv-digital-flop :config="countNeighbour" :style="{width:'0.38rem',height:'0.38rem'}" />
                    </div>
                  </div>
                  <div class="eachOne">
                    <span>登记车主</span>
                    <div class="eachStatics">
                      <dv-digital-flop :config="countowner" :style="{width:'0.38rem',height:'0.38rem'}" />
                    </div>
                  </div>
                  <div class="eachOne">
                    <span>安装车辆</span>
                    <div class="eachStatics">
                      <dv-digital-flop :config="countMonitoring" :style="{width:'0.38rem',height:'0.38rem'}" />
                    </div>
                  </div>
                </div>
                <h4 class="staticsTitle" style="margin-top: 10px;text-align: left;">月内新增数据</h4>
                <!-- 月内 -->
                <div class="staticsBox">
                  <div class="eachOne" v-if="userInfo && userInfo.roleLevel <= 5 && neighbourhoodId == '' ">
                    <span>入驻社区</span>
                    <b style="color: #eecc5e;">{{countData.thisMonthSettledCommunity}}</b>
                  </div>
                  <div class="eachOne" v-if="userInfo && userInfo.roleLevel <= 5 && neighbourhoodId == '' ">
                    <span>入驻小区</span>
                    <b style="color:#827df2">{{countData.thisMonthSettledUnit}}</b>
                  </div>
                  <div class="eachOne" >
                    <span>登记车主</span>
                    <b style="color: #eecc5e;">{{countData.thisMonthRegisteredOwner}}</b>
                  </div>
                  <div class="eachOne" >
                    <span>安装车辆</span>
                    <b style="color:#827df2">{{countData.thisMonthInstallVehicle}}</b>
                  </div>
                </div>
            </div>
            </div>
        </dv-border-box-13>
        <!-- 电瓶过温 -->
        <dv-border-box-12 class="abnormalType">
          <div class="show-word-animation-over-temp">
            <span>电池过温</span>
            <b>历史累计：{{abnormalInfo.excessTemperature}}</b>
            <b>今日新增：{{abnormalInfo.todayExcessTemperature}}</b>
            <b :class=" abnormalInfo.untreatedExcessTemperature !== 0 ? 'twinkle' : '' " @click="openAbnormalDialog(2)">
              未处理：{{ abnormalInfo.untreatedExcessTemperature }}
            </b>
          </div>
        </dv-border-box-12>
        <!-- 电瓶离车 -->
        <dv-border-box-12 class="abnormalType">
          <span class="show-word-animation-leave-car">
            <span>电池离车</span>
            <b style="">历史累计：{{abnormalInfo.accumulatorLeave}}</b>
            <b style="">今日新增：{{abnormalInfo.todayAccumulatorLeave}}</b>
            <b :class=" abnormalInfo.untreatedAccumulatorLeave !== 0 ? 'twinkle' : '' " @click="openAbnormalDialog(1)">未处理：{{abnormalInfo.untreatedAccumulatorLeave}}</b>
          </span>
        </dv-border-box-12>
        <!-- 车辆超速 -->
        <dv-border-box-12 class="abnormalType" v-if="userInfo && userInfo.roleLevel <= 5 && neighbourhoodId == '' ">
          <span class="show-word-animation-over-speed">
            <span>车辆超速</span>
            <b>历史累计：{{abnormalInfo.overSpeed}}</b>
            <b>今日新增：{{abnormalInfo.todayOverSpeed}}</b>
            <b  @click="checkNotSolve(4)">查看列表</b>
          </span>
        </dv-border-box-12>
        <!-- 车辆摔倒 -->
        <dv-border-box-12 class="abnormalType" v-if="userInfo && userInfo.roleLevel <= 5 && neighbourhoodId == '' ">
          <span class="show-word-animation-over-speed">
            <span>车辆摔倒</span>
            <b>历史累计：{{abnormalInfo.fallDown}}</b>
            <b>今日新增：{{abnormalInfo.todayFallDown}}</b>
            <b  @click="checkNotSolve(5)">查看列表</b>
          </span>
        </dv-border-box-12>
        <!-- 其他异常 -->
        <dv-border-box-12 class="abnormalType" >
          <span class="show-word-animation-others">
            <span>其他异常</span>
            <b style="">历史累计：{{abnormalInfo.other}}</b>
            <b style="">今日新增：{{abnormalInfo.todayOther}}</b>
            <b :class="abnormalInfo.untreatedOther !== 0 ? 'twinkle' : '' " @click="openAbnormalDialog(3)">未处理：{{abnormalInfo.untreatedOther}}</b>
          </span>
        </dv-border-box-12>
        <!-- 异常详情 -->
        <dv-border-box-13 class="abnormalDetail" :color="['#8eebf3']" v-if="!isEnlarge">
          <h5 style="color:#fff;text-align: left;margin-bottom: 5px;height: 20px;">
            异常详情
            <i class="el-icon-zoom-in" @click="enlarge(isEnlarge)"></i>
          </h5>
          <div class="abnormal" style="width:100%;padding:0;height: calc(100% - 10px);">
            <div style="display:flex;justify-content:flex-start;width: 100%;box-sizing: border-box;height:0.25rem;">
                <el-date-picker
                  :append-to-body='!isFullScreen'
                  :key="Math.random()"
                  v-model="abnormalDate"
                  type="date"
                  @input="chooseDate"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期">
                </el-date-picker>
                <div class="each">
                    <img src="@/static/img/efficiency.png" alt="" />
                    <p>
                        <b style="color: #eecc5e;">{{abnormalDetail.processingProgress}}%</b>
                        <span>处理进度</span>
                    </p>
                </div>
                <div class="each">
                    <img src="@/static/img/abnormal.png" alt="" />
                    <p>
                        <b>{{abnormalDetail.abnormalNumber}}</b>
                        <span>异常数量</span>
                    </p>
                </div>
            </div>
            <div style="width:100%;height: calc(100% - 0.27rem);">
              <dv-scroll-board :config="abnormalDetail.data" style="width:100%;height:95%;" />
            </div>
          </div>
        </dv-border-box-13> 
      </div>
      <!-- 右：地图 -->
      <dv-border-box-6 class="mapContent" :color="['red', 'green']" id="map" v-if="!isEnlarge">
        <screen @clickNeighbour="clickNeighbour" :value="notSolve"></screen>
      </dv-border-box-6>
      <!-- 异常详情放大版 -->
      <dv-border-box-13 class="abnormalDetailBig" :color="['#8eebf3']" v-else>
        <h5 style="color:#fff;text-align: left;margin-bottom: 5px;height: 20px;">
          异常详情
          <i class="el-icon-zoom-out" @click="enlarge(isEnlarge)"></i>
        </h5>
        <div class="abnormal" style="width:100%;padding:0;height: calc(100% - 10px);">
          <div style="display:flex;justify-content:flex-start;width: 100%;box-sizing: border-box;height:0.25rem;">
            <el-date-picker
              :append-to-body='!isFullScreen'
              :key="Math.random()"
              v-model="abnormalDate"
              type="date"
              value-format="yyyy-MM-dd"
              @input="chooseDate"
              placeholder="选择日期">
            </el-date-picker>
            <div class="each">
              <img src="@/static/img/efficiency.png" alt="" />
              <p>
                  <b style="color: #eecc5e;">{{abnormalDetail.processingProgress}}%</b>
                  <span>处理进度</span>
              </p>
            </div>
            <div class="each">
              <img src="@/static/img/abnormal.png" alt="" />
              <p>
                  <b>{{abnormalDetail.abnormalNumber}}</b>
                  <span>异常数量</span>
              </p>
            </div>
          </div>
          <div style="width:100%;height: calc(100% - 0.27rem);">
            <dv-scroll-board :config="abnormalDetail.data" style="width:100%;height:95%;" />
          </div>
        </div>
      </dv-border-box-13>
      <!-- 车辆超速、摔倒弹框 -->
      <div class="overSpeed">
        <el-dialog
          :title="abnormalType == 4 ? '超速车辆列表' : '车辆摔倒列表'"
          :visible.sync="showOverSpeed"
          width="70%"
        >
          <div class="flexBox">
            <div class="tableBox">
              <el-table :data="overSpeedList" height="500px" border style="width:100%" fit>
                <el-table-column align="center" label="设备编号" prop="deviceId">
                </el-table-column>
                <el-table-column align="center" label="车辆信息" prop="LicencePlate">
                  <template slot-scope="scope">
                    <p>{{ scope.row.plateNumber }}({{ scope.row.brand }})</p>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="车主信息" prop="OwnerName">
                  <template slot-scope="scope">
                    <p>{{ scope.row.ownerName ? scope.row.ownerName : '--' }}</p>
                    <p>{{ scope.row.ownerPhone ? scope.row.ownerPhone : '--' }}</p>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="摔倒车速(km/h)" prop="currentSpeed" v-if="abnormalType == 5">
                </el-table-column>
                <el-table-column align="center" label="开始时间" prop="createTime">
                </el-table-column>
                <el-table-column align="center" label="恢复时间" prop="updateTime">
                  <template slot-scope="scope">
                    <p v-if="abnormalType == 5">{{ scope.row.status == 1 ? scope.row.updateTime : '未扶正' }}</p>
                    <p v-else>{{ scope.row.updateTime }}</p>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button size="mini" @click="handleCheckGuiji(scope.row)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <el-col :span="24" class="toolbar pagination" >
                <el-pagination
                  layout="total, prev, pager, next, jumper"
                  @current-change="handleCurrentChange"
                  :page-size="10"
                  :total="total"
                  :current-page="currentPage"
                  style="float:right;"
                ></el-pagination>
              </el-col>
            </div>
            <div id="guiji"></div>
          </div>
        </el-dialog>
      </div>
      <!-- 未处理异常列表弹框 -->
      <div class="overSpeed">
        <el-dialog
          title="未处理异常列表"
          :visible.sync="notSolveDialog"
          width="70%"
        >
          <div class="flexBox">
            <div class="tableBox">
              <el-table
               :data="notSolve.data"
               height="500px"
               border
               style="width:100%"
               fit
              >
              <el-table-column align="center" label="设备编号" prop="deviceId">
              </el-table-column>
              <el-table-column align="center" label="异常类型" prop="abnormalType">
                <template slot-scope="scope">
                  <span v-if="scope.row.abnormalType == 1">电瓶离车</span>
                  <span v-if="scope.row.abnormalType == 2">电瓶过温</span>
                  <span v-if="scope.row.abnormalType == 3">温感线故障</span>
                  <span v-if="scope.row.abnormalType == 4">车辆超速</span>
                  <span v-if="scope.row.abnormalType == 5">车辆摔倒</span>
                  <span v-if="scope.row.abnormalType == 6">掉线</span>
                  <!-- <span v-if="scope.row.abnormalType == 7">WiFi设备被拆</span>
                  <span v-if="scope.row.abnormalType == 8">WiFi设备过温</span> -->
                  <span v-if="scope.row.abnormalType == 9">4G设备被拆</span>
                  <span v-if="scope.row.abnormalType == 10">4G设备过温</span>
                  <span v-if="scope.row.abnormalType == 11">地锁设备故障</span>
                  <span v-if="scope.row.abnormalType == 12">地锁异常解锁</span>
                  <span v-if="scope.row.abnormalType == 13">充电桩故障</span>
                  <span v-if="scope.row.abnormalType == 14">设备脱落</span>
                  <span v-if="scope.row.abnormalType == 15">设备电源断开</span>
                  <span v-if="scope.row.abnormalType == 16">设备被拆</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="所属小区" prop="unitName">
                <template slot-scope="scope">
                  <span> {{ scope.row.unitName ? scope.row.unitName : "小区外" }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="具体区域" prop="remarks">
                <template slot-scope="scope">
                  <span> {{ scope.row.remarks ? scope.row.remarks : "--" }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="温度" prop="firstTemp">
                <template slot-scope="scope">
                  <p>{{ scope.row.firstTemp ? scope.row.firstTemp+'℃' : "--" }}</p>
                  <p>{{ scope.row.secondTemp ? scope.row.secondTemp+'℃' : "--" }}</p>
                </template>
              </el-table-column>
              <el-table-column align="center" label="创建时间" prop="createTimeStr">
              </el-table-column>
              </el-table>
              <!-- 分页 -->
              <pagination v-bind:child-msg="notSolveSearchInfo" @callFather="callFather"></pagination>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <i class="el-icon-full-screen" @click="fullScreen"></i>
  </div>
</template>
<script>
import screen from './map.vue';
import 'echarts/extension/bmap/bmap';
import {getStatistics,getAbnormal,getAbnormalDetail,getAbnormalList,checkSpeedDetail,getUntreatedList,getHighBatteryUntreated} from '@/api/public.js';
import pagination from '@/components/Pagination.vue';
import { timeChange } from '@/api/publicFun.js';
export default {
  name: 'HomeView',
  data(){
    return {
      role:'manager',
      userInfo:null,
      isFullScreen:false,
      dialogVisible:false, // 弹框是否显示
      dialogInfo:[],
      neighbourhoodId:'', // 地图当前点击小区的id，默认为0
      NeighbourhoodName:'',// 地图当前点击小区的名称，默认为空
      countMonitoring:{ // 安装车辆
        number:[0],
        content: '{nt}',
        style:{
          fontSize:35,
          fill: '#fff'
        }
      },
      countowner:{ // 登记车主
        number:[0],
        content: '{nt}',
        style:{
          fontSize:35,
          fill: '#fff'
        }
      },
      countNeighbour:{ // 入驻小区
        number:[0],
        content: '{nt}',
        style:{
          fontSize:35,
          fill: '#fff'
        }
      },
      countCommunity:{ // 入驻社区
        number:[0],
        content: '{nt}',
        style:{
          fontSize:35,
          fill: '#fff'
        }
      },
      params:{
        loginName:'admin',
        password:'123456',
      },
      abnormalDate:null, // 异常日期
      mapData:[], // 地图数据
      parklist:[], // 停车区数据
      arr:[],
      carInOutData:{  // 车辆进出统计数据
          x:[],
          y:[],
      },
      abnormalDetail:{ // 异常详情
          countnum:0,
          turebfb:0,
          rowNum:0,
          data:{
            header:['日期','所属小区','设备号','异常类型'],
            align:['center'],
            headerBGC:'#0f2054', // 表头背景色
            evenRowBGC:'#0c1244', // 偶数行背景色
            oddRowBGC:'#0c1244',// 奇数行背景色
            hoverPause:true,
            data:[],
          },
      },
      countData:{ // 统计数据
        countnewCommunity:0, // 月内新增入驻社区
        countnewNe:0, // 
        countCommunity:0, // 入驻社区
        countNe:0,
        countowner:0,
        countMonitoring:0,
        newNeList:[],
      },
      timer:null, // 定时更新数据
      timer2:null,
      role:0, // 当前登陆账号角色，用于权限区分
      isEnlarge:false, // 是否放大异常详情
      notSolve:{  // 各项未处理异常
        data:[],
        type:0,
      },
      abnormalInfo:{}, // 异常统计数据
      overSpeedList:[], // 车辆超速列表
      othersInfo:{ // 其他异常
        statistics:0,
        Dailyaddition:0,
        untreated:0,
      },
      showOverSpeed:false, // 车辆超速谈弹框是否显示
      // 超速列表分页
      pageNo:1,
      pageSize:10,
      currentPage:1,
      total:0,
      overSpeedMap:null, // 超速车辆轨迹地图
      abnormalType:4,
      notSolveDialog:false, // 未处理异常弹框是否显示
      notSolveSearchInfo:{
        pageNo:1,
        pageSize:10,
        total:0,
        param:{
          deviceType:null,
        },
      }
    }
  },
  components: {
    screen,
    pagination,
  },
  methods:{
    // 全屏
    fullScreen(){
      let all = document.getElementById("home");
      if(!this.isFullScreen){
        if (all.requestFullscreen) {
          all.requestFullscreen();
        } else if (all.mozRequestFullScreen) {
          all.mozRequestFullScreen();
        } else if (all.webkitRequestFullscreen) {
          all.webkitRequestFullscreen();
        } else if (all.msRequestFullscreen) {
          all.msRequestFullscreen();
        }
        
      }else{
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
      }
      this.isFullScreen = !this.isFullScreen;
    },
    // 打开弹框
    openDialog(){
      this.dialogVisible = true;
    },
    // 从某个小区数据返回所有数据
    backAll(){
      // location.reload();
      this.neighbourhoodId = '';
      this.notSolve = 'backAll';
      this.init();
    },
    // 获取统计数据、月内新增数据
    async getGeneralSurvey(){
      let data = {
        param:{
          unitId:this.neighbourhoodId,
          merchantId:this.userInfo.merchantId,
        }
      }
      await getStatistics(data).then(res=>{
        if(res.success){
          this.countData = res.data;
          this.countowner.number[0] = res.data.registeredOwner; // 登记车主
          this.countowner={...this.countowner};
          this.countMonitoring.number[0] = res.data.installVehicle; // 安装车辆
          this.countMonitoring={...this.countMonitoring};
          this.countCommunity.number[0] = res.data.settledCommunity; // 入驻社区
          this.countCommunity={...this.countCommunity};
          this.countNeighbour.number[0] = res.data.settledUnit; // 入驻小区
          this.countNeighbour={...this.countNeighbour};
        }
      })
    },
    // 获取各异常数量
    getTemperatureControl(item){
      let data = {
        param:{
          unitId:this.neighbourhoodId,
          merchantId:this.userInfo.merchantId,
        }
      }
      getAbnormal(data).then(res=>{
        if(res.success){
          this.abnormalInfo = res.data;
        }
      })
    },
    // 获取车辆超速列表数据
    async getOverSpeedList(){
      this.abnormalType = 4;
      let data = {
          pageNo:this.pageNo,
          pageSize:10,
          param:{
            abnormalType: 4,
            deviceType: 1,
            unitId:this.neighbourhoodId,
          }
      }
      await getAbnormalList(data).then(res=>{
        if(res.success){
          this.overSpeedList = res.data.list;
          this.total = res.data.total;
          this.overSpeedList.forEach(element=>{
              element.createTime = timeChange(element.createTime,'seconds');
              element.updateTime = timeChange(element.updateTime,'seconds');
          })
          this.showOverSpeed = true;
        }else{
          this.overSpeedList = [];
          this.total = 0;
        }
      })
    },
    // 获取车辆摔倒列表数据
    async getFallOfList(){
      this.abnormalType = 5;
      let data = {
          pageNo:this.pageNo,
          pageSize:10,
          param:{
            abnormalType: 5,
            deviceType: 1,
            unitId:this.neighbourhoodId,
          }
      }
      await getAbnormalList(data).then(res=>{
        if(res.success){
          console.log(res.data.total,999)
          this.overSpeedList = res.data.list;
          this.total = res.data.total;
          this.overSpeedList.forEach(element=>{
              element.createTime = timeChange(element.createTime,'seconds');
              element.updateTime = timeChange(element.updateTime,'seconds');
          })
          this.showOverSpeed = true;
          console.log(this.total,888)
        }else{
          this.overSpeedList = [];
          this.total = 0;
        }
      })
    },
    // 打开未处理异常弹框
    openAbnormalDialog(item){
      this.notSolveDialog = true;
      this.notSolveSearchInfo.param.deviceType = item;
      this.callFather({currentPage:1});
    },
    // 查看未处理异常情况
    checkNotSolve(item){
      if(item == 4 || item == 5){
        this.abnormalType = item;
        this.showOverSpeed = true;
        this.handleCurrentChange(1);
        this.$nextTick(()=>{
          this.overSpeedMap = new BMapGL.Map("guiji");   // 创建Map实例
          this.overSpeedMap.enableScrollWheelZoom(true);  //开启鼠标滚轮缩放
          this.overSpeedMap.centerAndZoom(new BMapGL.Point(120.207461,30.260319), 12.5); // 地图展示中心和缩放级别
          // 根据多个点连线成轨迹
        })
      }else if(item == 3){
        this.notSolveSearchInfo.param = {
          unitId:this.neighbourhoodId,
        }
        getUntreatedList(this.notSolveSearchInfo).then(res=>{
          if(res.success){
            this.notSolveSearchInfo.total = res.data.total;
            this.notSolve = {
              data:res.data.list,
              type:item
            };
            this.notSolve.data.forEach(element=>{
              element.createTimeStr = timeChange(element.createTime,'seconds');
            })
          }
        })
        this.notSolveDialog = true;
      }else if(item == 2) {
        this.notSolveSearchInfo.param = {
          unitId:this.neighbourhoodId,
          deviceType:1,
        }
        getHighBatteryUntreated(this.notSolveSearchInfo).then(res=>{
          if(res.success){
            this.notSolveSearchInfo.total = res.data.total;
            this.notSolve = {
              data:res.data.list,
              type:item
            };
            this.notSolve.data.forEach(element=>{
              element.createTimeStr = timeChange(element.createTime,'seconds');
            })
          }
        })
        this.notSolveDialog = true;
      }else{
        this.notSolveSearchInfo.param = {
          abnormalType: item,
          deviceType: 1,
          deviceId:null,
          status:0,
          unitId:this.neighbourhoodId,
        }
        getAbnormalList(this.notSolveSearchInfo).then(res=>{
          if(res.success){
            this.notSolveSearchInfo.total = res.data.total;
            this.notSolve = {
              data:res.data.list,
              type:item
            };
            this.notSolve.data.forEach(element=>{
              element.createTimeStr = timeChange(element.createTime,'seconds');
            })
          }
        })
        this.notSolveDialog = true;
      }
    },
    // 分页插件事件
    callFather(parm) {
      this.notSolveSearchInfo.pageNo = parm.currentPage;
      if(this.notSolveSearchInfo.param.deviceType == 1){
        this.checkNotSolve(1);
      }else if(this.notSolveSearchInfo.param.deviceType == 2){
        this.checkNotSolve(2);
      }else{
        this.checkNotSolve(3);
      }
    },
    // 点击查看超速轨迹及其详情
    async handleCheckGuiji(row){
      this.overSpeedMap.clearOverlays();
      if(this.abnormalType == 4){
          await checkSpeedDetail({param:row.id}).then(res=>{
          this.detailInfo = res.data ? res.data : [];
          this.open=true;
          this.$nextTick(()=>{
            this.overSpeedMap.clearOverlays();
              if(this.detailInfo[0].latitude){
                let allGps = [];
                let speeds = [];
                this.detailInfo.forEach((element,index)=>{
                    allGps.push(new BMapGL.Point(element.longitude,element.latitude));
                    var eachPoint = new BMapGL.Point(element.longitude,element.latitude);
                    var marker = new BMapGL.Marker(eachPoint);
                    speeds.push({lng:element.longitude,lat:element.latitude})
                    this.overSpeedMap.addOverlay(marker);
                    let eachTime = timeChange(element.createTime,'seconds');
                    element.createTime = eachTime;
                    if(index === 0 ){
                      var content =`<div style='width:100%;'><div style='width:90%;margin:0 auto;'>` + `<div><span>时间：</span>` +element.createTime;
                      this.brokenInfo(marker,content);
                    }else{
                      var content =`<div style='width:100%;'><div style='width:90%;margin:0 auto;'>` + `<div><span>时间：</span>` +element.createTime + `</div><div><span>车速：</span>` + element.speed+'km/h';
                      this.brokenInfo(marker,content);
                    }
                })
                // 计算所有标点在一个比较舒适的初始缩放范围
                let view2 = this.overSpeedMap.getViewport(eval(speeds));
                this.overSpeedMap.centerAndZoom(view2.center, view2.zoom);
                var polyline = new BMapGL.Polyline(allGps, {strokeColor:"blue", strokeWeight:7, strokeOpacity:0.7,geodesic:true}); // 连点成线
                this.overSpeedMap.addOverlay(polyline);
              }else{ // 没有gps
                this.overSpeedMap.centerAndZoom(new BMapGL.Point(120.207461,30.260319), 15.5);
                this.$message({
                  message:'未获取到GPS',
                  type:'error'
                })
              }
          })
        })
      }else{
        if(row.latitude){
          var eachPoint = new BMapGL.Point(row.longitude,row.latitude);
          var marker = new BMapGL.Marker(eachPoint);
          this.overSpeedMap.addOverlay(marker);
          this.overSpeedMap.centerAndZoom(eachPoint, 15.5);
        }else{
          this.overSpeedMap.centerAndZoom(new BMapGL.Point(120.207461,30.260319), 15.5);
          this.$message({
            message:'未获取到GPS',
            type:'error'
          })
        }
      }
    },
    // 点击未处理异常标记点显示标点详细信息
    brokenInfo(marker,content) {
     var infoWindow = new BMapGL.InfoWindow(content, this.opts);
        marker.onclick=function(){
            marker.openInfoWindow(infoWindow);
        }
    },
    // 超速列表切换页码
    handleCurrentChange(val){
      this.pageNo = val;
      this.currentPage = val;
      this.abnormalType == 4 ? this.getOverSpeedList() : this.getFallOfList();
    },
    // 放大异常详情
    enlarge(isTrue){
      this.isEnlarge=!isTrue;
    },
    // 选择日期
    chooseDate(e){
      this.abnormalDate = e;
      this.init();
    },
    // 获取图表数据
    init(){
        clearInterval(this.timer2);
        this.getabnormal();
        this.getGeneralSurvey();
        this.getTemperatureControl(1);
    },
    // 异常详情
    async getabnormal(){
      let data = {
        param:{
          queryDate:this.abnormalDate,
          unitId:this.neighbourhoodId,
        }
      }
      await getAbnormalDetail(data).then(res=>{
          if(res.success){
              this.abnormalDetail=res.data;
              let arr=[];
              if(!res.data.abnormalDetails || res.data.abnormalDetails.length == 0){
                arr.push(['暂无异常']);
              }else{
                res.data.abnormalDetails.forEach(element=>{
                  element.createTime = timeChange(element.createTime,'seconds')
                  if(element.abnormalType == 1){
                    element.abnormalType = '电瓶离车';
                  }else if(element.abnormalType == 2){
                    element.abnormalType = '电瓶过温';
                  }
                  element.unitName = element.unitName ? element.unitName : '--';
                  element.deviceId = element.deviceId ? element.deviceId : '--';
                  let each = [`<p>${element.createTime}</p>`, `<p style="display:inline-block">${element.unitName}</p>`, `<p style="display:inline-block">${element.deviceId}</p>`,`<p style="display:inline-block;">${element.abnormalType}</p>`];
                  arr.push(each);
                })
              }
              this.abnormalDetail.data={
                header:['日期','所属小区','设备号','异常详情'],
                align:['center','center','center',],
                columnWidth:[105],
                rowNum:4,
                headerBGC:'#4554dd', // 表头背景色
                evenRowBGC:'#0c1244', // 偶数行背景色
                oddRowBGC:'#0c1244',// 奇数行背景色
                hoverPause:true,
                data:arr,
                waitTime:5000
              }
          }else{
              this.abnormalDetail.data=[];
          }
      })
    },
    // 点击地图标点
    clickNeighbour(id,name){
      this.neighbourhoodId = id;
      this.NeighbourhoodName = name;
      this.notSolve = {data:[]};
      this.init();
    },
  },
  async mounted(){
    this.userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    this.init();
    this.timer = setInterval(()=>{
      this.init();
    },300000)
  },
  beforeDestroy(){
    clearInterval();
    if(this.timer){
        clearInterval(this.timer);
        clearInterval(this.timer2);
    }
  }
}
</script>
<style scoped lang='scss'>
.staticsBox{
  display:flex;
  justify-content: space-around;
  align-items: center;
  .staticsTitle{
    color:#7de7ff;
    width: 100%;
    text-align: left !important;
    height: 0.156rem;
    line-height: 0.156rem;
  }
}
// 一级标题
.firstTitle{
  display:flex;
  align-items: center;
  justify-content: center;
  height: 6%;
}
.flexBox{
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  #guiji{
    width: 50%;
    min-height:600px;
  }
  .tableBox{
    flex: 1;
    height:600px;
    display:flex;
    width: 50%;
    flex-direction: column;
    justify-content: space-between;
  }
}
@mixin wordAnimation($preColor:#fff,$nextColor:#055454,$times:'0.5s',$animationName:'namepp'){
  @keyframes  #{$animationName} {
    0%{
      //color:$preColor;
      opacity:1 ;
    }
    60%{
      //color:$preColor;
      opacity:1 ;
    }
    100%{
     // color:$nextColor;
     opacity:0.3 ;
    }
  }
  animation: #{$animationName} infinite $times;
}
// 弹框
.remind{
  color: #fff !important;
  font-weight: 700;
  height: 60px;
  width: 60px;
  border-radius: 50% !important;
  background-color: red !important;
  text-align: center;
  line-height: 60px;
  position: absolute !important;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 10000;
  font-size: 20px;
  @include wordAnimation(#ff8851,gray,0.5s,'remind');
}
.dialog{
  position: absolute !important;
  top: 0;
  right: 0;
  background: #fff;
  padding: 0.1rem;
  border-radius: 5px;
  color: #000;
  z-index: 10000;
  .content{
    max-height: 1.5rem;
    width: 1.5rem;
    overflow: auto;
  }
  .dialogTitle{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    span{ 
      font-size:19px;
      font-weight: 600;
    }
    .el-icon-close{
      cursor: pointer;
    }
  }
}
/deep/ .el-dialog{
  // position: absolute !important;
  // top: 10px;
  // right: 10px;
  // margin-top: 0 !important;
}
// 左侧内容区
.leftContent{
  width:30%;height: 100%;display: flex;justify-content: space-between;flex-wrap: wrap;
  .statisticData{ // 统计数据
    width:100%;height: 33%;display: flex;justify-content: space-between;align-items: center;
  }
  .abnormalType{ // 几种异常类型
    height:7%;
    width:100%;
    padding:20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    span{
      margin-right: 10px;
      font-weight:600;
    }
  }
  .abnormalDetail{ // 异常详情
    height:calc(32% - 0.13rem);width:100%;padding:15px;box-sizing: border-box;
  }
}
// 地图区
.mapContent{
  width:68%;height: 100%;
}
// 异常详情放大版
.abnormalDetailBig{
  height:100%;width:68%;padding:15px;box-sizing: border-box;
}
// 小区名称标题栏
.neighborName{
  color:#fff;
  text-align: center;
  width:100%;
  height: 0.13rem;
}
.el-icon-zoom-in,.el-icon-zoom-out{
  cursor: pointer;
}
/deep/ polygon{
  fill:rgba($color:#82daff,$alpha:1) !important;
  stroke: #2691d7 !important;
}
/deep/ polyline{
  stroke: #2691d7 !important;
}
/deep/ .dv-decoration-11 .decoration-content{
color:#000 !important;
padding: 0.05rem;
}
/deep/ input,.el-picker-panel{
  background: #0f2054!important;
  color: #51bdd9!important;
  height: 100% !important;
  border: 0;
  display: flex;
  align-items: center;
}
/deep/ .el-input__icon{
  line-height: 100% !important;
}
/deep/ [data-v-09354c11] .el-date-editor.el-input, .el-date-editor.el-input__inner[data-v-09354c11]{
  width: auto !important;
}
/deep/ .el-input--prefix .el-input__inner{
  padding-right: 0 !important;
}
/deep/.el-date-editor.el-input, .el-date-editor.el-input__inner{
  width: 135px !important;
}
 /deep/ .anchorBL{ //去除百度地图logo
  display: none !important;
  z-index: 0 !important;
}
div{
  box-sizing: border-box;
}
body,html{
  height: 100%;
  width: 100%;
}
h4{
  margin-bottom: 5px;
  button{
    background-color: #4554dd;
    color: #fff;
    border-radius: 3px;
    border: 0;
    padding:0 0.05rem;
    margin-left: 0.1rem;
    cursor: pointer;
  }
}
.eachOne{
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 25%;
      height: 100%;
      box-sizing: border-box;
      .eachStatics{
        border:solid #5ac2f9 2px;
        border-radius:50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        box-sizing: border-box;
      }
      b{
          color: #eb7038;
          font-size: 0.17rem;
          span{
            margin-left: 5px;
          }
      }
      span{
          color: #fff;
          font-size: 0.073rem;
          margin-bottom: 5px;
      }
  }
.abnormal{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .each{
      width: 50%;
      display: flex;
      height: 0.21rem;
      align-items: center;
      justify-content: center;
      p{
          display: flex;
          flex-direction: column;
          align-items: center;
          b{
              color: #eb7038;
              font-size: 0.1rem;
          }
          span{
              color: #8eebf3;
              font-size: 0.073rem;
          }
      }
      img{
          height: 0.21rem;
          width: 0.21rem;
      }
  }
}
.home{
  font-size: 0.084rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: #0c1244;
  position: relative;
  .el-icon-full-screen{
    position: absolute;
    bottom: 0.1rem;
    right: 0.1rem;
    color: #fff;
    z-index: 10000;
    cursor: pointer;
    font-size: 20px;
  }
  .box{
    height: 93%;
    padding: 5px;
    position: relative;
    margin: 0 auto;
    color: #fff;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    #map{
      height: 100%;
    }
    .show-word-animation-leave-car{
      color:#ff8851;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 0.1rem;
      .twinkle{
        @include wordAnimation(#ff8851,gray,0.5s,'overTemp');
        cursor: pointer;
      }
      b{
        &:nth-last-child(1){
          cursor: pointer;
        }
      }
    }
    .show-word-animation-over-temp{
      width: 100%;
      color:red;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 0.1rem;
      .twinkle{
        @include wordAnimation(red,gray,0.5s,'overTemp');
        cursor: pointer;
      }
      b{
        &:nth-last-child(1){
          cursor: pointer;
        }
      }
    }
    .show-word-animation-over-speed{
      width: 100%;
      color:rgb(255, 230, 0);
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 0.1rem;
      .twinkle{
        @include wordAnimation(rgb(255, 230, 0),gray,0.5s,'overTemp');
        cursor: pointer;
      }
      b{
        &:nth-last-child(1){
          cursor: pointer;
        }
      }
    }
    .show-word-animation-others{
      color:#fff;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 0.1rem;
      .twinkle{
        @include wordAnimation(#fff,gray,0.5s,'overTemp');
        cursor: pointer;
      }
      b{
        &:nth-last-child(1){
          cursor: pointer;
        }
      }
      // @include wordAnimation(#fff,gray,1.2s,'others');
    }
  }
  .dv-border-box-11{
    color: #fff;
      ::v-deep .border-box-content{
      position: none;
      box-sizing: border-box;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      
      h5{
        width: 100%;
      }
      ::v-deep .active-ring-chart-container{
        height: 100%;
        width: 100%;
        ::v-deep canvas{
         height: 100%;
         width: 100%;
        }
      }
    }
  }
}
::v-deep .dv-scroll-board .rows .row-item{
  white-space: normal;
  word-break: break-all;
  height: auto !important;
  align-items: center;
}

::v-deep .dv-scroll-board .rows .ceil{
  white-space: normal !important;
  text-overflow: inherit;
  overflow: visible;
  height: auto !important;
  line-height: auto !important;
}
/deep/ .dv-border-box-12 .border-box-content{
  display: flex;
  align-items: center;
  justify-content: center;
}    
</style>
