<template>
  <section class="outContainer">
    <div class="search">
      <!-- <el-select class='each' placeholder="开关类型" v-model="searchInfo.param.type" clearable>
        <el-option
          v-for="item in typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select> -->
      <el-cascader class="each" placeholder="所属商户 / 所属小区"
        clearable
        v-model="unitId"
        :options="managerUnitOptions"
        :props="props"
        @change="chooseMerchant"
        v-if="userInfo.roleLevel < 5"
      ></el-cascader>
      <el-select class='each' placeholder="所属小区" v-model="searchInfo.param.unitId" clearable v-if="userInfo.roleLevel >= 5 && userInfo.roleLevel < 10">
        <el-option
          v-for="item in unitOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
      <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
    </div>
    <div class="tableContainer">
      <el-table
         :data="tableList"
         height="100%">
          <el-table-column align="center" label="开关编号" prop="id"></el-table-column>
          <el-table-column align="center" label="开关名称" prop="name"></el-table-column>
          <!-- <el-table-column align="center" label="开关类型" prop="typeName">
            <template slot-scope="props">
              {{ props.row.type == 1 ? 'WiFi开关' : ( props.row.type == 2 ? '4G开关' : '--' )}}
            </template>
          </el-table-column> -->
          <el-table-column align="center" label="安装小区" prop="unitName">
            <template slot-scope="props">
              {{ props.row.unitName ? props.row.unitName : '--' }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="在线状态" prop="online">
            <template slot-scope="props">
              {{ props.row.online == 1 ? '在线' : ( props.row.online == 2 ? '离线' : '--' )}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="开启状态" prop="status">
            <template slot-scope="props">
              <el-switch
                :disabled="props.row.online == 2"
                :value="props.row.status == 1 ? true : false"
                active-color="#13ce66"
                @change="changeStatus(props.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" label="版本" prop="version">
            <template slot-scope="props">
              <p>硬件版本：{{ props.row.hardWareVersion ? props.row.hardWareVersion : '--' }}</p>
              <p>软件版本：{{ props.row.version ? props.row.version : '--' }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="创建时间" prop="createTimeStr">
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right" width="200">
            <template slot-scope="props">
              <p style="margin-bottom: 5px;">
                <el-button size="mini" @click="checkCommunicate(props.row)">通讯记录</el-button>
                <el-button @click="delSwitch(props.row)" size="mini">删除</el-button>
              </p>
            </template>
          </el-table-column>
        </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { lockSwitch,getSwitchList,getNeighbourhoodList,getMerchantList,delWifiSwitch } from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
export default {
  data() {
    return {
      searchInfo:{
        pageNo:1,
        pageSize:10,
        total:0,
        param:{
          type:2,
          merchantId:null,
          unitId:null,
        },
      },
      tableList:[], // 表格数据
      typeOptions:[
        {value:2,label:'4G开关'},
        {value:1,label:'WiFi开关'},
      ],
      userInfo:JSON.parse(localStorage.getItem('userInfo')), // 当前登录账号用户信息
      managerUnitOptions:[], // 所属商户、所属小区选项
      props: { // 所属商户、小区 联动选择框配置
        value:'id',
        label:'name',
        lazy: true,
        checkStrictly:true,
        lazyLoad (node, resolve) {
          const { level } = node;
          console.log(node,987)
          setTimeout(() => {
            let data = {
              pageNo:1,
              pageSize:1000,
              param:{
                name:null,
                merchantId:node.value,
              }
            }
            if(level == 1){
              getNeighbourhoodList(data).then(res=>{
              // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                let nodes = [];
                res.data.list.forEach(element=>{
                    nodes.push({
                      id: element.id,
                      name: element.name,
                      leaf: level >= 1,
                      children:null,
                    })
                })
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                resolve(nodes);
              })
              console.log(node,908)
            }
          }, 1000);
        }
      },
      unitId:"", // 小区id
      unitOptions:[], // 安装小区选项
    }
  },

  components: {
    Pagination,
  },

  computed: {},

  mounted() {
    this.init();
    this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : this.getNieghbourList();
  },

  methods: {
    // 条件筛选事件
    handleSearch(type){
      if(type == 'clear'){
        this.searchInfo.param = {
          type:2,
          merchantId:null,
          unitId:null,
        };
        this.unitId = "";
      }
      this.searchInfo.pageNo = 1;
      this.init();
    },
    async init(){
      await getSwitchList(this.searchInfo).then((res)=>{
        if(res.success){
          this.tableList = [...res.data.list];
          this.searchInfo.total = res.data.total;
          this.tableList.forEach(element => {
            element.createTimeStr = element.createTime ? timeChange(element.createTime,'seconds') : '--';
          });
        }else{
          this.tableList = [];
          this.searchInfo.total = 0;
        }
      });
    },
    // 获取小区列表
    getNieghbourList(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:null,
        }
      }
      getNeighbourhoodList(data).then(res=>{
        if(res.success){
          this.unitOptions = res.data.list;
        }
      })
    },
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.managerUnitOptions = res.data.list;
          this.managerUnitOptions.forEach(element=>{
            element.children = [];
          })
        }else{
          this.managerUnitOptions = [];
        }
      })
    },
    // 选择商户、小区
    chooseMerchant(e){
      this.searchInfo.param.merchantId = e[0] ? e[0] : null;
      this.searchInfo.param.unitId = e[1] ? e[1] : null;
    },
    // 查看4G开关通讯记录
    checkCommunicate(row){
      if(row.type == 2){
        this.$router.push({path:'/inductorManage/switchCommuication',query:{id:row.id}});
      }else{
        this.$router.push({path:'/device/wifiCommunication',query:{id:row.id}});
      }
    },
    // 打开、关闭开关
    changeStatus(e){
      this.$confirm('确认进行该操作吗？').then(async () => {
        const loading = this.$loading({
          lock: true,
          text: '操作中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let data = {
          param:{
            switchLock:e.status == 1 ? 0 : 1,
            wifiId:e.id,
          }
        }
        await lockSwitch(data).then(res=>{
          if(res.success && res.data.result){
            this.$message({
              message:'操作成功',
              type:'success'
            })
          }else{
            this.$message({
              message:'操作失败',
              type:'fail'
            })
          }
          this.init();
          loading.close();
        })
      })
    },
    // 删除开关
    delSwitch(row){
      this.$confirm('确认删除吗？').then(async () => {
        await delWifiSwitch({ param:row.id }).then(res=>{
          if(res.success){
            this.$message({
              message:'操作成功',
              type:'success'
            })
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
          this.init();
        })
      })
    },
    // 分页操作事件
    callFather(parm){
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped></style>
