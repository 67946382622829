<template>
  <div class="outContainer">
    <div class="back">
      <i class="el-icon-back" @click="back"></i>
      <span>{{this.$route.query.id}}</span>
    </div>
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-select class='select each' v-model="searchInfo.param.type" clearable placeholder="通讯类型" @change="changeType">
        <el-option
          v-for="item in communicationOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="tableContainer">
      <el-table
      :data="tableList"
      height="100%"
      style="width: 100%;">
        <af-table-column align="center" label="指令" prop="cmd">
        </af-table-column>
        <af-table-column align="center" label="RFID" prop="rfid">
          <template slot-scope="scope">
            <p>{{ scope.row.rfid ? scope.row.rfid : '--' }}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="头盔识别" prop="picUpload" width="200" v-if="cameraStatus == 1">
          <template slot-scope="scope">
            <!-- 更改头盔识别状态指令 -->
            <p  v-if="scope.row.cmd == '8001' || scope.row.cmd == '8002' || scope.row.cmd == '1001'">
              状态更改：{{ scope.row.picUpload == 'on' ? '开启' : (scope.row.picUpload == 'off' ? '关闭' : '--') }}
            </p>
            <!-- RFID感应 -->
            <div v-if="scope.row.cmd == '2001'">
              <p v-if="!scope.row.scores && scope.row.errorType == 0">未开启头盔识别</p>
              <!-- 头盔置信度数组 -->
              <div v-if="scope.row.helmetData.length > 0 && scope.row.scores">
                <p v-for="(item,index) in scope.row.helmetData" :key="index">{{ item.label }} {{ item.value }}%</p>
              </div>
            </div>
            <div v-if="scope.row.cmd == '2001' && scope.row.errorType">
              {{ scope.row.errorTypeStr }}
            </div>
            <!-- 其他指令 -->
            <p v-if="scope.row.cmd != '8001' && scope.row.cmd != '2001' && scope.row.cmd != '8002' && scope.row.cmd != '1001' ">--</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="版本" prop="version">
          <template slot-scope="scope">
            <p>固件版本：{{ scope.row.version ? scope.row.version : '--' }}</p>
            <p>软件版本：{{ scope.row.firmVersion ? scope.row.firmVersion : '--' }}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="时间间隔（秒）" prop="heartbeatInterval">
          <template slot-scope="scope">
            <p>心跳：{{ scope.row.heartbeatInterval ? Number(scope.row.heartbeatInterval) / 1000 : '--' }}</p>
            <p>读标签：{{ scope.row.readInterval ? Number(scope.row.readInterval) / 1000 : '--' }}</p>
            <p>上报：{{ scope.row.sendInterval ? Number(scope.row.sendInterval) / 1000 : '--' }}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="超时时间（秒）" prop="heartbeatInterval">
          <template slot-scope="scope">
            <p>{{ scope.row.timeout ? ( scope.row.timeout == 60000 ? '无网不开道闸' : Number(scope.row.timeout) / 1000 ) : '--' }}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="感应范围（米）" prop="power">
          <template slot-scope="scope">
            <span v-if="scope.row.power == 16">0.8</span>
            <span v-if="scope.row.power == 17">1</span>
            <span v-if="scope.row.power == 18">1.5</span>
            <span v-if="scope.row.power == 19">2</span>
            <span v-if="scope.row.power == 20">2.5</span>
            <span v-if="scope.row.power == 21">3</span>
            <span v-if="scope.row.power == 22">4</span>
            <span v-if="scope.row.power == 23">4.5</span>
            <span v-if="scope.row.power == 24">5</span>
            <span v-if="scope.row.power == 25">6</span>
            <span v-if="scope.row.power == 26">7</span>
            <span v-if="scope.row.power == 27">9</span>
            <span v-if="scope.row.power == 28">10</span>
            <span v-if="scope.row.power == 29">12</span>
            <span v-if="scope.row.power == 30">13</span>
            <span v-if="scope.row.power == 31">14</span>
            <span v-if="scope.row.power == 32">15</span>
            <span v-if="scope.row.power == 33">16</span>
            <span v-if="!scope.row.power">--</span>
          </template>
        </af-table-column>
        <af-table-column align="center" label="是否成功" prop="isSuccess">
          <template slot-scope="scope">
            <p>{{ scope.row.isSuccess == 0 ? '成功' : (scope.row.isSuccess == 1 ? '失败' : '--')}}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="创建时间" prop="createDatetime"></af-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <el-col :span="24" class="toolbar pagination">
      <el-pagination
        background
        layout=" prev , next , jumper"
        @prev-click="prevpage"
        @next-click="nextpage"
        @current-change="handleCurrentChange"
        :page-size="10"
        style="float:right;"
      ></el-pagination>
    </el-col>
  </div>
</template>

<script>
import {getBrakeCommunication} from '@/api/public.js';
import {timeChange} from '@/api/publicFun.js';
import Pagination from '@/components/Pagination.vue';
export default {
  data() {
    return {
      searchInfo:{
        pageNo:1,
        pageSize:10,
        param:{
          deviceId:'',
          type:null,
        }
      },
      tableList:[],
      communicationOptions:[
        {value:1,label:'心跳'},
        {value:2,label:'RFID感应'},
        {value:3,label:'主动设置感应间隔'},
        {value:4,label:'主动设置功率'},
        {value:5,label:'主动重启'},
        {value:6,label:'主动开关道闸'},
        {value:7,label:'主动设置道闸无网策略'},
        {value:8,label:'主动设置头盔识别状态'},
      ],
      carInfo:{},
      cameraStatus:null,
    }
  },

  components: {Pagination},

  computed: {},

  mounted() {
    this.searchInfo.param.deviceId = this.$route.query.id;
    this.cameraStatus = this.$route.query.cameraStatus;
    this.init();
  },

  methods: {
    back(){
      this.$router.go(-1)
    },
    changeType(e){
      this.searchInfo.pageNo = 1;
      this.searchInfo.param.type = e && e != "" ? e : null;
      this.init();
    },
    async init(){
      await getBrakeCommunication(this.searchInfo).then(res=>{
        if(res.success){
          if( !res.data.list || res.data.list.length == 0){
            this.tableList = [];
            this.$message({
              message:'没有更多通讯记录了',
              type:'remind'
            })
          }else{
            this.tableList = res.data.list;
            this.tableList.forEach(element => {
              element.createDatetime = timeChange(element.createDatetime,'seconds');
              // 转换头盔置信度数组
              element.scores = element.scores ? JSON.parse(element.scores) : element.scores;
              element.labels = element.labels ? JSON.parse(element.labels) : element.labels;
              element.helmetData = [];
              if(element.scores && element.labels){
                element.scores.forEach((ele,ind)=>{
                  if( element.labels[ind] && element.labels[ind].indexOf('no') == -1){ // 如果该项不包含‘no’，则对应的数据是 佩戴头盔置信度
                    element.helmetData.push({label:'佩戴头盔置信度：',value:(Number(ele)*100).toFixed(2)});
                  }else{ // 如果该项包含‘no’，则对应的数据是 未佩戴头盔置信度
                    element.helmetData.push({label:'未佩戴头盔置信度：',value:(Number(ele)*100).toFixed(2)});
                  }
                  ele = {...ele};
                })
              }
            });
          }
        }else{
          this.tableList = [];
          this.$message({
            message:res.msg,
            type:'fail',
          })
        }
      })
    },
    // 上一页
    prevpage(val){
      this.searchInfo.pageNo = val;
      this.init();
    },
    // 下一页
    nextpage(val){
      this.searchInfo.pageNo = val;
      this.init();
    },
    handleCurrentChange(val){
      this.searchInfo.pageNo = val;
      this.init();
    }
  },
};
</script>
<style scoped>

</style>
