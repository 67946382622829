<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
      <el-form-item label="区域名称" prop="name" >
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="区域类型" prop="type" required>
        <el-select v-model="form.type" placeholder="请选择"  style="width:100%">
           <el-option
             v-for="item in typeOptions"
             :key="item.value"
             :label="item.label"
             :value="item.value"
            >
           </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="区域范围" prop="ranges" required>
        <button class="bMap" type="button" @click="chooseRange">地图划定范围</button>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input placeholder="请输入备注" type="textArea" v-model="form.remark">
        </el-input>
      </el-form-item>
      <el-form-item style="justify-content: center !important;">
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="edit('form')" :plain='true'>{{!row.IsEdit?'立即添加':'立即修改'}}</el-button>
      </el-form-item>
    </el-form>
    <el-dialog title="地址选择" :visible.sync="open" width="900px" append-to-body>
        <el-form label-width="100px">
          <el-row>
            <el-col :span="10">
              <el-form-item label="搜索地址">
                <el-input type="text" id="searchAddress" v-model="searchAddress" placeholder="请输入地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="当前地址">
                <el-input v-model="addressInfo.address" placeholder="请输入内容">
                  <template slot="prepend">
                    {{addressInfo.province}}{{addressInfo.city}}{{addressInfo.district}}
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 百度地图 -->
        <div id="MapContainer" style="width: 100%; height: 400px;"></div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirm">确定</el-button>
          <el-button @click="cancel">重置</el-button>
        </div>
    </el-dialog>
  </section>
</template>

<script>
import {addUnitArea} from '@/api/public.js';
export default {
  props:['row'],
  data() {
    return {
      form:{ // 表单数据
        unitId:'',
        ranges:'',
        remark:'',
        type:'',
        name:'',
      },
      typeOptions:[
        {label:'停放区',value:1},
        // {label:'临时停放区',value:2},
        {label:'禁行区',value:3},
        // {label:'通道感应',value:4},
      ],
      MonitorEquipOptions:[],
      // 搜索地址
      searchAddress: "",
      // 地址信息
      addressInfo: {
        longitude: "",// 经度
        latitude: "",// 纬度
        province: "",// 省
        city: "",// 市
        district: "",// 区
        address: "",// 详细地址
      },
      open: false,
      rules: { // 表单验证
        ranges: [
          { required: true, message: '请选择停车区域范围', trigger: 'change', }
        ],
        name: [
          { required: true, message: '区域名称不得为空', trigger: 'change', }
        ],
        type: [
          { required: true, message: '请选择停车区类型', trigger: 'change', }
        ],
      },
      neighbourInfo:null,
      userGps:["120.17906278816268","30.32004063996321"],
      Polygon:[], // 多边形gps
      map:null,
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.neighbourInfo = this.row;
    this.form.unitId = this.row.id;
    // this.getLocation();
  },

  methods: {
    async getMonitorEquip(){
      await selectParkingEquipmentBandInfo({}).then(res=>{
        if(res.Success){
          this.MonitorEquipOptions = res.Data;
        }
      })
    },
    // 关闭修改弹窗
    close(){
       this.$emit('close');
    },
    // 获取用户当前位置
    getLocation(){
      var geolocation = new BMapGL.Geolocation();
      geolocation.getCurrentPosition((r)=>{
        this.userGps[0]=r.longitude;
        this.userGps[1]=r.latitude;
      },{enableHighAccuracy: false,maximumAge:1000})
    },
    // 打开地图选址
    chooseRange(){
      this.open=true;
      this.initBaiduMap();
    },
    // 初始化百度地图
    initBaiduMap() {
      let that = this;
      this.$nextTick(() => {
        /* 初始化地图 start */
        this.map = new BMapGL.Map("MapContainer") // 创建地图实例
        var point = new BMapGL.Point(this.row.longitude,this.row.latitude); // 设置中心点坐标
        this.map.centerAndZoom(point, 18); // 地图初始化，同时设置地图展示级别
        this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
        /* 初始化地图 end */
        this.map.addEventListener("click", (e) => {
          this.$nextTick(()=>{
            var click = e.latlng; // 点击的坐标
            this.Polygon.push(click);
            if(this.Polygon.length<=1){
              this.map.clearOverlays();
              var Point = new BMapGL.Point(click.lng,click.lat);
              var Marker = new BMapGL.Marker(Point); // 创建标注
              this.map.addOverlay(Marker); // 将标注添加到地图中
              that.geocAddress(Point);
            }else if(this.Polygon.length == 2){
              let start=this.Polygon[this.Polygon.length-2];
              let end=this.Polygon[this.Polygon.length-1];
	            var polyline = new BMapGL.Polyline([
	            	new BMapGL.Point(start.lng, start.lat),
	            	new BMapGL.Point(end.lng, end.lat),
	            ], {strokeColor:"blue", strokeWeight:2, strokeOpacity:0.5});   //创建折线
              this.map.addOverlay(polyline)
            }else{
              this.map.clearOverlays()
              let arr=[];
              this.Polygon.forEach(element => {
                arr.push(new BMapGL.Point(element.lng,element.lat));
              });
              var polygon = new BMapGL.Polygon(arr, {strokeColor:"blue", strokeWeight:2, strokeOpacity:0.5});  //创建多边形
              this.map.addOverlay(polygon);  //增加多边形
            }
          })
        })
        /** 点击地图创建坐标事件End */
        /** 搜索地址Start */
        // 建立一个自动完成的对象
        var ac = new BMapGL.Autocomplete({
            input: "searchAddress",
            location: this.map,
          });
        // 鼠标点击下拉列表后的事件
        ac.addEventListener("onconfirm", (e) => {
          this.map.clearOverlays();
          var local = new BMapGL.LocalSearch(this.map, {
            // 智能搜索
            onSearchComplete: (res) => {
              let poi = res.getPoi(0); // 获取第一个智能搜索的结果
              var searchpt = poi.point; // 获取坐标
              this.map.centerAndZoom(searchpt, 16);
              this.map.addOverlay(new BMapGL.Marker(searchpt));
              that.geocAddress(searchpt);
            }
          });
          // 搜索词
          var searchValue = e.item.value;
          local.search(
            searchValue.province +
            searchValue.city +
            searchValue.district +
            searchValue.street +
            searchValue.business
          )
        });
        /** 搜索地址End */
      })
    },
    // 逆向解析地址
    geocAddress(point) {
        let that = this;
        var geoc = new BMapGL.Geocoder();
        geoc.getLocation(point,  (geocInfo) => {
          // 设置基本信息
          var addressInfo = geocInfo.addressComponents;
          that.addressInfo.longitude = point.lng;
          that.addressInfo.latitude = point.lat;
          that.addressInfo.province = addressInfo.province;
          that.addressInfo.city = addressInfo.city;
          that.addressInfo.district = addressInfo.district;
          let address = addressInfo.street + addressInfo.streetNumber;
          if (geocInfo.surroundingPois.length > 0) {
            address = address + geocInfo.surroundingPois[0].title;
          }
          that.addressInfo.address = address;
        });
    },
    /** 确认选择 */
    confirm() {
      for(let i=0;i<this.Polygon.length;i++){
        this.Polygon[i] = [this.Polygon[i].lng,this.Polygon[i].lat];
      }
      this.form.ranges = JSON.stringify(this.Polygon);
      this.open=false;
    },
    // 取消选择
    cancel() {
      this.map.clearOverlays();
      this.Polygon=[];
    },
    // 修改
    edit(form) {
       this.$refs["form"].validate(valid => {
          if (valid) {
              this.$confirm('确认新增吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(()=>{
                addUnitArea({param:this.form})
                  .then((res) => {
                    if (res.success) {
                      this.$message({
                        message: "新增成功",
                        type: "success",
                      });
                      setTimeout(() => {
                        this.$emit("success");
                        this.init();
                      }, 1000);
                    }else{
                      this.$message({
                        message: "新增失败，请重试",
                        type: "fail",
                      });
                    }
                  })
                  .catch(error => {});
              })
          }else{
            return false;
          }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.bMap{
    border: 1px solid #dcdfe6;
    background: #fff;
    border-radius: 4px;
    width: 100px;
    height: 35px;
   }
 .el-drawer{
   overflow:auto !important;
 }
 .el-form-item{
  display: flex;
  align-items: center;
 }
 /deep/ .el-form-item__content{
  display: flex;
  margin-left: 0 !important;
  width: 100%;
  justify-content: center;
 }
 .map{
  border: 1px solid #dcdfe6;
  background: #fff;
  border-radius: 4px;
  width: 80px;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
