import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=18fd7fd6&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&id=18fd7fd6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18fd7fd6",
  null
  
)

export default component.exports