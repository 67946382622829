<template>
    <div class="outContainer">
      <div class="back">
        <i class="el-icon-back" @click="back"></i>
        <span>{{stationName}}预约检测记录</span>
      </div>
      <!-- 筛选条件栏 -->
      <div class="search">
          <el-input class="each" v-model="searchInfo.param.plateNumber" placeholder="车牌号">
          </el-input>
          <el-select class='each' v-model="searchInfo.param.status" clearable placeholder="是否检测">
            <el-option
              v-for="item in IstestOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!-- 预约时间选择 -->
          <el-date-picker class='each' v-model="time"
           type="daterange"
           :default-time="['00:00:00', '23:59:59']"
           range-separator="至"
           start-placeholder="预约时间"
           end-placeholder="预约时间"
           @change='chooseAppointTime'
          >
          </el-date-picker>
          <el-button size="mini" type="primary" icon="el-icon-search"  @click="handleSearch('search')">搜索</el-button>
          <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
          <el-button size="mini" icon="el-icon-download" @click="educe">导出</el-button>
      </div>
      <!-- 表格 -->
      <div class="tableContainer">
        <el-table
        :data="tableList"
        height="100%"
        style="width: 100%;">
          <el-table-column align="center" label="车辆信息" prop="id">
            <template slot-scope="scope">
              <p v-if="scope.row.ebikeUserTypeVO">{{ scope.row.ebikeUserTypeVO.plateNumber ? scope.row.ebikeUserTypeVO.plateNumber : '--' }}</p>
              <p v-if="scope.row.ebikeUserTypeVO">{{ scope.row.ebikeUserTypeVO.brand ? scope.row.ebikeUserTypeVO.brand : '--' }}</p>
              <p v-if="!scope.row.ebikeUserTypeVO"> -- </p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="车主信息" prop="name">
            <template slot-scope="scope">
              <p v-if="scope.row.ebikeUserTypeVO">{{ scope.row.ebikeUserTypeVO.userName ? scope.row.ebikeUserTypeVO.userName : '--' }}</p>
              <p v-if="scope.row.ebikeUserTypeVO">{{ scope.row.ebikeUserTypeVO.userPhone ? scope.row.ebikeUserTypeVO.userPhone : '--' }}</p>
              <p v-if="!scope.row.ebikeUserTypeVO"> -- </p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="预约时间" prop="appointmentTime">
            <template slot-scope="scope">
              <p>{{ scope.row.appointmentTime }}  {{  scope.row.appointmentPeriodStr  }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否检测" prop="statusName">
          </el-table-column>
          <el-table-column align="center" label="创建时间" prop="createTime">
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right" width="250">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleAdd(scope.row)" v-if="scope.row.status == 3 || scope.row.status == 4">补充数据</el-button>
              <el-button size="mini" @click="handleDetail(scope.row)" v-if="scope.row.status == 4">详情</el-button>
              <el-button size="mini" @click="handlePrint(scope.row)" v-if="scope.row.status == 4">打印</el-button>
              <p v-if="scope.row.status != 3 && scope.row.status != 4">--</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <pagination v-bind:child-msg="searchInfo" @callFather="callFather"></pagination>
      <!-- 详情 -->
      <el-drawer
        title="详情"
        :visible.sync="detailDrawer"
        :before-close="handleClose"
        size="80%">
        <detail v-if="detailDrawer" @close='handleClose' ref='newForm' :row="row"></detail>
      </el-drawer>
    </div>
  </template>
  
  <script>
  import detail from './detail.vue';
  import Pagination from "@/components/Pagination.vue";
  import { getDetectionAppointmentList,getStationDetail,exportDetectionList } from '@/api/public.js';
  import { timeChange } from '@/api/publicFun.js';
  const XLSX = require("xlsx-js-style");
  export default {
    data() {
      return {
        searchInfo: { // 筛选条件
          pageSize:10,
          pageNo:1,
          total:1,
          param:{
            appointmentEndTime: "",
            appointmentStartTime: "",
            status: "",
            plateNumber: "",
            siteId:"",
          }
        },
        tableList: [],// 表格数据
        IstestOptions: [ // 是否检测
          {value:3,label:'未检测'},
          {value:4,label:'已检测'},
          {value:5,label:'已过期'},
          {value:6,label:'已取消'},
        ],
        detailDrawer: false, // 控制详情弹框是否显示
        row: null,
        time:"",
        stationName:"",
        batteryBrands:[],
      }
    },
  
    components: { detail,Pagination },
  
    computed: {},
  
    mounted() {
      this.searchInfo.param.siteId = this.$route.query.id;
      this.init();
      this.getDetail();
    },
    methods: {
      // 返回
      back(){
        this.$router.go(-1)
      },
      // 关闭弹框
      handleClose(){
        this.detailDrawer = false;
        this.init();
      },
      // 查询详情
      getDetail(){
        getStationDetail({param:this.$route.query.id}).then(res=>{
          if(res.success){
            this.stationName = res.data.name
          }
        })
      },
      // 条件筛选事件
      handleSearch(type){
        if(type == 'clear'){
          this.searchInfo.param = {
            siteId:this.$route.query.id
          };
          this.time = "";
        }
        this.searchInfo.pageNo = 1;
        this.init();
      },
      // 获取表格数据
      async init(){
        this.searchInfo.param.siteId = this.$route.query.id;
        await getDetectionAppointmentList(this.searchInfo).then(res=>{
          if(res.success && res.data){
            this.tableList =  res.data.list;
            this.searchInfo.total = res.data.total;
            this.tableList.forEach(element => {
              let eachAppointTime = timeChange(element.appointmentTime);
              element.appointmentTime = `${eachAppointTime.year}-${eachAppointTime.month}-${eachAppointTime.day}`;
              let eachCreateTime = timeChange(element.createTime);
              element.createTime = `${eachCreateTime.year}-${eachCreateTime.month}-${eachCreateTime.day} ${eachCreateTime.hour}:${eachCreateTime.minutes}:${eachCreateTime.seconds}`;
            });
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
            this.tableList = [];
            this.searchInfo.total = 0;
          }
        })
      },
      // 选择预约时间范围
      chooseAppointTime(e){
        let startTime = timeChange(e[0]);
        this.searchInfo.param.appointmentStartTime = `${startTime.year}-${startTime.month}-${startTime.day}`;
        let endTime = timeChange(e[1]);
        this.searchInfo.param.appointmentEndTime = `${endTime.year}-${endTime.month}-${endTime.day}`;
      },
      // 导出
      async educe(){
        const loading = this.$loading({
            lock: true,
            text: '导出中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        await exportDetectionList({param:this.searchInfo.param.siteId}).then(res=>{
          if( res.success && res.data && res.data.length > 0){
            let dataList = res.data;
            const data = [ // 表头
              ['车辆信息','车主信息','流水号','整车编码','蓄电池出厂日期','蓄电池类型','蓄电池品牌','蓄电池电压','蓄电池容量','电动机编码','是否有合格证信息码','铭牌信息是否完整','车牌有无脱落隐患','车体宽度、鞍座长度是否合格','前后轮是否出现变形','是否安装脚蹬','是否速度篡改','是否有车速提示音','最高车速（km/h）','前轴制动（10N）','后轴制动（10N）','前轴重（KG）','后轴重（KG）','整车质量（KG）','短路保护','线路是否改装、老化、脱落、裸露','电池连接线布线是否合理','是否安装指定类型蓄电池','是否有充鼓、漏液、变形','蓄电池安装情况是否防篡改','电池电压(V)','充电器与电池是否匹配','充电器的风扇是否正常工作','充电器电压(V)','充电器电流(A)','一号电池(mΩ)','二号电池(mΩ)','三号电池(mΩ)','四号电池(mΩ)','五号电池(mΩ)','六号电池(mΩ)']
            ];
            dataList.forEach(element=>{
              let each = []; // 数据
              each.push(`${element.licencePlate}-${element.brand}`); // 车辆信息
              each.push(`${element.carMaster}-${element.carMasterPhone}`); // 车主信息
              let arr = ['detectionNum','vehicleNum','batteryFactoryTimeStr','batteryTypeStr','batteryBrandStr','batteryVoltageStandard','batteryCapacity','motorCode','certificateCodeStr','nameplateInfoIsFullStr','licencePlatIsFallOffStr','widthLengthIsStandardStr','wheelIsAmorphosisStr','pedalIsInstallStr','speedIsChangeStr','speedPromptToneStr','topSpeed','frontAxleBrake','rearAxleBrake','frontAxleWeight','rearAxleWeight','vehicleMass','shortCircuitProtectStr','lineProblemStr','wiringIsRationalStr','installRightBatteryStr','batteryProblemStr','batteryTamperProofStr','batteryVoltage','chargerMatcheBatteryStr','fanIsWorkStr','chargerVoltage','chargerElectricity','batteryOne','batteryTwo','batteryThree','batteryFour','batteryFive','batterySix'];
              arr.forEach(ele=>{
                each.push(element[ele] ? element[ele] : '--');
              })
              data.push(each);
            })
            // excel导出
            const sheet = XLSX.utils.aoa_to_sheet(data);
            const book = XLSX.utils.book_new();
              // 设置列宽为自适应
              sheet['!cols'] = [{wch:22},{wch:22},{wch:20},{wch:22},{wch:22},{wch:20},{wch:20},{wch:20},{wch:20},{wch:25},{wch:22},{wch:22},{wch:22},{wch:26},{wch:22},{wch:22},{wch:22},{wch:22},{wch:22},{wch:22},{wch:22},{wch:22},{wch:22},{wch:22},{wch:22},{wch:40},{wch:28},{wch:28},{wch:28},{wch:28},{wch:22},{wch:24},{wch:28},{wch:22},{wch:22},{wch:22},{wch:22},{wch:22},{wch:22},{wch:22},{wch:22},];
              sheet['!rows'] = []; // 行高
              // 设置单元格样式
              for (let row = 0; row < data.length; row++) {
                for (let col = 0; col < data[row].length; col++) {
                  const cellRef = XLSX.utils.encode_cell({ c: col, r: row });
                  console.log('单元格',cellRef)
                  sheet[cellRef].s = {
                    alignment: {
                      horizontal: 'center', // 水平居中
                      vertical: 'center',   // 垂直居中
                    },
                    font: {
                      bold: true // 加粗
                    }
                  };
                  sheet['!rows'].push({ hpx: 28 }) // 行高
                }
              }
            XLSX.utils.book_append_sheet(book,sheet,"sheet1"); // 生成sheet
            XLSX.writeFile(book,`检测数据.xlsx`); // 导出
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
          loading.close();
        })
      },
      // 获取表格字段列宽
      getCellWidth(value) {
        // 判断是否为null或undefined
        if (value == null) {
          return 10;
        } else if (/.*[\u4e00-\u9fa5]+.*$/.test(value)) {
          // 中文的长度
          const chineseLength = value.match(/[\u4e00-\u9fa5]/g).length;
          // 其他不是中文的长度
          const otherLength = value.length - chineseLength;
          return chineseLength * 2.1 + otherLength * 1.1;
        } else {
          return value.toString().length * 1.1;
        }
      },
      // 前往补充数据
      handleAdd(row){
        this.$router.push({path:'/serviceStation/detectionRecords/edit',query:{appointId:row.id,status:row.status,detectionId:row.detectionId}})
      },
      // 查看详情
      handleDetail(row){
        this.row = row;
        this.detailDrawer = true;
      },
      // 打印
      handlePrint(row){
        this.row = row;
        this.$router.push({path:'/serviceStation/detectionRecords/print',query:{detectionId:row.detectionId}})
      },
      // 分页插件事件
      callFather(pram){
        this.searchInfo.pageNo = pram.currentPage;
        this.init();
      },
    },
  };
  </script>
  <style scoped lang="scss">

  </style>
  
