<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
        <el-form-item label="设备编号" prop="id">
          <el-input placeholder="请输入设备编号" v-model="form.id" :disabled="row.btnType == 'edit' ? true : false"></el-input>
        </el-form-item>
        <el-form-item label="开关名称" prop="name">
          <el-input placeholder="请输入开关名称" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="handleSubmit('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  import { addWifiSwitch,editWifiSwitch } from "@/api/public.js";
  export default {
    props:['row'], // 父组件携带参数
    data() {
      return {
        form:{ // 表单数据
          id: "",
          name: "",
          routerMacId:'',
          type:2
        },
        rules: { // 表单验证
          id:[
            { required: true, message: '设备编号不得为空', trigger: 'blur', }
          ],
          name:[
            { required:true,message:'开关名称不得为空',trigger:"blur"}
          ],
        },
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      if(this.row.btnType == 'add'){
        this.form.parentId = this.row.id;
      }else{
        this.form = {...this.row};
      }
    },
  
    methods: {
      // 关闭弹窗
      close(form){
         this.$emit('close');
      },
      // 新增
      async add(){
        await addWifiSwitch({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
            }, 1000);
          }else{
            this.$message({
              message: "提交失败，请重试",
              type: "fail",
            });
          }
        })
      },
      // 编辑
      async edit(){
        await editWifiSwitch({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
              this.init();
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
      },
      // 提交
      handleSubmit(form) {
         this.$refs["form"].validate(valid => {
            if (valid) {
                this.$confirm('确认提交吗？', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(()=>{
                  if(this.row.btnType == 'edit'){
                    this.edit();
                  }else{
                    this.add();
                  }
                })
            }else{
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss" scoped>
  // 防止地图自动完成的对象被遮挡
  .tangram-suggestion {
      z-index: 9999 !important;
    }
   .el-drawer{
     overflow:auto !important;
   }
   /deep/ .el-form-item__content{
    display: flex;
   }
   .map{
    border: 1px solid #dcdfe6;
    background: #fff;
    border-radius: 4px;
    width: 80px;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  