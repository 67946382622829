<template>
  <section class="outContainer">
    <div class="search">
        <el-input class="each"  placeholder="设备编号" v-model="searchInfo.param.id"></el-input>
        <el-select class='each' placeholder="设备类型" v-model="searchInfo.param.deviceType" clearable>
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select class='each' placeholder="是否绑定车辆" v-model="searchInfo.param.installBike" clearable>
          <el-option
            v-for="item in installOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
    </div>
    <div class="tableContainer">
        <el-table
         :data="tableData"
         height="100%">
          <el-table-column align="center" label="设备编号" prop="id">
          </el-table-column>
          <el-table-column align="center" label="设备类型" prop="deviceType">
              <template slot-scope="scope">
                <!-- <p>{{ scope.row.deviceType == 1 ? 'Pro-4G' : (scope.row.deviceType == 2 ? 'Air-WiFi' : (scope.row.deviceType == 3 ? 'Air-4G' : (scope.row.deviceType == 4 ? 'Power-4G' : '--'))) }}</p> -->
                <p>{{ scope.row.deviceType == 1 ? 'Pro-4G' : (scope.row.deviceType == 3 ? 'Air-4G' : (scope.row.deviceType == 4 ? 'Power-4G' : '--')) }}</p>
              </template>
          </el-table-column>
          <el-table-column align="center" label="创建时间" prop="createTimeStr">
          </el-table-column>
          <el-table-column align="center" label="操作" prop="deviceType" fixed="right" width="250">
              <template slot-scope="scope">
                <el-button size="mini" @click="conmmunicate(scope.row)">通讯记录</el-button>
              </template>
          </el-table-column>
        </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
    <!-- 新增、编辑 -->
    <el-dialog title="设备绑定" :visible.sync="open" width="60%" :close-on-click-modal="false" append-to-body>
        <el-form label-width="180px" :rules="rules" :model="deviceInfo" ref="deviceInfo">
          <el-form-item label="设备编号" prop="id">
            <el-input v-model="deviceInfo.id" placeholder="请输入设备编号" :disabled="row ? true : false"></el-input>
          </el-form-item>
          <el-form-item label="RFID" prop="rfid">
            <el-input v-model="deviceInfo.rfid" placeholder="请输入RFID"></el-input>
          </el-form-item>
          <el-form-item label="设备类型" prop="deviceType">
            <el-radio-group v-model="deviceInfo.deviceType">
                <el-radio :label="1">Pro-4G</el-radio>
                <!-- <el-radio :label="2">Air-WiFi</el-radio> -->
                <el-radio :label="3">Air-4G</el-radio>
                <el-radio :label="4">power-4G</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="open = false">关闭</el-button>
            <el-button @click="confirm('deviceInfo')">提交</el-button>
        </div>
    </el-dialog>
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { timeChange } from '@/api/publicFun.js';
import { addCarDevice,updateCarDevice,delCarDevice,getCarDeviceList } from '@/api/public.js';
export default {
  data() {
    return {
        tableData:[], // 表格数据列表
        searchInfo:{ // 筛选条件
            pageNo:1,
            pageSize:10,
            total:0,
            param:{
              installBike:null, // 是否绑定车辆
            },
        },
        row:null, // 当前操作数据
        open:false,
        deviceInfo:{ // 新增绑定信息
            id:'',
            rfid:'',
            deviceType:'',
        },
        typeOptions:[ // 设备类型选项
          {value:1,label:'Pro-4G'},
          // {value:2,label:'Air-WiFi'},
          {value:3,label:'Air-4G'},
          {value:4,label:'Power-4G'},
        ],
        installOptions:[ // 是否绑定车辆选项
          {value:true,label:'是'},
          {value:false,label:'否'},
        ],
        rules:{ // 新增、编辑设备表单验证
          id:[
            { required: true, message: '设备编号不得为空', trigger: 'blur',}
          ],
          rfid:[
            { required:true,message:'RFID不得为空',trigger:"blur"}
          ],
          deviceType:[
            { required:true,message:'设备类型不得为空',trigger:"blur"}
          ],
        }
    }
  },

  components: {
    Pagination,
  },

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 获取表格数据
    init(){
      getCarDeviceList(this.searchInfo).then(res=>{
        if(res.success){
          this.tableData = res.data.list;
          this.searchInfo.total = res.data.total;
          this.tableData.forEach(element => {
            element.createTimeStr = element.createTime ? timeChange(element.createTime,'seconds') : '--';
          });
        }else{
          this.tableData = [];
          this.searchInfo.total = 0;
        }
      })
    },
    // 搜索按钮事件
    handleSearch(type){
      this.searchInfo.pageNo = 1;
      if(type == 'clear'){
        this.searchInfo.param = {}
      }
      this.init();
    },
    // 打开新增、编辑弹框
    openDrawer(row){
        this.open = true;
        this.row = row;
        if(row){
          this.deviceInfo = {...row};
        }else{
          this.deviceInfo = {
            id:'',
            rfid:'',
            deviceType:'',
          }
        }
    },
    // 删除
    handleDel(row){
      this.$confirm('确认删除吗？').then(()=>{
        delCarDevice({param:row.id}).then(res=>{
          this.$message({
            message:res.success ? '删除成功' : res.msg,
            type:res.success ? 'success' : 'fail'
          })
          this.init();
        })
      })
    },
    // 确认提交，绑定设备
    confirm(){
      this.$refs["deviceInfo"].validate(valid => {
        if(valid){
          if(this.row){
            updateCarDevice({param:this.deviceInfo}).then(res=>{
              this.$message({
                message:res.success ? '提交成功' : res.msg,
                type:res.success ? 'success' : 'fail'
              })
            })
            this.open = false;
          }else{
            addCarDevice({param:this.deviceInfo}).then(res=>{
              this.$message({
                message:res.success ? '提交成功' : res.msg,
                type:res.success ? 'success' : 'fail'
              })
            })
          }
          this.deviceInfo.id = '';
          this.deviceInfo.rfid = '';
          this.init();
        }
      })
    },
    // 通讯记录
    conmmunicate(row){
      this.$router.push({path:'/device/carDevice/communication',query:{deviceId:row.id,deviceType:row.deviceType}});
    },
    // 分页操作事件
    callFather(parm){
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped></style>
