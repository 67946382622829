<template>
    <div>
        <el-form ref="form" label-position="right" status-icon :model="bikeInfo"  label-width="170px" style="width:95%;overflow:auto">
            <el-form-item label="车辆信息：" prop="name" >
                <p>{{ bikeInfo.plateNumber }} - {{ bikeInfo.brand }}</p>
            </el-form-item>
            <el-form-item label="车辆类型：" prop="standardType" >
                <p>{{ bikeInfo.standardType == 1 ? '临时车' : (bikeInfo.standardType == 1 ? '绿标车' : (bikeInfo.standardType == 2 ? '黄标车' : '--')) }}</p>
            </el-form-item>
            <el-form-item label="车主信息：" prop="name" >
                <p v-for=" (item,index) in bikeInfo.userVOList" :key="index">{{ item.name }} - {{ item.phone }}({{ item.userTypeStr }})</p>
            </el-form-item>
            <el-form-item label="登记小区：" prop="name" >
                <p v-for=" (item,index) in bikeInfo.unitVOList" :key="index">{{ item.name }} - {{ item.ebikeDoorplate }}</p>
            </el-form-item>
            <el-form-item label="黑匣子：" prop="name" >
                <p>{{ bikeInfo.deviceId ? bikeInfo.deviceId : '未安装' }}</p>
            </el-form-item>
            <el-form-item label="RFID：" prop="name" >
                <p>{{ bikeInfo.rfid ? bikeInfo.rfid : '未安装' }}</p>
            </el-form-item>
            <el-form-item label="软件版本：" prop="name">
                <p>{{ bikeInfo.deviceId  ? (bikeInfo.bikeDevice.version ? bikeInfo.bikeDevice.version : '--') : '未安装' }}</p>
            </el-form-item>
            <el-form-item label="硬件版本：" prop="name">
                <p>{{ bikeInfo.deviceId  ? (bikeInfo.bikeDevice.hardWareVersion ? bikeInfo.bikeDevice.hardWareVersion : '--') : '未安装' }}</p>
            </el-form-item>
            <el-form-item label="设备在线状态：" prop="name">
                <p>{{ bikeInfo.deviceId ? (bikeInfo.bikeDevice.status == 1 ? '在线' : '离线') : '未安装' }}</p>
            </el-form-item>
            <el-form-item label="电瓶是否离车：" prop="isAccumulatorLeave">
                <p>{{ bikeInfo.deviceId ? (bikeInfo.bikeDevice.isAccumulatorLeave == 0 ? '正常' : '离车') : '未安装' }}</p>
            </el-form-item>
            <el-form-item label="是否摔倒：" prop="isFall">
                <p>{{ bikeInfo.deviceId ? (bikeInfo.bikeDevice.isFall == 0 ? '正常' : '摔倒') : '未安装' }}</p>
            </el-form-item>
            <el-form-item label="通断电状态：" prop="isPowerOutage">
                <p>{{ bikeInfo.deviceId ? (bikeInfo.bikeDevice.isPowerOutage == 0 ? '通电' : '断电') : '未安装' }}</p>
            </el-form-item>
            <el-form-item label="温度：" prop="firstTemperature" >
                <p>{{ bikeInfo.deviceId ? `${bikeInfo.bikeDevice.firstTemperature}℃,${bikeInfo.bikeDevice.secondTemperature}℃` : '未安装' }}</p>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { getEBikeById } from '@/api/public.js';
export default {
    props:['row'],
    data() {
        return {
            bikeInfo:{},
        }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
        this.init();
    },
  
    methods: {
        colse(){
            this.$emit('handleClose')
        },
        async init(){
            await getEBikeById({param:this.row.id}).then(res=>{
                if(res.success){
                    this.bikeInfo = res.data;
                }else{
                    this.$message({
                        message:res.msg,
                        type:'fail'
                    })
                }
            })
        },
    },
};
</script>
<style scoped lang="scss">
// p{
//     line-height: 30px;
//     font-size: 16px;
// }
</style>
