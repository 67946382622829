<template>
  <div class="outContainer">
    <div class="back">
      <i class="el-icon-back" @click="back"></i>
    </div>
    <!-- 筛选条件栏 -->
    <div class="search">
        <el-select class='each select' v-model="IsQualified" clearable placeholder="是否合格">
          <el-option
            v-for="item in IsQualifiedOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button size="mini" type="primary" icon="el-icon-search">搜索</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close">清空搜索条件</el-button>
    </div>
    <div class="tableContainer">
      <el-table
      :data="tableList"
      height="100%"
      style="width: 100%;">
        <el-table-column align="center" label="车牌号" prop="id">
        </el-table-column>
        <el-table-column align="center" label="车主信息" prop="name">
          <template slot-scope="scope">
            <p>{{ scope.row.name }}</p>
            <p>{{ scope.row.phone }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="设备编号" prop="desc">
        </el-table-column>
        <el-table-column align="center" label="检测结果" prop="isOnline">
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="edit(scope.row)">补充数据</el-button>
            <el-button size="mini">详情</el-button>
            <el-button size="mini">打印</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <el-col :span="24" class="toolbar pagination">
      <el-pagination
        background
        layout=" prev , next"
        @prev-click="prevpage"
        @next-click="nextpage"
        :page-size="10"
        style="float:right;"
      ></el-pagination>
    </el-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchInfo:{
        pageSize:10,
        pageIndex:1,
        Data:{
          LicencePlate:'',
          EquipmentNum:'',
        }
      },
      tableList:[
        {
          name:"lmy",
          phone:'18019220807',
          shop:1,
          id:"杭州536035",
          shopId:1,
          category:1,
          address:1,
          desc:"EO8075658878BGF557339H",
          isOnline:1,
          rfid:'8iyb588765699h',
          createTime:'2023-09-08 15:30',
          equipVersion:'V2.1.31',
          version:'V1.1.0',
          neighbourhoodList:['光阳国际','近江八园'],
          emergencyList:['13567064322','15569752365'],
          type:"绿标车",
          battery:"正常",
          temperature:"0级",
          fallOver:"正常",
        }
      ],
      IsAddDataOptions:[ // 是否补充数据选项
        {value:0,label:'未补充'},
        {value:1,label:'已补充'},
      ], 
      IsQualifiedOptions:[ // 是否合格选项
        {value:1,label:'合格'},
        {value:2,label:'不合格'},
      ],
      BatteryCapacityOptions:[
        {value:36,label:'36V'},
        {value:48,label:'48V'},
        {value:60,label:'60V'},
        {value:72,label:'72V'},
      ],
      IsBulgeOptions:[
        {value:2,label:'是'},
        {value:1,label:'否'}
      ],
      IsWiringExposedOptions:[
        {value:2,label:'是'},
        {value:1,label:'否'}
      ],
      FastspeedOptions:[
        {value:2,label:'是'},
        {value:1,label:'否'}
      ],
    }
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    back(){
      // this.$router.push({path:'/device/eBike'})
      this.$router.go(-1)
    },
  },
};
</script>
<style scoped>

</style>
