<template>
  <section class="outContainer">
    <!-- 返回 -->
    <div class="back">
        <i class="el-icon-back" @click="back"></i>
        <span>审核列表</span>
    </div>
    <!-- 搜索栏 -->
    <div class="search">
      <el-input placeholder="申请人姓名" class="each" v-model="searchInfo.param.name"></el-input>
      <el-input placeholder="申请车牌号" class="each" v-model="searchInfo.param.plateNumber"></el-input>
      <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
      <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
    </div>
    <!-- 内容区 -->
    <div class="tableContainer">
      <!-- 数据表格 -->
      <el-table
        :data="tableList"
        style="width: 100%"
        class="table"
        height="100%"
        fit
      >
        <el-table-column align='center' label="申请人信息" prop="name">
          <template slot-scope="scope">
            <p>姓名：{{ scope.row.name ? scope.row.name : '--' }}</p>
            <p>电话：{{ scope.row.phone ? scope.row.phone : '--' }}</p>
          </template>
        </el-table-column>
        <el-table-column align='center' label="车牌号" prop="plateNumber">
        </el-table-column>
        <el-table-column align='center' label="申请说明" prop="applyExplain">
        </el-table-column>
        <el-table-column align='center' label="审核结果及说明" prop="auditResult">
          <template slot-scope="scope">
            <p>结果：{{ scope.row.auditResult == 0 ? '通过' : (scope.row.auditResult == 2 ? '待审核' : '未通过') }}</p>
            <p>说明：{{ scope.row.auditResultExplain ? scope.row.auditResultExplain : '--' }}</p>
          </template>
        </el-table-column>
        <el-table-column align='center' label="申请时间" prop="createTimeStr">
        </el-table-column>
        <el-table-column align='center' label="审核时间" prop="auditTimeStr">
          <template slot-scope="scope">
            <p v-if="scope.row.auditResult != 2">{{ scope.row.auditTimeStr}}</p>
            <p v-else>待审核</p>
          </template>
        </el-table-column>
        <el-table-column align='center' label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleExamine(scope.row)" v-if="scope.row.auditResult == 2">审核</el-button>
            <p v-else>--</p>
          </template>
        </el-table-column>
      </el-table>
       <!-- 审核弹框 -->
       <el-dialog title="审核" :visible.sync="auditVisible">
        <el-form :model="auditForm" :rules="auditRule" ref="auditForm">
          <el-form-item label="是否通过" prop="auditResult" label-width="120px">
            <el-radio-group v-model="auditForm.auditResult">
             <el-radio-button :label="0">通过</el-radio-button>
             <el-radio-button :label="1">驳回</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="审核结果说明" prop="auditResultExplain" label-width="120px">
            <el-input v-model="auditForm.auditResultExplain" type="textarea"  placeholder="请输入结果说明" style="width: 100%;"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="auditVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAudit('auditForm')">提交</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 分页 -->
    <pagination v-bind:child-msg="searchInfo" @callFather="callFather"></pagination>
  </section>
</template>

<script>
import { unitApplyList,auditApplyUnit } from '@/api/public.js';
import pagination from '@/components/Pagination.vue';
import {timeChange} from '@/api/publicFun.js';
export default {
  data() {
    return {
      neighborInfo:{}, // 小区信息
      searchInfo:{ // 查询条件
        pageNo:1,
        pageSize:10,
        total:0,
        param:{
          unitId:"",
          name:"",
          plateNumber:"",
        }
      },
      tableList:[],
      auditVisible:false, // 是否显示审核弹框
      auditForm:{ // 审核表单
        alllyId:"",
        auditResult:"",
        auditResultExplain:"",
      },
      auditRule:{ // 审核表单验证规则
        auditResult:[
          { required: true, message: '请选择是否通过审核', trigger: 'blur' },
        ],
        auditResultExplain:[
          { required: true, message: '请输入审核结果说明', trigger: 'blur' },
        ]
      },
      row:null,
    }
  },

  components: {
    pagination
  },

  computed: {},

  mounted() {
    this.searchInfo.param.unitId = this.$route.query.neighborInfoId;
    this.init();
  },

  methods: {
    // 返回上一页handleDel
    back(){
        this.$router.go(-1);
    },
    // 获取表格数据
    async init(){
      await unitApplyList(this.searchInfo).then(res=>{
        if(res.success){
          this.tableList = res.data.list;
          this.tableList.forEach(element => {
            element.createTimeStr = timeChange(element.createTime,'seconds');
            element.auditTimeStr = timeChange(element.auditTime,'seconds');
          });
          this.searchInfo.total = res.data.total;
        }
      })
    },
    // 搜索
    handleSearch(type){
      if(type == 'clear'){
        this.searchInfo.param = {
          name:"",
          plateNumber:"",
          unitId:this.$route.query.neighborInfoId,
        }
      }
      this.searchInfo.pageNo = 1;
      this.init();
    },
    // 打开审核弹框
    handleExamine(row){
      this.auditVisible = true;
      this.row = row;
      this.auditForm.applyId = row.id;
    },
    // 提交审核
    confirmAudit(auditForm){
      this.$refs["auditForm"].validate(valid =>{
        if(valid){
          this.$confirm('确认提交该审核？').then(()=>{
            auditApplyUnit({param:this.auditForm}).then(res=>{
              this.$message({
                message:res.success ? '提交成功' : res.msg,
                type:res.success ? 'success' : 'fail'
              })
              this.auditVisible = false;
              this.row = null;
              this.auditForm = {
                applyId:"",
                auditResult:"",
                auditResultExplain:"",
              };
              this.init();
            })
          })
        }
      })
    },
    // 分页插件事件
    callFather(parm) {
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped></style>
