<!-- 设备管理 / 车辆管理 -->
<template>
  <div class="outContainer">
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-input class="each" placeholder="设备编号" v-model="searchInfo.param.deviceId"></el-input>
      <el-input class="each" placeholder="RFID" v-model="searchInfo.param.rfid"></el-input>
      <el-input class="each" placeholder="车牌号" v-model="searchInfo.param.plateNumber"></el-input>
      <el-input class="each" placeholder="车主名称" v-model="searchInfo.param.name"></el-input>
      <el-input class="each" placeholder="车主电话" v-model="searchInfo.param.phone"></el-input>
      <el-cascader class="each" placeholder="所属商户 / 所属小区"
        clearable
        v-model="unitId"
        :options="managerUnitOptions"
        :props="props"
        @change="chooseMerchant"
        v-if="userInfo.roleLevel < 5"
      ></el-cascader>
      <el-select class='each' placeholder="所属小区" v-model="searchInfo.param.unitId" clearable v-if="userInfo.roleLevel >= 5 && userInfo.roleLevel < 10">
        <el-option
          v-for="item in unitOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-cascader class="each" placeholder="是否安装黑匣子 / 是否在线"
        v-model="isInstallEquip"
        clearable
        :options="equipOptions"
        :props="{ expandTrigger: 'hover', checkStrictly:true }"
        @change="handleChoose"
      ></el-cascader>
      <el-select class='each' placeholder="是否安装RFID" v-model="isRfid" clearable @change="chooseIsRfid">
        <el-option
          v-for="item in rfidOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select class='each' placeholder="是否检测" v-model="searchInfo.param.isDetection" clearable>
        <el-option
          v-for="item in isDetectionOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
      <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
      <el-button size="mini" icon="el-icon-download"  @click="educe">导出</el-button>
    </div>
    <div class="tableContainer">
      <!-- 数据表格 -->
      <el-table
       :data="tableList"
       style="width: 100%;"
       height="100%">
        <el-table-column align="center" label="车辆信息" prop="plateNumber">
          <template slot-scope="scope">
            <p>{{ scope.row.plateNumber }}</p>
            <p>{{ scope.row.brand }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="车主信息" prop="userVOList">
          <template slot-scope="scope" v-if="scope.row.userVOList && scope.row.userVOList != '--'">
            <p v-if=" !scope.row.userVOList || scope.row.userVOList.length == 0">--</p>
            <p v-if="scope.row.userVOList && scope.row.userVOList.length == 1" >
                {{ scope.row.userVOList[0].name }} - {{ scope.row.userVOList[0].phone }}
            </p>
            <el-dropdown trigger="click" v-if="scope.row.userVOList && scope.row.userVOList.length>1">
              <span class="el-dropdown-link">
                {{scope.row.userVOList[0].name}} - {{ scope.row.userVOList[0].phone }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" style="max-height:200px;overflow-y: auto;">
                <el-dropdown-item v-for="(item,index) in scope.row.userVOList" :key="index">
                    {{item.name}} - {{ item.phone }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template v-else>--</template>
        </el-table-column>
        <el-table-column align="center" label="所属小区" prop="unitVOList" min-width="150">
          <template slot-scope="scope"  v-if="scope.row.unitVOList && scope.row.unitVOList != '--'">
            <p v-if=" !scope.row.unitVOList || scope.row.unitVOList.length == 0">--</p>
            <p v-if="scope.row.unitVOList && scope.row.unitVOList.length == 1" >
                {{ scope.row.unitVOList[0].name }}
                <span v-if="scope.row.rfid">({{scope.row.unitVOList[0].enterStateName ? scope.row.unitVOList[0].enterStateName : '--'}})</span>
            </p>
            <div v-if="scope.row.unitVOList && scope.row.unitVOList.length > 1 && scope.row.unitVOList.length < 4">
              <p v-for="(item,index) in scope.row.unitVOList" :key="index">
                  {{ item.name }}
                  <span v-if="scope.row.rfid">({{item.enterStateName ? item.enterStateName : '--'}})</span>
                  <el-button size="mini" @click="releaseBind(item,scope.row)">解绑</el-button>
              </p>
            </div>
            <el-dropdown trigger="click" v-if="scope.row.unitVOList && scope.row.unitVOList.length > 4">
              <span class="el-dropdown-link">
                {{scope.row.unitVOList[0].name}}
                <span v-if="scope.row.rfid">({{scope.row.unitVOList[0].enterStateName ? scope.row.unitVOList[0].enterStateName : '--'}})</span>
                <el-button size="mini" @click="releaseBind(scope.row.unitVOList[0],scope.row)">解绑</el-button>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" style="max-height:200px;overflow-y: auto;">
                <el-dropdown-item v-for="(item,index) in scope.row.unitVOList" :key="index">
                    {{item.name}}
                    <span v-if="scope.row.rfid">({{item.enterStateName ? item.enterStateName : '--'}})</span>
                    <el-button size="mini" @click="releaseBind(item,scope.row)">解绑</el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template v-else>--</template>
        </el-table-column>
        <el-table-column align="center" label="设备信息" prop="desc">
          <template slot-scope="scope">
            <p>
              黑匣子：{{ scope.row.deviceId ? scope.row.deviceId : '--' }}
              <!-- <span v-if="scope.row.bikeDevice">({{ scope.row.bikeDevice.deviceType == 1 ? 'Pro-4G' : (scope.row.bikeDevice.deviceType == 2 ? 'Air-WiFi' : (scope.row.bikeDevice.deviceType == 3 ? 'Air-4G' : 'Power-4G')) }})</span> -->
              <span v-if="scope.row.bikeDevice">({{ scope.row.bikeDevice.deviceType == 1 ? 'Pro-4G' : (scope.row.bikeDevice.deviceType == 3 ? 'Air-4G' : (scope.row.bikeDevice.deviceType == 4 ? 'Power-4G' : '--')) }})</span>
            </p>
            <p>RFID：{{ scope.row.rfid ? scope.row.rfid : '--'  }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="是否检测" prop="isDetection">
          <template slot-scope="scope">
            <p>{{ scope.row.isDetection == 1 ? '已检测' : '未检测' }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="当前版本号" prop="version">
          <template slot-scope="scope">
            {{ scope.row.deviceId  ? ( scope.row.bikeDevice && scope.row.bikeDevice.version ? scope.row.bikeDevice.version : '--') : '未安装'}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="设备在线状态" prop="status">
          <template slot-scope="scope">
            {{ scope.row.deviceId ? ( scope.row.bikeDevice ? ( scope.row.bikeDevice.status == 1 ? '在线' :( scope.row.bikeDevice.status == 2 ? '离线' : '--')) : '--') : '未安装' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="车辆是否断电" prop="status">
          <template slot-scope="scope">
            <p v-if="scope.row.deviceId && scope.row.bikeDevice && scope.row.bikeDevice.type == 1">
              {{ scope.row.bikeDevice.isPowerOutage == 0 ? '通电' : ( scope.row.bikeDevice.isPowerOutage == 1 ? '断电' : '--') }}
            </p>
            <p v-else>--</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime">
        </el-table-column>
        <el-table-column align="center" label="操作"  fixed="right" width="380px">
          <template slot-scope="scope">
            <p style="margin-bottom: 5px;">
              <el-button size="mini" @click="bindNeighbour(scope.row)">绑定小区</el-button>
              <el-button size="mini" @click="handleDetail(scope.row)">详情</el-button>
              <el-button size="mini" @click="removeBind(scope.row,2)" v-if="scope.row.rfid">RFID解绑</el-button>
            </p>
            <p>
              <el-button size="mini" @click="removeBind(scope.row,1)" v-if="scope.row.deviceId">黑匣子解绑</el-button>
              <el-button size="mini" @click="handleVersion(scope.row)" v-if="scope.row.deviceId && scope.row.bikeDevice && scope.row.bikeDevice.deviceType == 1">版本控制</el-button>
              <el-button size="mini" @click="detectionList(scope.row,'/carManage/communication')" v-if="scope.row.deviceId && scope.row.deviceId != '--'">通讯记录</el-button>
              <el-button size="mini" @click="handleLock(scope.row)" v-if="scope.row.deviceId && scope.row.deviceId != '--' && scope.row.bikeDevice.deviceType == 1">
                {{ scope.row.bikeDevice.isPowerOutage == 0 ? "断电" : "通电" }}
              </el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
    <!-- 版本控制 -->
    <el-dialog title="版本控制" :visible.sync="equipVersionVisible" >
      <p class="dec" v-if="this.versionForm">
          更新状态：
          <span v-if="versionForm.targetVersion">{{ versionForm.fromVersion}} - {{ versionForm.targetVersion }}</span>
          <span v-if="versionForm.targetVersion">{{ versionForm.status == 1 ? "待更新" : (versionForm.status == 2 ? "更新中" : (versionForm.status == 3 ? "更新成功" : "更新失败")) }}</span>
          <span v-if="!versionForm.targetVersion">未更新</span>
      </p>
      <el-form :model="versionForm" v-if="versionForm.status != 2">
        <el-form-item label="版本号" label-width="100px">
          <el-select remote filterable v-model="versionForm.softwareId" :remote-method="searchVersion" placeholder="请选择" style="width: 100%;">
            <el-option
               v-for="item in softVersionOptions"
               :key="item.id"
               :label="item.version"
               :value="item.id"
              >
             </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="versionForm.status != 2">
        <el-button @click="equipVersionVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmUpdateVersion">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 新增小区 -->
    <el-dialog title="绑定小区" :visible.sync="bindNeighbourVisible">
      <el-form :model="bindNeighbourForm">
        <el-form-item label="车辆" label-width="100px">
          <span>{{ bindNeighbourForm.plateNumber }} - {{ bindNeighbourForm.brand }}</span>
        </el-form-item>
        <el-form-item label="小区" label-width="100px" v-if="userInfo.roleLevel < 5 && !bindNeighbourForm.merchantId">
          <el-cascader class="each" placeholder="商户 / 小区"
            clearable
            v-model="unitId"
            :options="managerUnitOptions"
            :props="props"
            @change="chooseCarUnit"
            style="width: 100%;"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="小区" label-width="100px" v-else>
          <el-select clearable v-model="bindNeighbourForm.unitId" placeholder="请选择" style="width: 100%;">
            <el-option
               v-for="item in unitOptions"
               :key="item.id"
               :label="item.name"
               :value="item.id"
              >
             </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门牌号" label-width="100px">
          <el-input v-model="bindNeighbourForm.ebikeDoorplate"  placeholder="请输入门牌号" style="width: 100%;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="versionForm.status != 2">
        <el-button @click="bindNeighbourVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmBindNeighbour">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
    <el-drawer title="详情"
      :visible.sync="detailDrawer"
      :direction="direction"
      :before-close="handleClose"
      size="50%">
      <detail v-if="detailDrawer" @close='handleClose' :row="row" ref='newForm'></detail>
    </el-drawer>
  </div>
</template>
<script>
const XLSX = require("xlsx-js-style");
import Pagination from '@/components/Pagination.vue';
import { getEBikeList,getPageHardwareVersion,latestUpgradeRecord,deviceUpgrade,getListSoftwareVersion,getNeighbourhoodList,bindUnitByEBike,unbindUnitByEBike,exportBikeList,getMerchantList,unBindByBikeId,handleLockCar } from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
import detail from './detail.vue';
import { color } from 'echarts';
export default {
  data() {
    return {
      isDetectionOptions: [ // 是否检测
        {value:0,label:'未检测'},
        {value:1,label:'已检测'},
      ],
      userInfo:JSON.parse(localStorage.getItem('userInfo')),
      currentPage:1, // 当前页码
      searchInfo:{ // 搜索条件
        pageSize:10,
        pageNo:1,
        total:1,
        param:{
          deviceId: null,
          plateNumber: null,
          isBlackBox:null,
          isRfid:null,
          status:null,
          unitName:null,
          name:null,
          phone:null,
          isDetection:null,
        }
      },
      tableList:[],// 表格数据列表
      versionOptions:[],
      softVersionOptions:[],// 软件版本号列表
      equipVersionVisible:false, // 版本弹框是否显示
      bindNeighbourVisible:false, // 绑定小区弹框
      bindNeighbourForm:{},
      row:null, // 当前操作数据具体信息
      versionForm:{
        Version:'',
        softwareId:"",
      },
      isInstallEquip:'', // 是否安装黑匣子、是否在线
      equipOptions:[ // 黑匣子选项
        { value:1,
          label:'已安装Pro-4G',
          children:[
            {value:1,label:'在线'},
            {value:2,label:'离线'},
          ]
        },
        // { value:2,
        //   label:'已安装Air-WiFi',
        //   children:[
        //     {value:1,label:'在线'},
        //     {value:2,label:'离线'},
        //   ]
        // },
        { value:3,
          label:'已安装Air-4G',
        },
        { value:4,
          label:'已安装Power-4G',
          children:[
            {value:1,label:'在线'},
            {value:2,label:'离线'},
          ]
        },
        { value:false,label:'未安装' },
      ],
      rfidOptions:[ // RFID选项
        { value:false,label:'未安装'},
        { value:true,label:'已安装'},
      ],
      managerUnitOptions:[], // 所属商户、所属小区选项
      unitId:"",
      props: { // 所属商户、小区 联动选择框配置
        value:'id',
        label:'name',
        lazy: true,
        checkStrictly:true,
        lazyLoad (node, resolve) {
          const { level } = node;
          console.log(node,987)
          setTimeout(() => {
            let data = {
              pageNo:1,
              pageSize:1000,
              param:{
                name:null,
                merchantId:node.value,
              }
            }
            if(level == 1){
                getNeighbourhoodList(data).then(res=>{
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  let nodes = [];
                  res.data.list.forEach(element=>{
                      nodes.push({
                        id: element.id,
                        name: element.name,
                        leaf: level >= 1,
                        children:null,
                      })
                  })
                  // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  resolve(nodes);
                })
                console.log(node,908)
            }
          }, 1000);
        }
      },
      isRfid:'',
      unitOptions:[], // 所属小区选项
      carUnitOptions:[], // 车辆绑定小区时小区选项
      detailDrawer:false,
      direction:'rtl',
    }
  },

  components: { Pagination,detail },

  computed: {},

  mounted() {
    this.init();
    this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : this.getNieghbourList();
  },

  methods: {
    // 获取版本列表选项
    getVersionList(){
      getPageHardwareVersion({param:1}).then(res=>{
        if(res.success){
          this.versionOptions = res.data;
          this.versionOptions.forEach(element=>{

          })
        }
      })
    },
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.managerUnitOptions = res.data.list;
          this.managerUnitOptions.forEach(element=>{
            element.children = [];
          })
        }else{
          this.managerUnitOptions = [];
        }
      })
    },
    // 选择商户、小区
    chooseMerchant(e){
      this.searchInfo.param.merchantId = e[0] ? e[0] : null;
      this.searchInfo.param.unitId = e[1] ? e[1] : null;
    },
    // 获取小区列表
    getNieghbourList(merchantId){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:null,
          merchantId:merchantId
        }
      }
      getNeighbourhoodList(data).then(res=>{
        if(res.success){
          this.unitOptions = res.data.list;
        }
      })
    },
    // 导出
    async educe(){
      await exportBikeList({param:this.searchInfo.param}).then(res=>{
        if( res.success && res.data && res.data.length > 0){
          let dataList = res.data;
          const data = [ // 表头
            ['车牌号','车主名称','车主电话','黑匣子','RFID','是否检测','版本号','在线状态','创建时间']
          ];
          dataList.forEach(element=>{
            let each = [];
            each.push(element.plateNumber ? element.plateNumber : '--'); // 车牌号
            each.push(element.userName ? element.userName : '--'); // 车主姓名
            each.push(element.phone ? element.phone : '--'); // 车主电话
            let deviceId = element.deviceId ? element.deviceId : '未安装'  ;
            each.push(deviceId); // 黑匣子
            each.push(element.rfid ? element.rfid : '未安装'); // rfid
            each.push(element.isDetection == 1 ? '已检测' : '未检测') // 车辆是否检测
            each.push(element.version ? element.version : '--'); // 版本号
            each.push( element.deviceId ? (element.statusStr ? element.statusStr : '--') : '未安装' ); // 在线状态
            each.push(element.createTimeStr ? element.createTimeStr : '--'); // 创建时间
            data.push(each);
          })
          // excel导出
          const sheet = XLSX.utils.aoa_to_sheet(data);
          const book = XLSX.utils.book_new();
          // 设置列宽为自适应
          sheet['!cols'] = [{ wch: 15 },{ wch: 15 },{ wch: 18 },{ wch: 26 },{ wch: 26 },{ wch: 15 },{ wch: 15 },{ wch: 15 },{ wch: 20 }];
          sheet['!rows'] = []; // 行高
          // 设置单元格样式
          for (let row = 0; row < data.length; row++) {
            for (let col = 0; col < data[row].length; col++) {
              const cellRef = XLSX.utils.encode_cell({ c: col, r: row });
              console.log('单元格',cellRef)
              sheet[cellRef].s = {
                alignment: {
                  horizontal: 'center', // 水平居中
                  vertical: 'center',   // 垂直居中
                },
                font: {
                  bold: true // 加粗
                }
              };
              sheet['!rows'].push({ hpx: 28 }) // 行高
            }
          }
          XLSX.utils.book_append_sheet(book,sheet,"sheet1"); // 生成sheet
          XLSX.writeFile(book,`车辆管理.xlsx`); // 导出
        }else{
          this.$message({
            message:'失败，请重试',
            type:'fail'
          })
        }
      })
    },
    // 搜索软件版本号
    async searchVersion(e){
      let data = {
        pageNo:1,
        pageSize:10,
        param:{
            softwareVersion:e,
            hardwareVersion:this.row.bikeDevice.hardWareVersion,
            status:true,
            deviceType:1
        }
      }
      await getListSoftwareVersion(data).then(res=>{
        if(res.success){
          this.softVersionOptions = res.data.list;
          if(this.softVersionOptions.length == 1){
            this.versionForm.softwareId = this.softVersionOptions[0].id;
          }
          this.equipVersionVisible = true;
        }
      })
    },
    handleChoose(e){
      this.searchInfo.param.isBlackBox = e[0] || e[0] == false ? ( e[0] == false ? e[0] : true ): null;
      this.searchInfo.param.deviceType = e[0] ? e[0] : null;
      this.searchInfo.param.status = e[1] ? e[1] : null;
    },
    chooseIsRfid(e){
      if(this.isRfid === true || this.isRfid === false){
        this.searchInfo.param.isRfid = this.isRfid;
      }else{
        this.searchInfo.param.isRfid = null;
      }
    },
    // 打开绑定小区
    bindNeighbour(row){
      this.bindNeighbourVisible = true;
      this.bindNeighbourForm = row;
      this.getNieghbourList(this.bindNeighbourForm.merchantId);
    },
    // 筛选商户、小区
    chooseCarUnit(e){
      this.bindNeighbourForm.unitId = e[1];
    },
    // 确认绑定小区
    confirmBindNeighbour(){
      this.$confirm('确认绑定该小区吗？').then(async () =>{
        let data = {
          ebikeDoorplate:this.bindNeighbourForm.ebikeDoorplate,
          ebikeId:this.bindNeighbourForm.id,
          unitId:this.bindNeighbourForm.unitId,
        }
        await bindUnitByEBike({param:data}).then(res=>{
          if(res.success){
            this.$message({
              message:'绑定成功',
              type:'success'
            })
            this.init();
            this.bindNeighbourVisible = false;
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
        })
      })
    },
    // 解绑小区
    releaseBind(neighbourRow,row){
      event.stopPropagation();
      this.$confirm('确认进行解绑吗？').then(async ()=>{
        let data = {
          ebikeId:row.id,
          unitId:neighbourRow.id,
        }
        await unbindUnitByEBike({param:data}).then(res=>{
          if(res.success){
            this.$message({
              message:'解绑成功',
              type:'success'
            });
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            });
          }
        })
      })
    },
    // 获取最近一条版本更新记录
    getLatestRecord(){
      let data = {
        deviceId: this.row.bikeDevice.id,
        deviceType: 1
      }
      latestUpgradeRecord({param:data}).then(res=>{
        if(res.success){
          this.versionForm = res.data || this.row;
          this.versionForm.nowVersion
        }
      })
    },
    // 确认升级版本
    confirmUpdateVersion(){
      this.$confirm('确认进行该操作吗？').then(async () => {
        let data = {
          deviceId: this.row.deviceId,
          deviceType: 1,
          softwareId: this.versionForm.softwareId,
        }
        await deviceUpgrade({param:data}).then(res=>{
          if(res.success){
            this.$message({
              message:'指令发送成功！',
              type:'success'
            })
            this.equipVersionVisible = false;
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
        })
      })
      
    },
    // 条件筛选事件
    handleSearch(type){
      if(type == 'clear'){
        this.searchInfo.param = {
          deviceId: null,
          plateNumber: null,
          isBlackBox:null,
          isRfid:null,
          status:null,
          unitName:null,
          merchantId:null,
        };
        this.isRfid = '';
        this.unitId = "";
        this.isInstallEquip = [];
      }else{
        // this.searchInfo.param.status = this.searchInfo.param.status === '' ? null : this.searchInfo.param.status;
        // this.searchInfo.param.unitName = this.searchInfo.param.unitName === '' ? null : this.searchInfo.param.unitName;
        // this.searchInfo.param.name = this.searchInfo.param.name === '' ? null : this.searchInfo.param.name;
        // this.searchInfo.param.phone = this.searchInfo.param.phone === '' ? null : this.searchInfo.param.phone;
      }
      this.searchInfo.pageNo = 1;
      this.init();
    },
    // 获取表格数据
    async init(){
      await getEBikeList(this.searchInfo).then(res=>{
        if(res.success){
          this.tableList = res.data.list;
          this.searchInfo.total = res.data.total;
          this.tableList.forEach(element => {
            element.createTime = timeChange(element.createTime,'seconds');
          });
        }else{
          this.tableList = [];
          this.searchInfo.total = 0;
        }
      })
    },
    // 解绑设备
    removeBind(row,type){
      this.$confirm('确认解绑吗？','提示').then(res=>{
        let data = {
          param:{
            deviceId:row.id,
            type:type,
          }
        }
        unBindByBikeId(data).then(res=>{
          if(res.success){
            this.$message({
              message:'解绑成功',
              type:'success'
            })
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
        })
      })
    },
    // 查询车辆详情
    handleDetail(row){
      this.row = row;
      this.detailDrawer = true;
    },
    // 关闭弹框
    handleClose(){
      this.detailDrawer = false;
      this.init();
    },
    // 查看检测记录
    detectionList(row,path){
      this.$router.push({path:path,query:{id:row.id}});
    },
    // 打开版本弹框
    handleVersion(row){
      this.row = row;
      this.getLatestRecord();
      this.searchVersion("");
    },
    // 车辆解锁、上锁
    handleLock(row){
      this.$confirm("确认执行该操作吗？").then(async () => {
        const loading = this.$loading({
            lock: true,
            text: '指令下发中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
            color:"#fff"
        });
        let data = {
          param:{
            bikeId:row.id,
            switchLock:row.bikeDevice.isPowerOutage == 1 ? 0 : 1,
          }
        }
        await handleLockCar(data).then(res=>{
          this.$message({
            message:res.success && res.data.result ? "指令下发成功" : "指令下发失败",
            type:res.success && res.data.result ? "success" : "fail",
          })
          loading.close();
          this.init();
        })
      })
      
    },
    // 分页插件事件
    callFather(param){
      this.searchInfo.pageNo = param.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped lang="scss" scoped>
.dec{
  line-height: 45px;
}

// 加载文字颜色
 .el-loading-spinner{
  /deep/ i,.el-loading-text{
    color:#fff !important;
    z-index: 2400;
  }
 }
</style>
