<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="180px" style="width:95%;overflow:auto">
        <el-form-item label="设备编号" prop="id">
          <el-input placeholder="请输入设备编号" v-model="form.id" :disabled="row ? true : false"></el-input>
        </el-form-item>
        <el-form-item label="所属商户" prop="merchantId" v-if="userInfo.roleLevel < 5 && ( (row && !row.merchantId) || !row)">
          <el-select v-model="form.merchantId" placeholder="请选择所属商户" @change="chooseMerchant">
            <el-option
              v-for="item in managerUnitOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安装小区" prop="unitId" v-if="userInfo.roleLevel <= 5">
          <el-select filterable remote v-model="form.unitId" clearable placeholder="请输入小区名称查询" :remote-method="getNieghbourList">
            <el-option
              v-for="item in neighbourhoodList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="小区内具体安装位置" prop="adders">
          <el-input placeholder="请填写具体安装位置" v-model="form.adders"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="form.type" placeholder="请选择">
             <el-option
               v-for="item in typeOpions"
               :key="item.value"
               :label="item.label"
               :value="item.value"
              >
             </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="头盔识别置信度(%)" prop="trustPercent">
          <el-input placeholder="请输入头盔识别置信度" v-model="form.trustPercent" type='number'></el-input>
          <p>头盔识别置信度推荐设置55%</p>
        </el-form-item>
        <el-collapse v-model="showMore" v-if="!row">
          <el-collapse-item title="配置项" name="1">
            <el-form-item label="无网开闸超时时间（秒）" prop="timeout">
              <el-input placeholder="请输入无网开闸超时时间" v-model="form.timeout" type='number'></el-input>
              <p>提示：若设置为0，则认为无网状态不开道闸</p>
            </el-form-item>
            <el-form-item label="灯光配置"  prop="light">
              <el-radio-group v-model="form.light">
                <el-radio label="on">常开</el-radio>
                <el-radio label="auto">自动</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="语音播报是否开启"  prop="sound">
              <el-radio-group v-model="form.sound">
                <el-radio label="on">是</el-radio>
                <el-radio label="off">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <div v-if="form.sound == 'on'">
              <el-form-item label="等待语音播报内容"  prop="triggerVoice">
              <el-input v-model="form.triggerVoice" placeholder="请输入语音播报内容(例：您好，请稍等)"></el-input>
              </el-form-item>
              <el-form-item label="开闸语音播报内容"  prop="openVoice">
                <el-input v-model="form.openVoice" placeholder="请输入语音播报内容(例：闸已开，请通过)"></el-input>
              </el-form-item>
              <el-form-item label="拒绝通行语音播报内容"  prop="refuseVoice">
                <el-input v-model="form.refuseVoice" placeholder="请输入语音播报内容(例：您好，请佩戴头盔)"></el-input>
              </el-form-item>
              <el-form-item label="设备异常语音播报内容"  prop="errorVoice">
                <el-input v-model="form.errorVoice" placeholder="请输入语音播报内容(例：设备异常)"></el-input>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="handleSubmit('form')" :plain='true'>立即提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  import { addInfraredHelmet,getNeighbourhoodList,editInfraredHelmet,getMerchantList,dispositionInfraredHelmet } from '@/api/public.js';
  import { discount } from '@/api/publicFun.js';
  export default {
    props:['row'], // 父组件携带参数
    data() {
      return {
        showMore:"0",
        form:{ // 表单数据
          adders: "", // 小区内具体安装位置
          deviceSecret: "", // 设备密钥
          hardWareVersion: "", // 硬件版本号
          id: "", // 设备编号
          status: "", // 状态（是否启用）
          type: "", // 类型（入口、出口）
          unitId: "", // 小区id
          version: "", // 软件版本号
          timeout:5,
          photoStatus:"on",
          trustPercent:55,
          light:"auto",
          sound:"on",
          triggerVoice:"您好，请稍等",
          openVoice:"道闸已开，请快速通过",
          refuseVoice:"请佩戴头盔",
          errorVoice:'设备异常,请联系工作人员'
        },
        typeOpions:[
          {value:1,label:'入口'},
          {value:2,label:'出口'},
        ],
        rules: { // 表单验证
          id:[
            { required: true, message: '设备编号不得为空', trigger: 'blur',}
          ],
          unitId:[
            { required:true,message:'安装小区不得为空',trigger:"blur"}
          ],
          adders:[
            { required:true,message:'安装位置不得为空',trigger:"blur"}
          ],
          type:[
            { required:true,message:'类型不得为空',trigger:"blur"}
          ],
          cameraStatus:[
            { required:true,message:'请选择是否带摄像头',trigger:"blur"}
          ],
          photoStatus:[
            { required:true,message:'请选择是否识别头盔',trigger:"blur"}
          ],
          trustPercent:[
            { required:true,message:'请输入头盔识置信度',trigger:"blur"},
            {required:true,validator:discount,trigger:'blur'}
          ],
          timeout:[
            { required:true,message:'请输入无网开闸超时时间',trigger:"blur"},
          ],
          light:[
            { required:true,message:'请选择灯光配置',trigger:"change"},
          ],
          sound:[
            { required:true,message:'请选择语音播报是否开启',trigger:"change"},
          ],
          triggerVoice:[
            { required:true,message:'请输入等待语音播报内容',trigger:"blur"},
          ],
          openVoice:[
            { required:true,message:'请输入开闸语音播报内容',trigger:"blur"},
          ],
          refuseVoice:[
            { required:true,message:'请输入拒绝通行语音播报内容',trigger:"blur"},
          ],
          errorVoice:[
            { required:true,message:'请输入设备异常语音播报内容',trigger:"blur"},
          ],
        },
        workDates:null,
        neighbourhoodList:[], // 小区列表
        managerUnitOptions:[], // 商户列表
        props: { // 所属商户、小区 联动选择框配置
          value:'id',
          label:'name',
          lazy: true,
          checkStrictly:true,
          lazyLoad (node, resolve) {
            const { level } = node;
            console.log(node,987)
            setTimeout(() => {
              let data = {
                pageNo:1,
                pageSize:1000,
                param:{
                  name:null,
                  merchantId:node.value,
                }
              }
              getNeighbourhoodList(data).then(res=>{
              // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                let nodes = [];
                res.data.list.forEach(element=>{
                  nodes.push({
                    id: element.id,
                    name: element.name,
                    leaf: level >= 1,
                    children:null,
                  })
                })
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                resolve(nodes);
              })
              console.log(node,908)
            }, 500);
          }
        },
        unitId:[],
        userInfo:JSON.parse(localStorage.getItem('userInfo'))
      };
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.form = this.row ? {...this.row} : this.form;
      this.getMerchantOptions();
      this.getNieghbourList(null);
    },
  
    methods: {
      // 获取小区列表
      getNieghbourList(e){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:e,
            merchantId:this.form.merchantId,
          }
        }
        getNeighbourhoodList(data).then(res=>{
          if(res.success){
            this.neighbourhoodList = res.data.list;
          }
        })
      },
      // 获取商户列表
      async getMerchantOptions(){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:'',
          }
        }
        await getMerchantList(data).then(res=>{
          if(res.success){
            this.managerUnitOptions = res.data.list;
          }else{
            this.managerUnitOptions = [];
          }
        })
      },
      // 选择商户、小区
      chooseMerchant(e){
        this.form.merchantId = e;
        this.getNieghbourList();
      },
      // 关闭弹窗
      close(form){
         this.$emit('close');
      },
      // 新增
      async add(){
        await addInfraredHelmet({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
      },
      // 编辑
      async edit(){
        await editInfraredHelmet({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
            }, 1000);
          }else{
            this.$message({
              message: res.msg,
              type: "fail",
            });
          }
        })
      },
      // 配置
      async disposition(){
        let data = {
          param:{
            deviceId:this.form.id,
            triggerVoice:this.form.triggerVoice,
            sound:this.form.sound,
            light:this.form.light,
            timeout:this.form.timeout,
          }
        }
        await dispositionInfraredHelmet(data).then(res=>{
          this.$message({
            message:res.success ? '设备配置指令下发成功' : "设备初始化配置：" + res.msg,
            type:res.success ? 'success' : 'fail',
          })
        })
      },
      // 提交
      handleSubmit(form) {
        if(this.userInfo.roleLevel >= 10){
          this.form.unitId = this.userInfo.unitId;
        }
         this.$refs["form"].validate(valid => {
            if (valid) {
                this.$confirm('确认提交吗？', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(()=>{
                  if(this.row){
                    this.edit();
                  }else{
                    this.add();
                    this.disposition();
                  }
                })
            }else{
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss" scoped>
  // 防止地图自动完成的对象被遮挡
  .tangram-suggestion {
      z-index: 9999 !important;
    }
   .el-drawer{
     overflow:auto !important;
   }
   .map{
    border: 1px solid #dcdfe6;
    background: #fff;
    border-radius: 4px;
    width: 80px;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .el-collapse{
      border: 0 !important;
      padding-left: 100px;
    }
    /deep/.el-collapse-item__header{
      border: 0 !important;
      font-size: 18px !important;
      font-weight: 600 !important;
      justify-content: flex-start !important;
    }
    /deep/.el-collapse-item__wrap{
      border: 0 !important;
    }
    /deep/.el-collapse-item__arrow{
      margin: 0 10px;
    }
  </style>
  