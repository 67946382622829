<!-- 新能源充电桩经营报表 -->
<template>
  <section class="container">
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-date-picker start-placeholder="开始时间" range-separator="至" end-placeholder="结束时间" v-model="choosedTime"
       type="daterange"
       :default-time="['00:00:00', '23:59:59']"
       @change='chooseOrderTime'
       :clearable="false"
       class='timeSelect each'>
      </el-date-picker>
      <el-cascader class="each" placeholder="所属商户 / 所属小区" v-model="merchantUnitId"
        :options="managerUnitOptions"
        :props="props"
        @change="chooseMerchant"
        v-if="userInfo.roleLevel < 5"
      ></el-cascader>
      <el-select placeholder="所属小区" class='each' v-model="searchInfo.param.unitId" clearable v-if="userInfo.roleLevel >= 5 && userInfo.roleLevel < 10"
        @change="chooseUnit">
        <el-option
          v-for="item in unitOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-button size="mini" @click="toStatistics">报表统计</el-button>
    </div>
    <div class="content">
        <div class="eachContent" style="width: 50%;">
          <b>{{ merchantAmount.wxPreAmount ? merchantAmount.wxPreAmount : 0 }}元</b>
          <span>微信商户收款</span>
        </div>
        <div class="eachContent" style="width: 50%;">
          <b>{{ merchantAmount.wxRefundAmount ? merchantAmount.wxRefundAmount : 0 }}元</b>
          <span>微信商户退款</span>
        </div>
        <div class="eachContent" v-for="item in contentList" :key="item.value">
            <b>{{ item.value2 ? (statisticsInfo[item.value] ? statisticsInfo[item.value] : 0) + ' / ' + (statisticsInfo[item.value2] ? statisticsInfo[item.value2] : 0) : (statisticsInfo[item.value] || statisticsInfo[item.value] == 0 ? statisticsInfo[item.value] : 0)}}</b>
            <span>{{ item.name }}</span>
        </div>
    </div>
    <div class="charts">
      <!-- 收益 -->
      <div class="report">
        <div class="reportOne" id="income">
        </div>
      </div>
      <!-- 订单 -->
      <div class="report">
        <div class=" reportTwo" id="order"></div>
      </div>
    </div>
  </section>
</template>

<script>
import * as echarts from 'echarts';
let inComeChart,orderChart;
import { getNeighbourhoodList,getMerchantList,getSevenAmount,getSevenOrder,operationStatistics,getMerchantAmount } from '@/api/public.js';
import { timeChange,getNowTime } from '@/api/publicFun.js';
export default {
  data() {
    return {
        searchInfo:{ // 筛选条件
            param:{
                unitId:null,
                endTime:null,
                merchantId:null,
                startTime:null,
                type: 2,
            },
        },
        unitOptions:[], // 小区选项
        managerUnitOptions:[], // 商户、小区联级选项
        merchantUnitId:[],
        choosedTime:[], // 时间范围选择绑定数据
        contentList:[ // 统计展示项列表
            { name:'安装时间',value:'installTimeStr' },
            { name:'安装充电桩数 / 枪数',value:'chargingPileNumber',value2:'chargingPilePortNumber' },
            { name:'投入成本（元）',value:'inputCosts' },
            { name:'车位数量',value:'placeQuantity' },
            { name:'单枪日收入（元）',value:'singlePortDailyIncome' },
            { name:'订单数',value:'orderNumber' },
            { name:'已完成订单预收（元）',value:'preAmount' },
            { name:'已完成订单退款（元）',value:'refundAmount' },
            { name:'实收（元）',value:'actuallyAmount' },
            { name:'用电度数（度）',value:'energyUsed' },
            { name:'电费（元）',value:'electricityCharge' },
            { name:'服务费（元）',value:'serviceCharge' },
            { name:'分润比例（%）',value:'profitSharingRatio' },
            { name:'服务费分润（元）',value:'serviceChargeShareProfit' },
            { name:'服务费净收入（元）',value:'serviceChargeNetIncome' },
        ],
        statisticsInfo:{},  // 统计数据
        incomeTime:['2024-03-06','2024-03-07','2024-03-08','2024-03-09','2024-03-10','2024-03-11','2024-03-12'], // 收入图表x轴
        incomeData:[273.62,262.22,347.18,350,207.92,404.75,16.33], // 收入图表y轴,
        orderTime:['2024-03-06','2024-03-07','2024-03-08','2024-03-09','2024-03-10','2024-03-11','2024-03-12'], // 订单图表x轴
        orderData:[5,3,6,4,1,10,1], // 订单图表y轴
        userInfo:JSON.parse(localStorage.getItem('userInfo')),
        props: { // 所属商户、小区 联动选择框配置
          value:'id',
          label:'name',
          lazy: true,
          checkStrictly:true,
          lazyLoad (node, resolve) {
            const { level } = node;
            setTimeout(() => {
              let data = {
                pageNo:1,
                pageSize:1000,
                param:{
                  name:null,
                  merchantId:node.value,
                }
              }
              if(level == 1){
                getNeighbourhoodList(data).then(res=>{
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  let nodes = [];
                  res.data.list.forEach(element=>{
                      nodes.push({
                        id: element.id,
                        name: element.name,
                        leaf: level >= 1,
                        children:null,
                      })
                  })
                  // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  resolve(nodes);
                })
                console.log(node,908)
              }
            }, 1000);
          }
        },
        merchantAmount:{},
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : this.getNieghbourList();
    this.userInfo.roleLevel >= 5 ? this.getInitTime() : null;
  },

  methods: {
    // 获取初始化页面时的时间范围
    getInitTime(){
      this.searchInfo.param.startTime = getNowTime('month')+'-01';
      this.searchInfo.param.endTime = getNowTime('day');
      this.choosedTime = [new Date(this.searchInfo.param.startTime),new Date(this.searchInfo.param.endTime)];
      this.$nextTick(()=>{
        this.init();
        this.getStaticsInfo();
      })
    },
    // 获取图表数据
    async init(){
      console.log(this.searchInfo,999)
      this.incomeTime = [];
      this.incomeData = [];
      this.orderTime = [];
      this.orderData = [];
      // 近七日实收金额数据
      await getSevenAmount(this.searchInfo).then(res=>{
        if(res.success){
          res.data.forEach(element => {
            let time = timeChange(element.date,'day');
            this.incomeTime.push(time);
            this.incomeData.push(element.actuallyAmount);
          });
        }else{
          this.$message({
            message:res.msg,
            type:'fail'
          })
        }
      })
      // 近七日订单数量数据
      await getSevenOrder(this.searchInfo).then(res=>{
        if(res.success){
          res.data.forEach(element => {
            let time = timeChange(element.date,'day');
            this.orderTime.push(time);
            this.orderData.push(element.orderNumber);
          });
        }else{
          this.$message({
            message:res.msg,
            type:'fail'
          })
        }
      })
      this.incomeReport();
      this.orderReport();
    },
    // 获取统计数据
    async getStaticsInfo(){
      await operationStatistics(this.searchInfo).then(res=>{
        if(res.success){
          this.statisticsInfo = res.data;
          this.statisticsInfo.installTimeStr = this.statisticsInfo.installTime ? timeChange(this.statisticsInfo.installTime,'day') : '--';
        }
      })
      await getMerchantAmount(this.searchInfo).then(res=>{
        if(res.success){
          this.merchantAmount = res.data;
        }
      })
    },
    // 搜索事件
    handleSearch(type){
        if(type == 'clear'){
            this.searchInfo.param.unitId = null;
            this.getInitTime();
        }else{
          this.init();
          this.getStaticsInfo();
        }
    },
    // 选择时间范围
    chooseOrderTime(e){
      this.searchInfo.param.startTime = timeChange(e[0],'day');
      this.searchInfo.param.endTime = timeChange(e[1],'day');
      this.init();
      this.getStaticsInfo();
    },
    // 获取小区列表
    getNieghbourList(){
      let data = {
        pageNo:1,
        pageSize:10000,
        param:{
          name:null,
        }
      }
      getNeighbourhoodList(data).then(res=>{
        if(res.success){
          this.unitOptions = res.data.list;
        }
      })
    },
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.managerUnitOptions = res.data.list;
          this.managerUnitOptions.forEach(element=>{
            element.children = [];
          })
          this.merchantUnitId = [res.data.list[0].id];
          this.searchInfo.param.merchantId = res.data.list[0].id;
          this.$nextTick(()=>{
            this.getInitTime();
          })
        }else{
          this.managerUnitOptions = [];
        }
      })
    },
    // 选择商户、小区
    chooseMerchant(e){
      this.searchInfo.param.merchantId = e[0] ? e[0] : null;
      this.searchInfo.param.unitId = e[1] ? e[1] : null;
      this.init();
      this.getStaticsInfo();
    },
    // 选择小区
    chooseUnit(e){
      this.init();
      this.getStaticsInfo();
    },
    // 前往全小区报表统计列表页面
    toStatistics(){
      if(this.searchInfo.param.merchantId){
        this.$router.push({
            path:'/reportFormsManage/statistics',
            query:{
              merchantId:this.searchInfo.param.merchantId,
            }
        })
      }else{
        this.$router.push({path:'/reportFormsManage/statistics',})
      } 
    },
    // 收入统计图表绘制
    incomeReport(){
      var incomeChartDom = document.getElementById('income');
      inComeChart = echarts.init(incomeChartDom);
      setTimeout(()=>{
        var incomeOption = {
          title: {
            text: '近七天实收金额趋势(不含当天数据)',
            textStyle:{
              fontSize:'0.1rem'
            }
          },
          tooltip: { // 鼠标悬浮时显示当下数据详情
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: this.incomeTime
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name:'金额',
              data: this.incomeData,
              type: 'line',
              smooth: true,
            }
          ]
        };
        window.addEventListener('resize',()=>{
          inComeChart.resize();
        })
        incomeOption && inComeChart.setOption(incomeOption);
      },500)
    },
    // 订单统计图表绘制
    orderReport(){
      var orderChartDom = document.getElementById('order');
      orderChart = echarts.init(orderChartDom);
      setTimeout(()=>{
        var orderOption = {
          title: {
            text: '近七天订单趋势(不含当天数据)',
            textStyle:{
              fontSize:'0.1rem'
            }
          },
          tooltip: {// 鼠标悬浮时显示当下数据详情
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: this.orderTime,
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name:'订单量',
              data: this.orderData,
              type: 'line',
              smooth: true,
            }
          ]
        };
        window.addEventListener('resize',()=>{
          orderChart.resize();
        })
        orderOption && orderChart.setOption(orderOption);
      },500)
    },
  },
};
</script>
<style scoped lang="scss">
.wcMerchant{
  line-height: 50px;
  text-align: left;
  font-size: 18px;
  padding-left:20px;
  span{
    display: inline-block;
    margin-left: 30px;
  }
}
.container{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.charts{
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 200px;
  .report{
    width: calc(50% - 0.2rem);
    background: #fff;
    height: 100%;
    padding: 0.05rem;
    position: relative;
    div{
      width: 100%;
      height: 100%;
    }
    .el-select{
      width: 25%;
      position: absolute;
      top:10px;
      right: 30px;
    }
  }
}
.content{
    display: flex;
    background-color: white;
    padding: 30px 0 0;
    margin-bottom: 0.12rem;
    flex-wrap: wrap;
    height: 50%;
    box-sizing: border-box;
    .eachContent{
        height:calc(25% - 0.1rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20%;
        margin-bottom: 0.1rem;
    }
    span{
        margin-bottom: 10px;
        color: #8c8c8c;
        font-size: 0.1rem;
    }
    b{
        font-size: 0.15rem;
        font-weight: 500;
    }
}

</style>
