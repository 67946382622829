<!-- 设备管理 / 感应器管理 -->
<template>
  <div class="outContainer">
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-input class="each" placeholder="感应器地址" v-model="searchInfo.param.adders">
      </el-input>
      <el-cascader class="each" placeholder="所属商户 / 所属小区"
        clearable
        v-model="unitId"
        :options="managerUnitOptions"
        :props="props"
        @change="chooseMerchant"
        v-if="userInfo.roleLevel < 5"
      ></el-cascader>
      <el-select class='each' placeholder="安装小区" v-model="searchInfo.param.unitId" clearable v-if="userInfo.roleLevel >= 5 && userInfo.roleLevel < 10">
        <el-option
          v-for="item in unitOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-select class='each' placeholder="在线状态" v-model="searchInfo.param.status" clearable>
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          >
        </el-option>
      </el-select>
      <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
      <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
      <!-- <el-button size="mini" @click="openDrawer(null,'add')">新增</el-button> -->
    </div>
    <!-- 数据表格 -->
    <div class="tableContainer">
      <el-table
      :data="tableList"
      height="100%"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      row-key="id"
      style="width: 100%;">
        <af-table-column align="center" label="设备编号" prop="id">
        </af-table-column>
        <af-table-column align="center" label="安装小区" prop="unitName">
        </af-table-column>
        <af-table-column align="center" label="具体安装位置" prop="adders">
          <template slot-scope="scope">
            <p>{{ (scope.row.areaAdders ? scope.row.areaAdders : '') + (scope.row.adders ? scope.row.adders : '')}}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="时间间隔（秒）" prop="identifyInterval">
          <template slot-scope="scope">
            <p>心跳间隔:{{ scope.row.heartbeatInterval ? Number(scope.row.heartbeatInterval) / 1000 : '--'}}</p>
            <p>读标签间隔:{{ scope.row.readInterval ? Number(scope.row.readInterval)/1000 : '--'}}</p>
            <p>上报间隔:{{ scope.row.sendInterval ? Number(scope.row.sendInterval)/1000 : '--'}}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="方向类型" prop="directionType">
          <template slot-scope="scope">
            <p v-if="scope.row.directionType == 0">主→副:进入</p>
            <p v-if="scope.row.directionType == 0">副→主:离开</p>
            <p v-if="scope.row.directionType == 1">主→副:离开</p>
            <p v-if="scope.row.directionType == 1">副→主:进入</p>
            <p v-if="!scope.row.directionType && scope.row.directionType != 0">--</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="感应范围（米）" prop="power">
          <template slot-scope="scope">
            <span v-if="scope.row.power == 16">0.8</span>
            <span v-if="scope.row.power == 17">1</span>
            <span v-if="scope.row.power == 18">1.5</span>
            <span v-if="scope.row.power == 19">2</span>
            <span v-if="scope.row.power == 20">2.5</span>
            <span v-if="scope.row.power == 21">3</span>
            <span v-if="scope.row.power == 22">4</span>
            <span v-if="scope.row.power == 23">4.5</span>
            <span v-if="scope.row.power == 24">5</span>
            <span v-if="scope.row.power == 25">6</span>
            <span v-if="scope.row.power == 26">7</span>
            <span v-if="scope.row.power == 27">9</span>
            <span v-if="scope.row.power == 28">10</span>
            <span v-if="scope.row.power == 29">12</span>
            <span v-if="scope.row.power == 30">13</span>
            <span v-if="scope.row.power == 31">14</span>
            <span v-if="scope.row.power == 32">15</span>
            <span v-if="scope.row.power == 33">16</span>
            <span v-if="!scope.row.power">--</span>
          </template>
        </af-table-column>
        <af-table-column align="center" label="版本" prop="version">
          <template slot-scope="scope">
            <p>硬件版本：{{ scope.row.hardWareVersion ? scope.row.hardWareVersion : '--'}}</p>
            <p>软件版本：{{ scope.row.version ? scope.row.version : '--'}}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="在线状态" prop="status">
          <template slot-scope="scope">
            <p>{{ scope.row.status == 1 ? '在线' : (scope.row.status == 2 ? '离线' : '--')}}</p>
          </template>
        </af-table-column>
        <af-table-column align="center" label="安装时间" prop="createTimeStr">
        </af-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="250">
          <template slot-scope="scope">
            <p style="margin-bottom: 5px;">
              <el-button size="mini" @click="toChild(scope.row,'感应器感应记录')">感应记录</el-button>
              <el-button size="mini" @click="toChild(scope.row,'感应器通讯记录')">通讯记录</el-button>
            </p>
            <p>
              <el-button size="mini" @click="disposition(scope.row,'add')">配置</el-button>
              <el-button size="mini" @click="handleRestart(scope.row)">重启</el-button>
              <el-button size='mini' @click="handleDel(scope.row)" v-if="!scope.row.mainId">删除</el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
    <!-- 配置 -->
    <el-dialog title="配置" :visible.sync="open" width="60%" append-to-body>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="功率" name="power">
          <p class="remind">（指令下发成功后需重启才生效）</p>
          <el-form label-width="180px">
            <el-form-item label="设备编号">
              <el-input v-model="dispositionInfo.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="感应范围（米）">
              <el-select v-model="dispositionInfo.power" style="width: 100%;">
                <el-option
                  v-for="item in radiusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="时间间隔" name="interval">
          <p class="remind">（指令下发成功后需等待几分钟,设备才会生效,请耐心等待）</p>
          <el-form label-width="180px">
            <el-form-item label="设备编号">
              <el-input v-model="dispositionInfo.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="心跳时间间隔（秒）" v-if="userInfo && userInfo.roleLevel === 0">
              <el-input v-model="dispositionInfo.heartbeatInterval" placeholder="请输入心跳时间间隔"></el-input>
            </el-form-item>
            <el-form-item label="读标签时间间隔（秒）">
              <el-input v-model="dispositionInfo.readInterval" placeholder="请输入读标签时间间隔"></el-input>
            </el-form-item>
            <el-form-item label="上报时间间隔（秒）">
              <el-input v-model="dispositionInfo.sendInterval" placeholder="请输入上报时间间隔"></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">关闭</el-button>
        <el-button @click="confirm">提交</el-button>
      </div>
    </el-dialog>
    <!-- 新增、编辑 -->
    <el-drawer :title="row ? '编辑' : '新增'"
      :visible.sync="addDrawer"
      size="50%">
      <add v-if="addDrawer" @close="handleClose"  ref='newForm' :row="row"></add>
    </el-drawer>
    <!-- 新增4G开关 -->
    <el-drawer title="新增4G开关"
      :visible.sync="add4GDrawer"
      size="50%">
      <add4G v-if="add4GDrawer" @close="handleClose"  ref='newForm' :row="row"></add4G>
    </el-drawer>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination.vue';
import { getInductorList,deleteInductor,setPowerInductor,setIntervalInductor,restartInductor,getNeighbourhoodList,getMerchantList,delWifiSwitch,lockSwitch } from '@/api/public.js';
import add from './add.vue';
import add4G from './add4G.vue';
import { timeChange } from '@/api/publicFun.js';
export default {
  data() {
    return {
      searchInfo:{ // 筛选条件
        pageSize:10,
        pageNo:1,
        total:0,
        param:{
          adders:null,
          status:null,
          type:null,
          merchantId:null,
          unitId:null,
        }
      },
      tableList:[], // 表格数据列表
      row:null, // 当前操作数据具体信息
      addDrawer:false, // 是否显示新增弹框
      add4GDrawer:false, // 是否显示新增4G开关弹框
      statusOptions:[ // 是否在线选项
        {value:1,label:'在线'},
        {value:2,label:'离线'},
      ],
      typeOptions:[ // 感应器类型选项
        {value:1,label:'停放区'},
        {value:2,label:'临时停放区'},
        {value:3,label:'禁行区'},
        {value:4,label:'通道感应'},
      ],
      managerUnitOptions:[], // 所属商户、所属小区选项
      props: { // 所属商户、小区 联动选择框配置
        value:'id',
        label:'name',
        lazy: true,
        checkStrictly:true,
        lazyLoad (node, resolve) {
          const { level } = node;
          console.log(node,987)
          setTimeout(() => {
            let data = {
              pageNo:1,
              pageSize:1000,
              param:{
                name:null,
                merchantId:node.value,
              }
            }
            if(level == 1){
              getNeighbourhoodList(data).then(res=>{
              // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                let nodes = [];
                res.data.list.forEach(element=>{
                    nodes.push({
                      id: element.id,
                      name: element.name,
                      leaf: level >= 1,
                      children:null,
                    })
                })
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                resolve(nodes);
              })
              console.log(node,908)
            }
          }, 1000);
        }
      },
      unitId:"", // 小区id
      unitOptions:[], // 安装小区选项
      open:false, // 配置弹框是否显示
      dispositionInfo:{ // 配置信息
        timeout:-1,
      },
      activeName:'power', // 配置弹框当前激活的tab选项
      userInfo:JSON.parse(localStorage.getItem('userInfo')), // 当前登录账号用户信息
      radiusOptions:[ // 感应范围选项
        {value:16,label:0.8},
        {value:17,label:1},
        {value:18,label:1.5},
        {value:19,label:2},
        {value:20,label:2.5},
        {value:21,label:3},
        {value:22,label:4},
        {value:23,label:4.5},
        {value:24,label:5},
        {value:25,label:6},
        {value:26,label:7},
        {value:27,label:9},
        {value:28,label:10},
        {value:29,label:12},
        {value:30,label:13},
        {value:31,label:14},
        {value:32,label:15},
        {value:33,label:16},
      ],
    }
  },

  components: {
    add,
    Pagination,
    add4G
  },

  computed: {},

  mounted() {
    this.init();
    this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : this.getNieghbourList();
  },

  methods: {
    // 条件筛选事件
    handleSearch(type){
      if(type == 'clear'){
        this.searchInfo.param = {
          adders:null,
          status:null,
          type:null,
          merchantId:null,
          unitId:null,
        };
        this.unitId = "";
      }else{
        this.searchInfo.param.adders = this.searchInfo.param.adders ? this.searchInfo.param.adders : null;
        this.searchInfo.param.status = this.searchInfo.param.status ? this.searchInfo.param.status : null;
        this.searchInfo.param.type = this.searchInfo.param.type ? this.searchInfo.param.type : null;
        this.searchInfo.param.unitId = this.searchInfo.param.unitId ? this.searchInfo.param.unitId : null;
      }
      this.searchInfo.pageNo = 1;
      this.init();
    },
    // 判断当前行是否有子数据或者根据实际情况设置
    getRowClass(row){
      if(!row.row.switchDeviceVOList || row.row.switchDeviceVOList.length == 0){  
        return 'row-expand-cover';
      }
    },
    // 初始化获取表格数据
    async init(){
      await getInductorList(this.searchInfo).then(res=>{
        if(res.success){
          this.tableList = res.data.list;
          this.searchInfo.total = res.data.total;
          this.tableList.forEach(element => {
            element.createTimeStr = timeChange(element.createTime,'seconds');
            if(element.auxiliaryInductor){
              element.auxiliaryInductor.createTimeStr = element.auxiliaryInductor ? timeChange(element.auxiliaryInductor.createTime,'seconds') : '--';
              element.children = element.auxiliaryInductor ? [element.auxiliaryInductor] : [];
            }
          });
        }else{
          this.tableList = [];
          this.searchInfo.total = 0;
        }
      })
    },
    // 获取小区列表
    getNieghbourList(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:null,
        }
      }
      getNeighbourhoodList(data).then(res=>{
        if(res.success){
          this.unitOptions = res.data.list;
        }
      })
    },
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.managerUnitOptions = res.data.list;
          this.managerUnitOptions.forEach(element=>{
            element.children = [];
          })
        }else{
          this.managerUnitOptions = [];
        }
      })
    },
    // 选择商户、小区
    chooseMerchant(e){
      this.searchInfo.param.merchantId = e[0] ? e[0] : null;
      this.searchInfo.param.unitId = e[1] ? e[1] : null;
    },
    // 删除
    handleDel(row){
      this.$confirm('确认删除吗？').then(async () => {
        await deleteInductor({param:row.id}).then(res=>{
          if(res.success){
            this.$message({
              message:'删除成功！',
              type:"success"
            })
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:"fail"
            })
          }
        })
      })
      
    },
    // 重启
    handleRestart(row){
      this.$confirm('确认进行该操作吗？').then(async () => {
        await restartInductor({param:row.id}).then(res=>{
          if(res.success){
            this.$message({
              message:'操作成功',
              type:'success'
            })
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'error',
            })
          }
        })
      })
    },
    // 打开配置
    disposition(row){
      this.dispositionInfo = {...row};
      this.dispositionInfo.heartbeatInterval = this.dispositionInfo.heartbeatInterval ? this.dispositionInfo.heartbeatInterval / 1000 : this.dispositionInfo.heartbeatInterval;
      this.dispositionInfo.readInterval = this.dispositionInfo.readInterval ? this.dispositionInfo.readInterval / 1000 : this.dispositionInfo.readInterval;
      this.dispositionInfo.sendInterval = this.dispositionInfo.sendInterval ? this.dispositionInfo.sendInterval / 1000 : this.dispositionInfo.sendInterval;
      this.open = true;
    },
    // 关闭
    close(){
      this.open = false;
      this.init();
    },
    // 提交配置
    confirm(){
      this.$confirm('确认进行该操作吗？').then(async () => {
        this.dispositionInfo.deviceId = this.dispositionInfo.id;
        if(this.activeName == 'power'){
          this.dispositionInfo.power = Number(this.dispositionInfo.power);
          await setPowerInductor({param:this.dispositionInfo}).then(res=>{
            if(res.success){
              this.$message({
                message:'提交成功,指令下发成功后需等待几分钟,设备才会生效,请耐心等待',
                type:'success'
              })
              this.init();
              this.open = false;
            }else{
              this.$message({
                message:res.msg,
                type:'fail'
              })
            }
          })
        }else{
          this.dispositionInfo.heartbeatInterval = Number(this.dispositionInfo.heartbeatInterval) * 1000;
          this.dispositionInfo.readInterval = Number(this.dispositionInfo.readInterval) * 1000;
          this.dispositionInfo.sendInterval = Number(this.dispositionInfo.sendInterval) * 1000;
          await setIntervalInductor({param:this.dispositionInfo}).then(res=>{
            if(res.success){
              this.$message({
                message:'提交成功,指令下发成功后需等待几分钟,设备才会生效,请耐心等待',
                type:'success'
              })
              this.init();
              this.open = false;
            }else{
              this.$message({
                message:res.msg,
                type:'fail'
              })
            }
          })
        }
      })
    },
    // 前往子页面
    toChild(row,name){
      this.$router.push({name:name, query: {id: row.id}});
    },
    // 打卡侧边弹框
    openDrawer(row,type){
      this.row = row;
      this.addDrawer = true;
    },
    // 打开新增4G开关弹框
    open4GDrawer(row,type){
      this.row = row;
      this.row.btnType = type;
      this.add4GDrawer = true;
    },
    // 关闭弹框
    handleClose(){
      this.addDrawer = false;
      this.add4GDrawer = false;
      this.row = null;
      this.init();
    },
    // 打开、关闭开关
    changeStatus(e){
      this.$confirm('确认进行该操作吗？').then(async () => {
        const loading = this.$loading({
          lock: true,
          text: '操作中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let data = {
          param:{
            switchLock:e.status == 1 ? 0 : 1,
            wifiId:e.id,
          }
        }
        await lockSwitch(data).then(res=>{
          if(res.success && res.data.result){
            this.$message({
              message:'操作成功',
              type:'success'
            })
          }else{
            this.$message({
              message:'操作失败',
              type:'fail'
            })
          }
          this.init();
          loading.close();
        })
      })
    },
    // 查看4G开关通讯记录
    checkCommunicate(row){
      this.$router.push({path:'/inductorManage/switchCommuication',query:{id:row.id}})
    },
    // 删除开关
    delSwitch(row){
      this.$confirm('确认删除吗？').then(async () => {
        await delWifiSwitch({ param:row.id }).then(res=>{
          if(res.success){
            this.$message({
              message:'操作成功',
              type:'success'
            })
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
          this.init();
        })
      })
    },
    // 分页操作事件
    callFather(parm){
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped lang="scss"> 
/deep/ .el-table .row-expand-cover .cell .el-table__expand-icon {
  display: none;
}
.remind{
    color: red;
    text-align: center;
    margin-bottom: 20px;
}
</style>
