<!-- 道闸通行记录 -->
<template>
    <div class="outContainer">
      <div class="back">
        <i class="el-icon-back" @click="back"></i>
        <span>{{ this.$route.query.id }}</span>
      </div>
      <!-- 筛选条件栏 -->
      <div class="refreshContainer">
        <el-button size="mini" type="primary" icon="el-icon-refresh" @click="handleSearch">刷新</el-button>
      </div>
      <div class="tableContainer">
        <el-table
         :data="tableList"
         height="100%"
         style="width: 100%;">
          <el-table-column align="center" label="头盔识别照片" prop="imageList">
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.ossUrl"
                style="width: 100px; height: 100px"
                :src="scope.row.ossUrl"
                title="可点击放大查看"
                :preview-src-list="[scope.row.ossUrl]">
              </el-image>
              <div v-else>
                <p v-if="scope.row.errorType == 0">未开启头盔识别</p>
                <p v-else>--</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否允许通行" prop="errorTypeStr">
            <template slot-scope="scope">
              <p>{{ scope.row.errorTypeStr ? scope.row.errorTypeStr : '--' }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="消息ID" prop="msgId">
          </el-table-column>
          <el-table-column align="center" label="创建时间" prop="createDatetime">
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <el-col :span="24" class="toolbar pagination">
        <el-pagination
          background
          layout=" prev , next , jumper"
          @current-change="handleCurrentChange"
          :page-size="10"
          style="float:right;"
        ></el-pagination>
      </el-col>
    </div>
  </template>
  
  <script>
  import {getInfraredHelmetPassage} from '@/api/public.js';
  import {timeChange} from '@/api/publicFun.js';
  import Pagination from '@/components/Pagination.vue';
  export default {
    data() {
      return {
        searchInfo:{ // 筛选条件
          pageNo:1,
          pageSize:10,
          param:{
            deviceId:'',
          },
          total:0
        },
        tableList:[], // 数据表格列表
      }
    },
  
    components: {Pagination},
  
    computed: {},
  
    mounted() {
      this.searchInfo.param.deviceId = this.$route.query.id;
      this.init();
    },
  
    methods: {
      back(){
        this.$router.go(-1)
      },
      async init(){
        await getInfraredHelmetPassage(this.searchInfo).then(res=>{
          if(res.success){
            if( !res.data.list || res.data.list.length == 0){
              this.tableList = [];
              this.$message({
                message:'没有更多记录了',
                type:'remind'
              })
            }else{
              this.tableList = res.data.list;
              this.tableList.forEach(element => {
                element.createDatetime = timeChange(element.createDatetime,'seconds');
                element.scores = element.scores ? JSON.parse(element.scores) : [];
              });
            }
          }else{
            this.tableList = [];
          }
        })
        
      },
      // 条件筛选事件
      handleSearch(){
        this.searchInfo.pageNo = 1;
        this.init();
      },
      // 分页插件事件
      callFather(param){
        this.searchInfo.pageNo = param.currentPage;
        this.init();
      },
      handleCurrentChange(val){
        this.searchInfo.pageNo = val;
        this.init();
      }
    },
  };
  </script>
  <style scoped>
    .refreshContainer{
      display: flex;
      justify-content: flex-end;
      padding: 20px;
      padding-top: 0;
    }
  </style>
  