<template>
  <div id='rights'>
    <!-- 当前角色 -->
    <div class='select'>
      <span>{{ row.name }}</span>
      <el-button type="primary" @click='modefy'>保存</el-button>
    </div> 
    <!-- 树形控件 -->
    <el-tree
      :data="treeData"
      show-checkbox
      node-key="id"
      :default-expand-all='expand'
      :props="defaultProps"
      ref='tree'
      @check-change='nodeclick'>
    </el-tree>
  </div>
</template>

  <script>
  import { getTreeMenu,selectMenuByRoleId,createMenu } from '@/api/public.js';
  export default {
    props:['row'],
    data() {
      return {
        options: [],// 下拉框数据
        value: '',//下拉框双向绑定数据
        treeData: [],// 树形控件数据
        defaultProps: {
          children: 'childrenList',
          label: 'name',
        },
        checked:[],//选中节点数组
        check:[],
        expand:true,//树形结构默认展开
      }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
        this.init();
    },
  
    methods: {
      // 数据初始化
      async init(){
        // 获取树形结构节点数据
        await getTreeMenu().then(res=>{
          if(res.success){
            this.treeData=res.data;
          }
        });
        this.$nextTick(()=>{
          this.rights();
        })
      },
      resetChecked() {
        this.$refs.tree.setCheckedKeys([]);
      },
      setCheckedNodes(checked) {
        this.$refs.tree.setCheckedNodes(checked);
      },
      // 判断该角色现有的权限
      async rights(){
        let power;
        this.check=[];
        this.checked=[];
        this.resetChecked();
        this.$nextTick(async() => {
          await selectMenuByRoleId({param:this.row.id}).then(res=>{
            if(res.success){
              power=[...res.data];
              power.forEach(element => {
                if(element.childrenList && element.childrenList.length > 0){
                  element.childrenList.forEach(child=>{
                    this.checked.push(child.id);
                    const obj={
                      id:child.id,
                    }
                    this.check.push(obj);
                  })
                }
                const obj={
                  id:element.id,
                }
                this.check.push(obj);
                this.checked.push(element.id);
              });
              // 判断当前获取的权限中，如果父级下的子集并非全部选中的话，则需要让父级显示为非全选状态，即在this.checked中移除掉父级的权限ID
              this.treeData.forEach(value=>{
                if(value.childrenList && value.childrenList.length > 0){
                  for(let i=0;i<value.childrenList.length;i++){
                    if(this.checked.indexOf(value.childrenList[i].id) == -1 && this.checked.indexOf(value.id) !== -1){
                      let index = this.checked.indexOf(value.id);
                      this.checked.splice(index,1);
                      this.check.splice(index,1);
                      break;
                    }
                  }
                }else{

                }
              })
              this.$nextTick(()=>{
                this.checked=[...new Set(this.checked)];
                this.setCheckedNodes(this.check);
              })
            }
            
          });
        });
      },
      // 修改角色权限
      modefy(){
        this.$confirm('确认修改该角色的权限吗？').then( async () => {
          let data=[]; 
          this.checked.forEach((ele)=>{
            data.push({roleId:this.row.id,menuId:ele,actionType: JSON.stringify([1,2,3,4])});
          });
          data = JSON.stringify(data);
          await createMenu({param:data}).then(res=>{
            if(res.success){
              this.init();
              this.$message({
                message:'修改成功',
                type:'success'
              })
              setTimeout(() => {
                this.$emit('close');
              }, 1000);
              this.init();
            }
          });
        })
      },
      // 选择节点事件
      nodeclick(e,isSelect){
        if(isSelect){//如果是选中，则往选中数组中添加该节点
          this.checked.push(e.id)
          this.check.push({Id:e.id})
        }else{//如果是取消选中，则从选中数组中移除该节点
          for(let i=0;i<this.checked.length;i++){
            if(e.id===this.checked[i]){
              this.checked.splice(i,1);
              this.check.splice(i,1);
              --i;
            }
          }
        }
        setTimeout(() => {
          this.treeData.forEach(value=>{
            if(value.childrenList.length>0){
              value.childrenList.forEach(val=>{
                if(this.checked.indexOf(val.id) !== -1){
                  this.checked.push(value.id)
                }
              })
            }
            this.$forceUpdate()
          })
          let arr=[];
          this.checked.forEach(v=>{
            if(arr.indexOf(v)===-1){
              arr.push(v)
            }
          })
          this.checked=[...arr];
        }, 0);
      },
    },
  };
  </script>
  <style scoped lang="scss">
  #rights{
    width: 100%;
    background-color: white;
    padding: 10px 50px;
  }
  .select{
    display: flex;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    span {
      font-size: 18px;
      color: #1350d1;
      font-weight: 600;
    }
  }
  .select button{
    margin-left: 20px;
  }
  .el-popper ::v-deep .el-scrollbar{
    overflow-y:auto;
    height: 100%;
  }
  .el-tree{
    width: 200px !important;
    margin: 20px auto;
  }
  </style>
  