<template>
  <div class="outContainer">
    <div class="back">
      <i class="el-icon-back" @click="back"></i>
      <span>{{ carInfo.deviceId }} - {{ carInfo.plateNumber }}</span>
    </div>
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-select
        class="select each"
        v-model="searchInfo.param.type"
        clearable
        placeholder="通讯类型"
        @change="changeType"
      >
        <el-option
          v-for="item in communicationOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="tableContainer">
      <el-table :data="tableList" height="100%" style="width: 100%">
        <!-- 扩展信息 -->
        <el-table-column
          v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType == 1"
          type="expand"
          key="need"
        >
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="锁状态:">
                <p>
                  {{
                    props.row.blueLock == 0
                      ? '未启用'
                      : props.row.blueLock == 1
                      ? '解锁'
                      : props.row.blueLock == 2
                      ? '锁定'
                      : '--'
                  }}
                </p>
              </el-form-item>
              <el-form-item label="固件版本:">
                <p>
                  {{ props.row.firmVersion ? props.row.firmVersion : '--' }}
                </p>
              </el-form-item>
              <el-form-item label="最新版本号:">
                <p>{{ props.row.version ? props.row.version : '--' }}</p>
              </el-form-item>
              <el-form-item label="心跳频率:">
                <p>{{ props.row.frey }}</p>
              </el-form-item>
              <el-form-item label="过温温度:">
                <p>
                  {{ props.row.oheat }}℃ - {{ props.row.oheat2 }}℃ -
                  {{ props.row.oheat3 }}℃
                </p>
              </el-form-item>
              <el-form-item label="信号强度:">
                <p>{{ props.row.signal }}</p>
              </el-form-item>
              <el-form-item label="超速阈值:">
                <p>{{ props.row.speedM }}km/h</p>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="center" label="指令" prop="cmd">
        </el-table-column>
        <el-table-column align="center" label="执行状态" prop="ifpm">
          <template slot-scope="scope">
            <p>
              {{
                scope.row.ifpm == 1
                  ? '成功'
                  : scope.row.ifpm == 2
                  ? '失败'
                  : '--'
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="心跳次数"
          prop="index"
          v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType == 3"
        >
          <template slot-scope="scope">
            <p>
              {{
                scope.row.index || scope.row.index == 0 ? scope.row.index : '--'
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="心跳频率（秒 / 次）" prop="frey">
          <template slot-scope="scope">
            <p>
              {{
                scope.row.frey || scope.row.frey == 0 ? scope.row.frey : '--'
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="Mac"
          prop="mac"
          v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType == 2"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.mac ? scope.row.mac : '--' }}</p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="设备是否被拆"
          prop="dismantle"
          v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType != 1"
        >
          <template slot-scope="scope">
            <p v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType == 2">
              {{
                scope.row.dismantle == 1
                  ? '主板被拆'
                  : scope.row.dismantle == 2
                  ? '温感探头被拆'
                  : scope.row.dismantle == 3
                  ? '主板和温感探头都被拆'
                  : '未拆'
              }}
            </p>
            <p v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType == 4">
              {{
                scope.row.dismantle == 1
                  ? '主板被拆'
                  : scope.row.dismantle == 2
                  ? '外接电源被拆'
                  : scope.row.dismantle == 3
                  ? '主板和外接电源都被拆'
                  : '未拆'
              }}
            </p>
            <p v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType == 3">
              {{
                scope.row.dismantle == 0
                  ? '未拆'
                  : scope.row.dismantle == 1
                  ? '被拆'
                  : '--'
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="温度(℃)" prop="heat">
          <template slot-scope="scope">
            <div
              v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType != 4"
            >
              <p>温度一：{{ scope.row.heat ? scope.row.heat : '--' }}</p>
              <p
                v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType == 1"
              >
                温度二：{{ scope.row.heat2 ? scope.row.heat2 : '--' }}
              </p>
            </div>
            <div
              v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType == 4"
            >
              <p>
                是否过温：{{
                  scope.row.heat >= 80
                    ? '是'
                    : scope.row.heat < 80
                    ? '否'
                    : '--'
                }}
              </p>
              <p>电池温度：{{ scope.row.heat2 ? scope.row.heat2 : '--' }}</p>
              <p>芯片温度：{{ scope.row.heat3 ? scope.row.heat3 : '--' }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="版本"
          prop="version"
          v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType != 1"
        >
          <template slot-scope="scope">
            <p>软件版本：{{ scope.row.version ? scope.row.version : '--' }}</p>
            <p>
              固件版本：{{
                scope.row.firmVersion ? scope.row.firmVersion : '--'
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="通断电状态"
          prop="ifEnze"
          v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType == 1"
        >
          <template slot-scope="scope">
            <p>
              {{
                scope.row.ifEnze == 0
                  ? '通电'
                  : scope.row.ifEnze == 1
                  ? '断电'
                  : '--'
              }}
            </p>
            <p v-if="scope.row.ifEnze == 1">
              {{
                scope.row.outType == 1
                  ? '控制断电（服务器下发指令）'
                  : scope.row.outType == 2
                  ? '自主断电（设备自己判定断电）'
                  : '--'
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="摔倒状态"
          prop="ifFall"
          v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType == 1"
        >
          <template slot-scope="scope">
            <p>
              {{
                scope.row.ifFall == 0
                  ? '正常'
                  : scope.row.ifFall == 1
                  ? '摔倒'
                  : '--'
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="电瓶离车"
          prop="ifbatyle"
          v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType == 1"
        >
          <template slot-scope="scope">
            <p>
              {{
                scope.row.ifbatyle == 0
                  ? '正常'
                  : scope.row.ifbatyle == 1
                  ? '离车'
                  : '--'
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="车速(km/h)"
          prop="speed"
          v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType == 1"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.speed ? scope.row.speed : '--' }}</p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="GPS"
          prop="longitude"
          v-if="carInfo.bikeDevice && carInfo.bikeDevice.deviceType == 1"
        >
          <template slot-scope="scope">
            <p v-if="scope.row.longitude">
              {{ scope.row.longitude }},{{ scope.row.latitude }}
            </p>
            <p v-else>--</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createDatetime">
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <el-col :span="24" class="toolbar pagination">
      <el-pagination
        background
        layout=" prev , next , jumper"
        @prev-click="prevpage"
        @next-click="nextpage"
        @current-change="handleCurrentChange"
        :page-size="10"
        style="float: right"
      ></el-pagination>
    </el-col>
  </div>
</template>

<script>
import { getEBikeBycommunication, getEBikeById } from '@/api/public.js'
import { timeChange } from '@/api/publicFun.js'
export default {
  data() {
    return {
      searchInfo: {
        pageNo: 1,
        pageSize: 10,
        param: {
          deviceId: '',
          type: null,
        },
      },
      tableList: [],
      communicationOptions: [
        { value: 1, label: '心跳' },
        { value: 2, label: '电瓶离车' },
        { value: 3, label: '过温' },
        { value: 4, label: '温感掉落' },
        { value: 5, label: '摔倒' },
        { value: 6, label: '超速' },
        { value: 7, label: '主动获取GPS' },
        { value: 8, label: '主动控制通断电' },
        { value: 9, label: '主动更新过温温度' },
        { value: 10, label: '主动更新固件版本' },
        { value: 11, label: '主动重启设备' },
        { value: 12, label: '主动更新心跳频率' },
        { value: 13, label: '主动设置超速阈值' },
      ],
      carInfo: {
        bikeDevice: null,
      },
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.getCarInfo()
  },

  methods: {
    back() {
      this.$router.go(-1)
    },
    changeType(e) {
      this.searchInfo.pageNo = 1
      this.searchInfo.param.type = e && e != '' ? e : null
      this.init()
    },
    async init() {
      await getEBikeBycommunication(this.searchInfo).then(res => {
        if (res.success) {
          if (!res.data.list || res.data.list.length == 0) {
            this.tableList = []
            this.$message({
              message: '没有更多通讯记录了',
              type: 'remind',
            })
          } else {
            this.tableList = res.data.list
            this.tableList.forEach(element => {
              let eachTime = timeChange(element.createDatetime)
              element.createDatetime = `${eachTime.year}-${eachTime.month}-${eachTime.day}  ${eachTime.hour}:${eachTime.minutes}:${eachTime.seconds}`
            })
          }
        } else {
          this.tableList = []
        }
      })
    },
    async getCarInfo() {
      await getEBikeById({ param: this.$route.query.id }).then(res => {
        if (res.success) {
          this.carInfo = res.data
          this.searchInfo.param.deviceId = this.carInfo.deviceId
          this.init()
        }
      })
    },
    // 分页插件事件
    callFather(param) {
      this.searchInfo.pageNo = param.currentPage
      this.init()
    },
    // 上一页
    prevpage(val) {
      this.searchInfo.pageNo = val
      this.init()
    },
    // 下一页
    nextpage(val) {
      this.searchInfo.pageNo = val
      this.init()
    },
    handleCurrentChange(val) {
      this.searchInfo.pageNo = val
      this.init()
    },
  },
}
</script>
<style scoped></style>
