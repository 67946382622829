<template>
  <section class="outContainer">
    <!-- 返回 -->
    <div class="back">
        <i class="el-icon-back" @click="back"></i>
        <p>
            <span>报表统计记录</span>
            <b>报表数据统计的请求提交后，需要一定的计算时间，请耐心等待一段时间后再来查看</b>
        </p>
    </div>
    <div class="search">
        <el-select class='each' placeholder="统计类型" v-model="createReportData.param.reportType" @change="chooseUnit">
            <el-option
                v-for="item in reportTypeOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
        </el-select>
        <el-date-picker start-placeholder="开始时间" range-separator="至" end-placeholder="结束时间" v-model="orderTime"
         type="daterange"
         @change='chooseOrderTime'
         :picker-options="pickerOptions"
         class='timeSelect each'>
        </el-date-picker>
        <el-select class='each' placeholder="所属小区" v-model="createReportData.param.unitId" clearable @change="chooseUnit" v-if="createReportData.param.reportType == 2">
          <el-option
          v-for="item in unitOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
          </el-option>
        </el-select>
        <el-button class="mini" @click="handleAdd">根据条件段创建新报表</el-button>
        <el-button class="mini" @click="init" type="primary" size='mini'>刷新</el-button>
    </div>
    <div class="tableContainer">
        <el-table
         :data="tableList"
         height="100%"
         style="width: 100%;">
          <el-table-column align='center' label="开始时间" prop="startTimeStr">
          </el-table-column>
          <el-table-column align='center' label="结束时间" prop="endTimeStr">
          </el-table-column>
          <el-table-column align='center' label="统计进度" prop="statusName">
          </el-table-column>
          <el-table-column align='center' label="统计类型" prop="type">
            <template slot-scope="scope" >
                <p>{{ scope.row.reportType == 1 ? '全小区订单总报表' : '订单日报表' }}</p>
            </template>
          </el-table-column>
          <el-table-column align='center' label="统计小区" prop="type">
              <template slot-scope="scope" >
                  <p>{{ scope.row.unitName ? scope.row.unitName : '--' }}</p>
              </template>
          </el-table-column>
          <el-table-column align='center' label="创建时间" prop="createTimeStr">
          </el-table-column>
          <el-table-column align='center' label="操作" fixed="right">
            <template slot-scope="scope" >
              <el-button size="mini" @click="handleCheck(scope.row)" v-if="scope.row.status == 2">查看</el-button>
              <el-button size="mini" @click="handleEduce(scope.row)" v-if="scope.row.status == 2 && scope.row.reportType == 1">导出</el-button>
              <el-button size="mini" @click="handleDaily(scope.row)" v-if="scope.row.status == 2 && scope.row.reportType == 2">导出</el-button>
            </template>
          </el-table-column>
        </el-table>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
    <el-dialog :visible.sync="reportVisible" title="统计报表" width="90%">
        <!-- 全小区订单总报表统计 -->
        <el-table
         height="calc(100% - 24px)"
         border
         fit
         :data="unitReport"
         v-if="row && row.reportType == 1">
          <el-table-column align='center' label="小区名称" prop="unitName" fixed="left">
          </el-table-column>
          <el-table-column align='center' label="安装时间" prop="installTimeStr">
          </el-table-column>
          <el-table-column align='center' label="车位数量" prop="placeQuantity">
          </el-table-column>
          <el-table-column align='center' label="安装充电桩数 / 枪数" prop="chargingPileNumber">
            <template slot-scope="scope">
                {{ scope.row.chargingPileNumber + ' / ' + scope.row.chargingPilePortNumber }}
            </template>
          </el-table-column>
          <el-table-column align='center' label="投入成本（元）" prop="inputCosts">
          </el-table-column>
          <el-table-column align='center' label="订单数" prop="orderNumber">
          </el-table-column>
          <el-table-column align='center' label="预收（元）" prop="preAmount">
          </el-table-column>
          <el-table-column align='center' label="退款（元）" prop="refundAmount">
          </el-table-column>
          <el-table-column align='center' label="实收（元）" prop="actuallyAmount">
          </el-table-column>
          <el-table-column align='center' label="用电度数（度）" prop="energyUsed">
          </el-table-column>
          <el-table-column align='center' label="电费（元）" prop="electricityCharge">
          </el-table-column>
          <el-table-column align='center' label="服务费（元）" prop="serviceCharge">
          </el-table-column>
          <el-table-column align='center' label="分润占比（%）" prop="profitSharingRatio">
          </el-table-column>
          <el-table-column align='center' label="服务费分润（元）" prop="serviceChargeShareProfit">
          </el-table-column>
          <el-table-column align='center' label="服务费净收入（元）" prop="serviceChargeNetIncome">
          </el-table-column>
          <el-table-column align='center' label="单枪日收入（元）" prop="singlePortDailyIncome">
          </el-table-column>
        </el-table>
        <!-- 订单日报表统计 -->
        <el-table
         height="calc(100% - 24px)"
         border
         fit
         :data="unitReport"
         v-if="row && row.reportType == 2">
          <el-table-column align='center' label="日期" prop="target" fixed="left">
          </el-table-column>
          <el-table-column align='center' label="订单数" prop="orderNumber">
          </el-table-column>
          <el-table-column align='center' label="预收（元）" prop="preAmount">
          </el-table-column>
          <el-table-column align='center' label="退款（元）" prop="refundAmount">
          </el-table-column>
          <el-table-column align='center' label="实收（元）" prop="actuallyAmount">
          </el-table-column>
          <el-table-column align='center' label="用电度数（度）" prop="energyUsed">
          </el-table-column>
          <el-table-column align='center' label="电费（元）" prop="electricityCharge">
          </el-table-column>
          <el-table-column align='center' label="服务费（元）" prop="serviceCharge">
          </el-table-column>
          <el-table-column align='center' label="分润占比（%）" prop="profitSharingRatio">
          </el-table-column>
          <el-table-column align='center' label="服务费分润（元）" prop="serviceChargeShareProfit">
          </el-table-column>
          <el-table-column align='center' label="服务费净收入（元）" prop="serviceChargeNetIncome">
          </el-table-column>
        </el-table>
    </el-dialog>
  </section>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { createOperationReport,getOperationReport,getOperationReportInfo,getOperationDailyReportInfo,getNeighbourhoodList } from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
const XLSX = require("xlsx-js-style");
export default {
  data() {
    return {
        searchInfo:{
            pageNo:1,
            pageSize:10,
            total:0,
            param:{
                merchantId:null,
                type:2,
            },
        },
        tableList:[ // 表格数据
        ],
        unitReport:[], // 全小区统计报表表格数据
        reportVisible:false, // 控制全小区报表弹框是否显示
        row:null, // 当前操作数据
        orderTime:null, // 时间范围数据
        createReportData:{ // 创建报表参数
            param:{
                startTime:null,
                endTime:null,
                merchantId:null,
                type:2,
                reportType:1,
            }
        },
        unitOptions:[],
        reportTypeOptions:[
          {id:1,name:'全小区订单总报表统计'},
          {id:2,name:'订单日报表统计'},
        ],
        pickerOptions: { // 日期范围选择器，禁用今天及之后的日期
            disabledDate(time) {
                // 获取今天的零点时间戳
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return time.getTime() >= today.getTime(); // 禁用今天及之后的日期
            }
        }
    }
  },

  components: {
    Pagination
  },

  computed: {},

  mounted() {
    this.searchInfo.param.merchantId = this.$route.query ? this.$route.query.merchantId : null;
    this.createReportData.param.merchantId = this.$route.query ? this.$route.query.merchantId : null;
    this.init();
    this.getNieghbourList();
  },

  methods: {
    // 返回上一页handleDel
    back(){
        this.$router.go(-1);
    },
    // 获取小区列表
    getNieghbourList(){
      let data = {
        pageNo:1,
        pageSize:10000,
        param:{
          name:null,
          merchantId:this.createReportData.param.merchantId
        }
      }
      getNeighbourhoodList(data).then(res=>{
        if(res.success){
          this.unitOptions = res.data.list;
        }
      })
    },
    // 选择小区
    chooseUnit(){},
    // 获取创建记录
    async init(){
        await getOperationReport(this.searchInfo).then(res=>{
            if(res.success){
                this.tableList = res.data.list;
                this.searchInfo.total = res.data.total;
                this.tableList.forEach(element => {
                    element.createTimeStr = timeChange(element.createTime,'seconds');
                    element.startTimeStr = timeChange(element.startTime,'day');
                    element.endTimeStr = timeChange(element.endTime,'day');
                });
            }else{
                this.tableList = [];
                this.searchInfo.total = 0;
            }
        })
    },
    // 选择时间事件
    chooseOrderTime(e){
        this.createReportData.param.startTime = timeChange(e[0],'day');
        this.createReportData.param.endTime = timeChange(e[1],'day');
    },
    // 确认创建新报表
    handleAdd(){
        if(this.orderTime){
            this.$confirm('确认根据此时间范围创建新的全小区统计报表吗？').then( async () =>{
                await createOperationReport(this.createReportData).then(res=>{
                    this.$message({
                        message:res.success ? '创建成功，等待统计' : res.msg,
                        type:res.success ? 'success' : 'fail'
                    })
                    this.init();
                })
            })
        }else{
            this.$message({
                message:'请先选择时间范围',
                type:'warning'
            })
        }
    },
    // 查看
    async handleCheck(row){
        this.row = {...row};
        if(row.reportType == 1){
            await getOperationReportInfo({param:row.id}).then(res=>{
                if(res.success){
                    this.unitReport = res.data;
                    this.unitReport.forEach(element=>{
                        element.installTimeStr = element.installTime ? timeChange(element.installTime,'day') : '--';
                    })
                    this.reportVisible = true;
                }else{
                    this.$message({
                        message:res.msg,
                        type:fail,
                    })
                }
            })
        }else{
            await getOperationDailyReportInfo({param:row.id}).then(res=>{
                if(res.success){
                    this.unitReport = res.data;
                    this.unitReport.forEach(element=>{
                        element.installTimeStr = element.installTime ? timeChange(element.installTime,'day') : '--';
                    })
                    this.reportVisible = true;
                }else{
                    this.$message({
                        message:res.msg,
                        type:fail,
                    })
                }
            })
        }
    },
    // 导出
    handleEduce(row){
        this.$confirm('确认导出吗？').then(async ()=>{
            const loading = this.$loading({
                lock: true,
                text: '导出中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            await getOperationReportInfo({param:row.id}).then(res=>{
                let dataList = res.data;
                let data = [["小区名称","安装时间","车位数量","安装充电桩数 / 枪数","投入成本（元）","订单数","预收（元）","退款（元）","实收（元）","用电度数（度）","电费（元）","服务费（元）","分润占比（%）","服务费分润（元）","服务费净收入（元）","单枪日收入（元）"]];
                dataList.forEach(element=>{
                    let each = [];
                    each.push(element.unitName ? element.unitName : '--');
                    let installTimeStr = element.installTime ? timeChange(element.installTime,'day') : '--';
                    each.push(installTimeStr);
                    each.push(element.placeQuantity ? element.placeQuantity : 0);
                    each.push(element.chargingPileNumber + ' / ' + element.chargingPilePortNumber);
                    each.push(element.inputCosts ? element.inputCosts : 0);
                    each.push(element.orderNumber ? element.orderNumber : 0);
                    each.push(element.preAmount ? element.preAmount : 0);
                    each.push(element.refundAmount ? element.refundAmount : 0);
                    each.push(element.actuallyAmount ? element.actuallyAmount : 0);
                    each.push(element.energyUsed ? element.energyUsed : 0);
                    each.push(element.electricityCharge ? element.electricityCharge : 0);
                    each.push(element.serviceCharge ? element.serviceCharge : 0);
                    each.push(element.profitSharingRatio ? element.profitSharingRatio : 0);
                    each.push(element.serviceChargeShareProfit ? element.serviceChargeShareProfit : 0);
                    each.push(element.serviceChargeNetIncome ? element.serviceChargeNetIncome : 0);
                    each.push(element.singlePortDailyIncome ? element.singlePortDailyIncome : 0);
                    data.push(each);
                })
                // excel导出
                const sheet = XLSX.utils.aoa_to_sheet(data);
                const book = XLSX.utils.book_new();
                // 设置列宽为自适应
                sheet['!cols'] = [{ wch: 20 },{ wch: 20 },{ wch: 18 },{ wch: 23 },{ wch: 18 },{ wch: 18 },{ wch: 18 },{ wch: 18 },{ wch: 18 },{ wch: 19 },{ wch: 18 },{ wch: 18 },{ wch: 20 },{ wch: 20 },{wch: 22 },{ wch: 20 }];
                sheet['!rows'] = []; // 行高
                // 设置单元格样式
                for (let row = 0; row < data.length; row++) {
                    for (let col = 0; col < data[row].length; col++) {
                      sheet['!rows'].push({ hpx: 28 }) // 行高
                      const cellRef = XLSX.utils.encode_cell({ c: col, r: row });
                      sheet[cellRef].s = {
                          alignment: {
                            horizontal: 'center', // 水平居中
                            vertical: 'center',   // 垂直居中
                          },
                          font: {
                            bold: true // 加粗
                          }
                      };
                    }
                }
                XLSX.utils.book_append_sheet(book,sheet,"sheet1"); // 生成sheet
                XLSX.writeFile(book,`${timeChange(row.startTime,'day')} ~ ${timeChange(row.endTime,'day')}全小区经营报表.xlsx`); // 导出
                loading.close();
            })
        })
    },
    handleDaily(row){
        this.$confirm('确认导出吗？').then(async ()=>{
            const loading = this.$loading({
                lock: true,
                text: '导出中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            await getOperationDailyReportInfo({param:row.id}).then(res=>{
                let dataList = res.data;
                const data = [['日期',"订单数","预收（元）","退款（元）","实收（元）","用电度数（度）","电费（元）","服务费（元）","分润占比（%）","服务费分润（元）","服务费净收入（元）"]];
                dataList.forEach(element=>{
                    let each = [];
                    each.push(element.target);
                    each.push(element.orderNumber);
                    each.push(element.preAmount);
                    each.push(element.refundAmount);
                    each.push(element.actuallyAmount);
                    each.push(element.energyUsed);
                    each.push(element.electricityCharge);
                    each.push(element.serviceCharge);
                    each.push(element.profitSharingRatio);
                    each.push(element.serviceChargeShareProfit);
                    each.push(element.serviceChargeNetIncome);
                    data.push(each);
                })
                // excel导出
                const sheet = XLSX.utils.aoa_to_sheet(data);
                const book = XLSX.utils.book_new();
                // 设置列宽为自适应
                sheet['!cols'] = [{ wch: 20 },{ wch: 18 },{ wch: 18 },{ wch: 18 },{ wch: 18 },{ wch: 19 },{ wch: 18 },{ wch: 18 },{ wch: 19 },{ wch: 20 },{ wch: 20 }];
                sheet['!rows'] = []; // 行高
                // 设置单元格样式
                for (let row = 0; row < data.length; row++) {
                    for (let col = 0; col < data[row].length; col++) {
                      sheet['!rows'].push({ hpx: 28 }) // 行高
                      const cellRef = XLSX.utils.encode_cell({ c: col, r: row });
                      sheet[cellRef].s = {
                          alignment: {
                            horizontal: 'center', // 水平居中
                            vertical: 'center',   // 垂直居中
                          },
                          font: {
                            bold: true // 加粗
                          }
                      };
                    }
                }
                XLSX.utils.book_append_sheet(book,sheet,"sheet1"); // 生成sheet
                XLSX.writeFile(book,`${timeChange(row.startTime,'day')} ~ ${timeChange(row.endTime,'day')}日经营报表.xlsx`); // 导出
                loading.close();
            })
        })
    },
    // 分页操作事件
    callFather(parm){
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog{
    height: 80% !important;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .el-dialog__body{
        flex: 1;
    }
}
.back{
    p{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: end;
    }
    b{
        color: #6e6e6e;
        font-size: 14px;
        font-weight: 400;
    }
}
</style>
