<template>
  <div class="detail" >
    <el-form :model="detailInfo"  ref="ruleFormRef">
        <!-- 基本信息 -->
        <div class="basic">
          <p>基本信息：</p>
          <el-form-item label="检测日期:">
            <span>{{detailInfo.createTime ? detailInfo.createTime : '--'}}</span>
          </el-form-item>
          <el-form-item label="流水号:">
            <span>{{detailInfo.detectionNum ? detailInfo.detectionNum : '--'}}</span>
          </el-form-item>
          <el-form-item label="车牌号:">
            <span>{{detailInfo.licencePlate ? detailInfo.licencePlate : '--'}}</span>
          </el-form-item>
          <el-form-item label="车辆类型:">
            <span>{{detailInfo.carType ? detailInfo.carType : '--'}}</span>
          </el-form-item>
          <el-form-item label="整车编码：">
            <span>{{ detailInfo.vehicleNum ? detailInfo.vehicleNum : '--' }}</span>
          </el-form-item>
          <el-form-item label="品牌:">
            <span>{{detailInfo.brand ? detailInfo.brand : '--'}}</span>
          </el-form-item>
          <el-form-item label="蓄电池出厂日期:">
            <span>{{detailInfo.batteryFactoryTime ? detailInfo.batteryFactoryTime : '--'}}</span>
          </el-form-item>
          <el-form-item label="蓄电池类型:">
            <span>{{detailInfo.batteryType ? detailInfo.batteryType : '--'}}</span>
          </el-form-item>
          <el-form-item label="蓄电池品牌:">
            <span>{{detailInfo.batteryBrand ? detailInfo.batteryBrand : '--'}}</span>
          </el-form-item>
          <el-form-item label="蓄电池电压及容量:">
            <span>{{ detailInfo.batteryVoltageStandard ? detailInfo.batteryVoltageStandard + 'V' : '--' }}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>{{ detailInfo.batteryCapacity ? detailInfo.batteryCapacity + 'ah' : '--' }}</span>
          </el-form-item>
          <el-form-item label="车辆所有人:">
            <span>{{detailInfo.carMaster ? detailInfo.carMaster : '--'}}</span>
          </el-form-item>
          <el-form-item label="联系方式:">
            <span>{{detailInfo.carMasterPhone ? detailInfo.carMasterPhone : '--'}}</span>
          </el-form-item>
        </div>
        <!-- 车体外观 -->
        <div class="look"> 
            <p>车体外观：</p> 
            <el-form-item label="电动机编码:">
              <span>{{detailInfo.motorCode ? detailInfo.motorCode : '--'}}</span>
            </el-form-item>
            <el-form-item label="合格证信息码:">
              <span>{{detailInfo.certificateCode == 1 ? '有' : ( detailInfo.certificateCode == 2 ? '无' : '--')}}</span>
            </el-form-item>
            <el-form-item label="铭牌信息是否完整：">
              <span>{{detailInfo.nameplateInfoIsFull == 1 ? '是' : ( detailInfo.nameplateInfoIsFull == 2 ? '否' : '--')}}</span>
            </el-form-item>
            <el-form-item label="车牌有无脱落隐患：">
              <span>{{detailInfo.licencePlatIsFallOff == 1 ? '无':(detailInfo.licencePlatIsFallOff == 2 ? '有' : '--')}}</span>
            </el-form-item>
            <el-form-item label="车体宽度、鞍座长度是否合格：">
              <span>{{detailInfo.widthLengthIsStandard == 1 ? '是' : ( detailInfo.widthLengthIsStandard == 2 ? '否' : '--')}}</span>
            </el-form-item>
            <el-form-item label="前后轮是否出现变形：">
              <span>{{detailInfo.wheelIsAmorphosis == 1 ? '否':(detailInfo.wheelIsAmorphosis == 2 ? '是':'--')}}</span>
            </el-form-item>
        </div>
        <!-- 车速、制动及整车质量 -->
        <div class="insulation">
            <p>车速、制动及整车质量：</p>
            <el-form-item label="是否安装脚蹬：">
              <span>{{detailInfo.pedalIsInstall == 1 ? '是' : (detailInfo.pedalIsInstall == 2 ? '否' : '--')}}</span>
            </el-form-item>
            <el-form-item label="速度篡改：">
              <span>{{detailInfo.speedIsChange == 1? '否' : ( detailInfo.speedIsChange == 2 ? '是' : '--' )}}</span>
            </el-form-item>
            <el-form-item label="最高车速(km/h)：">
              <span>{{detailInfo.topSpeed ? detailInfo.topSpeed : '--'}}</span>
            </el-form-item>
            <el-form-item label="车速提示音：">
              <span>{{detailInfo.speedPromptTone == 1 ? '有' : ( detailInfo.speedPromptTone == 2 ? '无' : '--' )}}</span>
            </el-form-item>
            <el-form-item label="前轴制动(%)：">
              <span>{{detailInfo.frontAxleBrake ? detailInfo.frontAxleBrake : '--' }}</span>
            </el-form-item>
            <el-form-item label="后轴制动(%)：">
              <span>{{ detailInfo.rearAxleBrake ? detailInfo.rearAxleBrake : '--' }}</span>
            </el-form-item>
            <el-form-item label="前轴重量(KG)：">
              <span>{{detailInfo.frontAxleWeight ? detailInfo.frontAxleWeight : '--' }}</span>
            </el-form-item>
            <el-form-item label="后轴重量(KG)：">
              <span>{{ detailInfo.rearAxleWeight ? detailInfo.rearAxleWeight : '--' }}</span>
            </el-form-item>
            <el-form-item label="整车质量（KG）：">
              <span>{{ detailInfo.vehicleMass ? detailInfo.vehicleMass : '--' }}</span>
            </el-form-item>
        </div>
        <!-- 线路情况 -->
        <div class="insulation">
            <p>线路情况：</p>
            <el-form-item label="短路保护：">
              <span>{{detailInfo.shortCircuitProtect == 1 ? '有' : ( detailInfo.shortCircuitProtect == 2 ? '无' : '--' )}}</span>
            </el-form-item>
            <el-form-item label="线路是否改装、老化、脱落、漏电、裸露：">
              <span>{{detailInfo.lineProblem == 1 ? '否' : ( detailInfo.lineProblem == 2 ? '是' : '--')}}</span>
            </el-form-item>
            <el-form-item label="电池连接线布线是否合理：">
              <span>{{detailInfo.wiringIsRational == 1 ? '是' : ( detailInfo.wiringIsRational == 2 ? '否' : '--' )}}</span>
            </el-form-item>
        </div>
        <!-- 电池信息 -->
        <div class="btyInfo">
          <p>电池信息：</p>
          <el-form-item label="是否安装指定类型蓄电池：">
            <span>{{detailInfo.installRightBattery == 1 ? '是' : ( detailInfo.installRightBattery == 2 ? '否' : '--' )}}</span>
          </el-form-item>
          <el-form-item label="是否有充鼓、漏液、变形：">
            <span>{{detailInfo.batteryProblem == 1 ? '否' : ( detailInfo.batteryProblem == 2 ? '是' : '--' )}}</span>
          </el-form-item>
          <el-form-item label="电压（V）：">
            <span>{{detailInfo.batteryVoltage ? detailInfo.batteryVoltage : '--'}}</span>
          </el-form-item>
          <el-form-item label="蓄电池安装情况是否防篡改：">
            <span>{{detailInfo.batteryTamperProof == 1 ? '是' : ( detailInfo.batteryTamperProo == 2 ? '否' : '--' )}}</span>
          </el-form-item>
        </div>
        <!-- 充电器 -->
        <div class="batry">
            <p>充电器：</p>
            <el-form-item label="充电器与电池是否匹配：">
              <span>{{detailInfo.chargerMatcheBattery == 1 ? '是' : ( detailInfo.chargerMatcheBattery == 2 ? '否' : '--')}}</span>
            </el-form-item>
            <el-form-item label="充电器的风扇是否正常工作：">
              <span>{{detailInfo.fanIsWork == 1 ? '是' : (detailInfo.fanIsWork == 2 ? '否' : '--' )}}</span>
            </el-form-item>
            <el-form-item label="充电器电压(V):">
              <span>{{ detailInfo.chargerVoltage ? detailInfo.chargerVoltage : '--' }}</span>
            </el-form-item>
            <el-form-item label="充电器电流(A):">
              <span>{{ detailInfo.chargerElectricity ? detailInfo.chargerElectricity : '--' }}</span>
            </el-form-item>
        </div>
        <!-- 铅酸电池内阻特殊检测 -->
        <div class="btyInfo">
          <p>铅酸电池内阻特殊检测：</p>
          <el-form-item label="一号电池(mΩ):">
            <span>{{ detailInfo.batteryOne ? detailInfo.batteryOne : '--'  }}</span>
          </el-form-item>
          <el-form-item label="二号电池(mΩ):">
            <span>{{ detailInfo.batteryTwo ? detailInfo.batteryTwo : '--'  }}</span>
          </el-form-item>
          <el-form-item label="三号电池(mΩ):">
            <span>{{ detailInfo.batteryThree ? detailInfo.batteryThree : '--'  }}</span>
          </el-form-item>
          <el-form-item label="四号电池(mΩ):">
            <span>{{ detailInfo.batteryFour ? detailInfo.batteryFour : '--'  }}</span>
          </el-form-item>
          <el-form-item label="五号电池(mΩ):">
            <span>{{ detailInfo.batteryFive ? detailInfo.batteryFive : '--'  }}</span>
          </el-form-item>
          <el-form-item label="六号电池(mΩ):">
            <span>{{ detailInfo.batterySix ? detailInfo.batterySix : '--'  }}</span>
          </el-form-item>
        </div>
        <!-- 备注 -->
        <div class="look">
          <p>问题项：</p>
          <div class="problemList">
            <p v-for="(item,index) in problemList" :key="index" class="problemItem">{{ item }}</p>
            <p v-if="problemList.length == 0">暂无问题项</p>
          </div>
        </div>
    </el-form>
  </div>
</template>

<script>
import {getDetectionDetail,getDictionaryByType} from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
export default {
  props:{row:{type:Object}},
  data() {
    return {
        Id:'',
        detailInfo:{},
        otherInfo:{},
        styleone:'color:#fff;background:#28c24d',
        styletwo:'color:#000;background:#fff',
        isLa:true,
        showleaveFactoryTime:false,
        showbatteryFactoryTime:false,
        isShellCharged:'',
        batteryValue:[ // 电池内阻计算数据
			    {name:'batteryOne',nameStr:'一号电池'},
			    {name:'batteryTwo',nameStr:'二号电池'},
			    {name:'batteryThree',nameStr:'三号电池'},
			    {name:'batteryFour',nameStr:'四号电池'},
			    {name:'batteryFive',nameStr:'五号电池'},
			    {name:'batterySix',nameStr:'六号电池'},
			  ],
				batteryNumber:[ // 铅酸电池特殊检测不同电池容量对应计算参数 type：蓄电池容量 value：标准值
			    { type:12,value:14.4 },
			    { type:14,value:13.6 },
			    { type:20,value:10.6 },
			    { type:24,value:9.8 },
			    { type:32,value:8.6 },
			  ],
			  chargerElectricityNumber:[ // 充电器电压计算 不同电压对应计算参数 type：蓄电池电压 max：最大值 min：最小值
					{ type:48,max:59,min:42 },
					{ type:60,max:74,min:52 },
				],
				batteryVoltageNumber:[ // 电池电压计算 不同电压对应计算参数 type：蓄电池电压 max：最大值 min：最小值
					{ type:48,max:56,min:42 },
					{ type:60,max:72,min:56 },
				],
        detectionItem:[ // 检测项列表 label检测项中文 value表单对象对应属性 type输入框类型 inputType文子输入框类型 disabled是否禁用 relation选择框关联渲染数据 error选择框选择不合格时的问题项信息
					{ // 基本信息
						title:'基本信息',
						list:[
							{label:'车牌号',value:'licencePlate',type:'input',inputType:'text',disabled:true,relation:''},
							{label:'品牌',value:'brand',type:'input',inputType:'text',disabled:true,relation:''},
							{label:'车辆所有人',value:'carMaster',type:'input',inputType:'text',disabled:true,relation:''},
							{label:'联系方式',value:'carMasterPhone',type:'input',inputType:'text',disabled:true,relation:''},
							{label:'流水号',value:'detectionNum',type:'input',inputType:'digit',disabled:false,relation:''},
							{label:'整车编码',value:'vehicleNum',type:'input',inputType:'text',disabled:false,relation:''},
							{label:'蓄电池出厂日期',value:'batteryFactoryTime',type:'datetime',disabled:false,relation:''},
							{label:'蓄电池类型',value:'batteryType',type:'checkbox',disabled:false,relation:'batteryTypeOption',error:''},
							{label:'蓄电池品牌',value:'batteryBrand',type:'select',disabled:false,relation:'batteryBrandOption'},
							{label:'蓄电池电压',value:'batteryVoltageStandard',type:'checkbox',disabled:false,relation:'batteryVoltageStandardOption',error:''},
							{label:'蓄电池容量',value:'batteryCapacity',type:'checkbox',disabled:false,relation:'batteryCapacityOption',error:''},
						]
					},
					{ // 车体外观
						title:'车体外观',
						list:[
							{label:'电动机编码',value:'motorCode',type:'input',inputType:'text',disabled:false,relation:''},
							{label:'合格证信息码',value:'certificateCode',type:'checkbox',disabled:false,relation:'hasOrNot',error:'车辆无合格证信息;'},
							{label:'铭牌信息是否完整',value:'nameplateInfoIsFull',type:'checkbox',disabled:false,relation:"yesOrNo",error:'铭牌信息不完整;'},
							{label:'车牌有无脱落隐患',value:'licencePlatIsFallOff',type:'checkbox',disabled:false,relation:'notOrHas',error:'车牌有脱落隐患;'},
							{label:'车体宽度、鞍座长度是否合格',value:'widthLengthIsStandard',type:'checkbox',disabled:false,relation:'yesOrNo',error:'车体宽度、鞍座长度不合格;'},
							{label:'前后轮是否出现变形',value:'wheelIsAmorphosis',type:'checkbox',disabled:false,relation:'noOrYes',error:"前后轮出现变形;"},
						]
					},
					{ // 车速、制动及整车质量
						title:'车速、制动及整车质量',
						list:[
							{label:'是否安装脚蹬',value:'pedalIsInstall',type:'checkbox',disabled:false,relation:'yesOrNo',error:"未安装脚踏;"},
							{label:'是否有车速提示音',value:'speedPromptTone',type:'checkbox',disabled:false,relation:'yesOrNo',error:"无车速提示音;"},
							{label:'最高车速（km/h）',value:'topSpeed',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
							{label:'前轴重（kg）',value:'frontAxleWeight',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
							{label:'前轴制动（%）',value:'frontAxleBrake',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
							{label:'后轴重（kg）',value:'rearAxleWeight',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
							{label:'后轴制动（%）',value:'rearAxleBrake',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
							{label:'整车质量（kg）',value:'vehicleMass',type:'input',inputType:'digit',disabled:true,relation:'',error:""},
						]
					},
					{ // 线路情况
						title:'线路情况',
						list:[
							{label:'短路保护',value:'shortCircuitProtect',type:'checkbox',disabled:false,relation:'hasOrNot',error:"无短路保护;"},
							{label:'线路是否改装、老化、脱落、裸露',value:'lineProblem',type:'checkbox',disabled:false,relation:'noOrYes',error:"线路改装、老化、脱落、裸露;"},
							{label:'电池连接线布线是否合理',value:'wiringIsRational',type:'checkbox',disabled:false,relation:'yesOrNo',error:"电池连接线布线不合理;"},
						]
					},
					{ // 电池信息
						title:'电池信息',
						list:[
							{label:'是否安装指定类型蓄电池',value:'installRightBattery',type:'checkbox',disabled:false,relation:'yesOrNo',error:"未安装指定类型蓄电池;"},
							{label:'是否有充鼓、漏液、变形',value:'batteryProblem',type:'checkbox',disabled:false,relation:'noOrYes',error:"电池存在充鼓、漏液、变形;"},
							{label:'蓄电池安装情况是否防篡改',value:'batteryTamperProof',type:'checkbox',disabled:false,relation:'yesOrNo',error:"蓄电池安装无防篡改;"},
							{label:'电压（V）',value:'batteryVoltage',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
						]
					},
					{ // 充电器
						title:'充电器',
						list:[
							{label:'充电器与电池是否匹配',value:'chargerMatcheBattery',type:'checkbox',disabled:false,relation:'yesOrNo',error:"充电器与电池不匹配;"},
							{label:'充电器的风扇是否正常工作',value:'fanIsWork',type:'checkbox',disabled:false,relation:'yesOrNo',error:"充电器风扇未正常工作;"},
							{label:'充电器电压(V)',value:'chargerVoltage',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
							{label:'充电器电流(A)',value:'chargerElectricity',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
						]
					},
					{ // 铅酸电池内阻特殊检测
						title:'铅酸电池内阻特殊检测',
						list:[
							{label:'一号电池(mΩ)',value:'batteryOne',type:'input',inputType:'digit',disabled:false,relation:''},
							{label:'二号电池(mΩ)',value:'batteryTwo',type:'input',inputType:'digit',disabled:false,relation:''},
							{label:'三号电池(mΩ)',value:'batteryThree',type:'input',inputType:'digit',disabled:false,relation:''},
							{label:'四号电池(mΩ)',value:'batteryFour',type:'input',inputType:'digit',disabled:false,relation:''},
							{label:'五号电池(mΩ)',value:'batteryFive',type:'input',inputType:'digit',disabled:false,relation:''},
							{label:'六号电池(mΩ)',value:'batterySix',type:'input',inputType:'digit',disabled:false,relation:''},
							
						]
					},
				],
        problemList:[],
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init()
  },

  methods: {
    // 获取电池品牌选项
    async getBrands(){
     await getDictionaryByType({type:'BATTERY_TYPE'}).then(res=>{
         if(res.success){
           for(let i=0;i<res.data.length;i++){
             if(res.data[i].id == this.detailInfo.batteryBrand){
                 this.detailInfo.batteryBrand = res.data[i].dicValue;
             }
           }
         }else{
           this.batteryBrands = [];
         }
     })
    },
    // 初始化
    async init(){
      let data = {
          param:this.row.detectionId,
        }
      await getDetectionDetail(data).then(res=>{
        if(res.success){
            this.detailInfo=res.data;
            this.getBrands();
            let batteryFactoryTime = timeChange(this.detailInfo.batteryFactoryTime);
            this.detailInfo.batteryFactoryTime = `${batteryFactoryTime.year}-${batteryFactoryTime.month}-${batteryFactoryTime.day}`
            let createTime = timeChange(this.detailInfo.createTime);
            this.detailInfo.createTime = `${createTime.year}-${createTime.month}-${createTime.day}`;
            this.detailInfo.batteryType = this.detailInfo.batteryType == 0 ? '--' : ( this.detailInfo.batteryType == 1 ? '铅酸电池' : '锂电池');
            this.otherInfo = this.detailInfo;
            this.detailInfo.remarks = this.detailInfo.remarks ? this.detailInfo.remarks : '';
            if(!this.detailInfo.frontAxleWeight || !this.detailInfo.rearAxleWeight){
              this.detailInfo.vehicleMass = null;
            }else{
              this.detailInfo.vehicleMass = (Number(this.detailInfo.frontAxleWeight) + Number(this.detailInfo.rearAxleWeight)).toFixed(2);
            }
            this.createProblems();
        }
      });
    },
    // 生成问题项
	  createProblems(){
		    this.problemList = [];
		    // 单选框选择不好的一项时,自动填充问题项
		    this.detectionItem.forEach(element=>{
		    	element.list.forEach(ele=>{
		    		if(ele.type == 'checkbox' && this.detailInfo[ele.value] == 2){
		    			this.problemList.push(ele.error);
		    		}
		    	})
		    })
		    this.speedWeightJudge();
		    this.brakeJudge();
		    this.batteryVoltageStandardJudge();
		    this.chargerJudge();
        this.internalResistance();
	  },
    // 铅酸内阻检测计算
	  internalResistance(){
      for(let i=0;i<this.batteryNumber.length;i++){
          if(this.detailInfo.batteryCapacity == this.batteryNumber[i].type){
              this.batteryValue.forEach(element => {
                let percent = Number(this.detailInfo[element.name]) ? (this.batteryNumber[i].value / Number(this.detailInfo[element.name])*100).toFixed(2) : null;
                this.detailInfo[element.label] = percent;
                if(percent < 45 && percent){
                  this.problemList.push(`${element.nameStr}建议更换：${percent}%`);
                }else if(45 <= percent && percent < 60 && percent){
                  this.problemList.push(`${element.nameStr}需注意：${percent}%`);
                }
              })
              break;
          }else{
              this.batteryValue.forEach(element=>{
                  this.detailInfo[element.nameStr] = '';
              })
          }
      }
	  },
	  // 车速、重量判断
	  speedWeightJudge(){
      // 车速
      if(this.detailInfo.topSpeed && Number(this.detailInfo.topSpeed) > 25){
          this.problemList.push("车速超过25千米每小时;");
          Number(this.detailInfo.topSpeed) > 26 ? this.problemList.push("速度篡改;") : "";
      }
      // 整车质量
      if(this.detailInfo.vehicleMass && Number(this.detailInfo.vehicleMass) > 55){
        this.problemList.push("整车质量过重;");
      }
	  },
	  // 制动判断
	  brakeJudge(){
      if(this.detailInfo.frontAxleBrake){
					if(Number(this.detailInfo.frontAxleBrake) < 60){
						this.problemList.push(`前轴制动过低：${Number(this.detailInfo.frontAxleBrake).toFixed(2)}%`);
					}
				}
				if(this.detailInfo.rearAxleBrake){
					if(Number(this.detailInfo.rearAxleBrake) < 60){
						this.problemList.push(`后轴制动过低：${Number(this.detailInfo.rearAxleBrake).toFixed(2)}%`);
					}
				}
	  },
	  // 电池电压判断
	  batteryVoltageStandardJudge(){
      if(this.detailInfo.batteryVoltage != 0 && this.detailInfo.batteryVoltage){
        for(let i=0;i<this.batteryVoltageNumber.length;i++){
          if(this.detailInfo.batteryVoltageStandard == this.batteryVoltageNumber[i].type){
            if(Number(this.detailInfo.batteryVoltage) > this.batteryVoltageNumber[i].max){
              this.problemList.push("电池电压过高;");
            }else if(Number(this.detailInfo.batteryVoltage) < this.batteryVoltageNumber[i].min){
              this.problemList.push("电池电压过低;");
            }
            break;
          }
        }
      }
	  },
	  // 充电器判断
	  chargerJudge(){
      // 充电器电流
      if(this.detailInfo.chargerElectricity != 0 && this.detailInfo.chargerElectricity){
        if(Number(this.detailInfo.chargerElectricity) > this.chargeElectricStandard + 0.5){
          this.problemList.push("充电器电流过高;");
        }else if(Number(this.detailInfo.chargerElectricity) < this.chargeElectricStandard - 0.5){
          this.problemList.push("充电器电流过低;");
        }
      }
      // 充电器电压
      if(this.detailInfo.chargerVoltage && this.detailInfo.chargerVoltage != 0){
        for(let i=0;i<this.chargerElectricityNumber.length;i++){
          if(this.detailInfo.batteryVoltageStandard == this.chargerElectricityNumber[i].type){
            if(Number(this.detailInfo.chargerVoltage) > this.chargerElectricityNumber[i].max){
              this.problemList.push("充电器电压过高;");
            }else if(Number(this.detailInfo.chargerVoltage) < this.chargerElectricityNumber[i].min){
              this.problemList.push("充电器电压过低;");
            }
            break;
          }
        }
      }
	  },
  },
};
</script>
<style scoped lang='scss'>
.problemList{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
  .problemItem{
      width: 50%;
      font-size: 16px;
      box-sizing: border-box;
      color: #740505!important;
      padding-left: 10px;
      line-height: 25px;
      margin: 0;
    }
}
  /deep/ .el-drawer__body{
   overflow:auto !important;
 }
.detail{
    padding: 30px;
}
.battery{
    display: flex;
    flex-direction: column;
}
.otherInfo,.basic,.btyInfo,.look,.insulation,.electric,.speed,.powerDevice,.batry,.protect,.charge{
  display: flex;
  flex-wrap: wrap;
  p{
    width: 100%;
    margin-bottom: 30px;
    font-size: 18px;
    text-align: left;
    color: #000;
    font-weight: 700;
    margin: 20px 0;
  }
  span{
    color: #000 !important;
  }
  .el-form-item{
    text-align: center;
    width:calc(100% / 3);
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    #el-id-9334-0{
        width: 140px;
    }
    .el-input{
        flex: 1;
    }
    span{
        color: red;
    }
  }
}
.el-form{
    .tab{
        text-align: left;
        span{
            display: inline-block;
            border-radius: 5px;
            border: solid 1px #ccc;
            width: 70px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            &:hover{
                cursor: pointer;
            }
        }
    }
    
}

</style>
