<template>
  <section>
    <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
      <el-form-item label="是否为双向感应" prop="isTwo">
        <div>
          <el-radio-group v-model="form.isTwo" @change="chooseIsTwo">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="主设备编号" prop="id">
        <el-input placeholder="请输入主设备编号" v-model="form.id" :disabled="row.id ? true : false"></el-input>
      </el-form-item>
      <el-form-item label="副设备编号" prop="auxiliaryInductorId" v-if="form.isTwo">
        <el-input placeholder="请输入副设备编号" v-model="form.auxiliaryInductorId" :disabled="row.id ? true : false"></el-input>
      </el-form-item>
      <el-form-item label="主 → 副" prop="id" v-if="form.isTwo">
        <div>
          <el-radio-group v-model="form.directionType">
            <el-radio :label="0">进入</el-radio>
            <el-radio :label="1">离开</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="小区内具体安装位置" prop="adders">
        <el-input placeholder="请填写具体安装位置" v-model="form.adders"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="handleSubmit('form')" :plain='true'>提交</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { createInductor,updateInductor } from "@/api/public.js";
export default {
  props:['row'], // 父组件携带参数
  data() {
    return {
      form:{ // 表单数据
        unitId:'',
        adders: "",
        id: "",
        type: "",
        merchantId:"",
        way:'',
      },
      open: false, // 选择地址弹框是否显示
      rules: { // 表单验证
        id:[
          { required: true, message: '设备编号不得为空', trigger: 'blur'}
        ],
        unitId:[
          { required:true,message:'安装小区不得为空',trigger:"blur"}
        ],
        adders:[
          { required:true,message:'安装位置不得为空',trigger:"blur"}
        ],
        auxiliaryInductorId:[
          { required:true,message:'副设备编号不得为空',trigger:"blur"}
        ],
        directionType:[
          { required:true,message:'请选择感应方向',trigger:"blur"}
        ],
        isTwo:[
          { required:true,message:'请选择是否为双向感应',trigger:"blur"}
        ],
      },
      userInfo:JSON.parse(localStorage.getItem('userInfo')),
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.form = this.row ? {...this.row} : this.form;
    if(this.row.auxiliaryInductor){
      this.form.isTwo = true;
      this.form.auxiliaryInductorId = this.row.auxiliaryInductor.id;
    }else if(this.row.mainId){
      this.form.isTwo = true;
      this.form.id = this.row.mainId;
      this.form.auxiliaryInductorId = this.row.id;
    }else{
      this.form.isTwo = false;
    }
  },

  methods: {
    // 关闭弹窗
    close(form){
       this.$emit('close');
    },
    chooseIsTwo(){
      this.form = {...this.form}
    },
    // 新增
    async add(){
      await createInductor({param:this.form}).then((res) => {
        if (res.success) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          setTimeout(() => {
            this.$emit("close");
          }, 1000);
        }else{
          this.$message({
            message: res.msg,
            type: "fail",
          });
        }
      })
    },
    // 编辑
    async edit(){
      await updateInductor({param:this.form}).then((res) => {
        if (res.success) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          setTimeout(() => {
            this.$emit("close");
          }, 1000);
        }else{
          this.$message({
            message: res.msg,
            type: "fail",
          });
        }
      })
    },
    // 提交
    handleSubmit(form) {
      if(this.userInfo.roleLevel >= 10){
        this.form.unitId = this.userInfo.unitId;
      }
       this.$refs["form"].validate(valid => {
          if (valid) {
              this.$confirm('确认提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(()=>{
                if(this.row.id){
                  this.edit();
                }else{
                  this.add();
                }
              })
          }else{
            return false;
          }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
// 防止地图自动完成的对象被遮挡
.tangram-suggestion {
    z-index: 9999 !important;
  }
 .el-drawer{
   overflow:auto !important;
 }
 /deep/ .el-form-item__content{
  display: flex;
 }
 .map{
  border: 1px solid #dcdfe6;
  background: #fff;
  border-radius: 4px;
  width: 80px;
 }
 .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow:hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
