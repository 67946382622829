<template>
  <div class="detail" >
    <div class="back">
      <i class="el-icon-back" @click="back"></i>
      <h2>电动车检测数据</h2>
    </div>
    <el-form :model="detailInfo" ref="ruleFormRef" :rules="rules">
      <!-- 表单项 -->
      <div v-for="(item,index) in detectionItem" :key="index">
        <div class="look" v-if="item.title !='铅酸电池内阻特殊检测' || detailInfo.batteryType == 1">
          <p>{{ item.title }}</p>
          <el-form-item v-for="(ite,ind) in item.list" :key="ind" :label="ite.label" :prop="ite.value">
            <!-- 文本输入框 -->
            <el-input v-if="ite.type == 'input'" :type="ite.inputType" v-model="detailInfo[ite.value]" :placeholder="'请输入'+ite.label" :disabled='ite.disabled'  @input="handleInput(ite.inputType,ite.value)"/>
            <!-- 日期选择器 -->
            <el-date-picker v-if="ite.type == 'datetime'" type="date" v-model="detailInfo[ite.value]"/>
            <!-- 单选框 -->
            <div v-if="ite.type == 'checkbox'" style="display: flex;align-items: center;">
              <el-radio-group v-model="detailInfo[ite.value]">
                <el-radio v-for="(each) in allOptions[ite.relation]" :key="each.value" :label="each.value">{{ each.text }}</el-radio>
              </el-radio-group>
              <i class="el-icon-circle-close" @click="cancelChoose(ite)" v-if="detailInfo[ite.value]"></i>
            </div>
            <!-- 下拉选择器 -->
            <el-select v-if="ite.type == 'select'" v-model="detailInfo[ite.value]" placeholder="请选择">
              <el-option
               v-for="each in allOptions[ite.relation]"
               :key="each.id"
               :label="each.dicValue"
               :value="each.id" />
            </el-select>
          </el-form-item>
        </div>
      </div>
      
      <!-- 问题项 -->
      <div class="look">
        <p>问题项：</p>
        <div class="problemList">
          <p v-for="(item,index) in problemList" :key="index" class="problemItem">{{ item }}</p>
          <p v-if="problemList.length == 0">暂无问题项</p>
        </div>
      </div>
      <!-- 提交 -->
      <el-form-item>
        <el-button type="primary" @click="onSubmit('form')">提交</el-button>
      </el-form-item>
    </el-form>
    <!-- 提示 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="40%">
      <span>车辆未进行检测，请先进行检测再补充数据</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="back">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDetectionDetail,addDetection,getDictionaryByType,createDetection } from '@/api/public.js';
export default {
  data() {
    // 表单关联验证是否选择了蓄电池容量
    let checkBatteryCapacity = (rule, value, callback) => {
      if(value && !this.detailInfo.batteryCapacity){
        callback('请先选择基本信息中的蓄电池容量');
      }else{
        callback();
      }
    };
    // 表单关联验证是否选择了蓄电池电压
    let checkBatteryVoltageStandard = (rule, value, callback) => {
      if(value && !this.detailInfo.batteryVoltageStandard){
        callback('请先选择基本信息中的蓄电池电压');
      }else{
        callback();
      }
    };
    return {
        detailInfo:{ // 详细信息
          detectionNum:"",
          licencePlate:"",
          carType:"两轮车",
          brand:"",
          vehicleNum:"",
          batteryType:0,
          batteryBrand:0,
          batteryFactoryTime:"",
          batteryVoltageStandard:0,
          batteryCapacity:0,
          carMaster:"",
          carMasterPhone:"",
          motorCode:"",
          certificateCode:0,
          nameplateInfoIsFull:0,
          licencePlatIsFallOff:0,
          widthLengthIsStandard:0,
          wheelIsAmorphosis:0,
          pedalIsInstall:0,
          speedIsChange:0,
          speedPromptTone:0,
          frontAxleBrake:"",
          rearAxleBrake:"",
          frontAxleWeight:"",
          rearAxleWeight:"",
          vehicleMass:0,
          shortCircuitProtect:0,
          lineProblem:0,
          wiringIsRational:0,
          installRightBattery:0,
          batteryProblem:0,
          batteryVoltage:"",
          batteryTamperProof:0,
          chargerMatcheBattery:0,
          fanIsWork:0,
          chargerVoltage:"",
          chargerElectricity:"",
          remarks:"",
          batteryOne:"",
					batteryTwo:"",
					batteryThree:"",
					batteryFour:"",
					batteryFive:"",
					batterySix:"",
        },
        batteryBrands:[], // 电池品牌选项
        dialogVisible:false, // 异常弹框是否显示
        allOptions:{ // 所有select、checkbox选择器的选项数据
					batteryTypeOption:[ // 蓄电池类型选项
						{value:1,text:'铅酸电池'},
						{value:2,text:'锂电池'},
					],
					batteryVoltageStandardOption:[ // 蓄电池标准电压选项
						{value:"48",text:'48V'},
						{value:"60",text:'60V'},
					],
					batteryCapacityOption:[ // 蓄电池容量选项
						{value:12,text:'12ah'},
						{value:14,text:'14ah'},
						{value:20,text:'20ah'},
						{value:24,text:'24ah'},
						{value:32,text:'32ah'},
					],
					yesOrNo:[ // 是否选项
						{value:1,text:'是'},
						{value:2,text:'否'},
					],
					noOrYes:[ // 否是选项
					    {value:2,text:'是'},
						{value:1,text:'否'},
					],
					hasOrNot:[ // 有无选项
						{value:1,text:'有'},
						{value:2,text:'无'},
					],
					notOrHas:[ // 无有选项
					    {value:2,text:'有'},
						{value:1,text:'无'},
					],
					batteryBrandOption:[] // 蓄电池品牌
				},
        batteryValue:[ // 电池内阻计算数据
			    {name:'batteryOne',nameStr:'一号电池'},
			    {name:'batteryTwo',nameStr:'二号电池'},
			    {name:'batteryThree',nameStr:'三号电池'},
			    {name:'batteryFour',nameStr:'四号电池'},
			    {name:'batteryFive',nameStr:'五号电池'},
			    {name:'batterySix',nameStr:'六号电池'},
			  ],
				batteryNumber:[ // 铅酸电池特殊检测不同电池容量对应计算参数 type 蓄电池容量值 value 计算标准值
			    { type:12,value:14.4 },
			    { type:14,value:13.6 },
			    { type:20,value:10.6 },
			    { type:24,value:9.8 },
			    { type:32,value:8.6 },
			  ],
			  chargerElectricityNumber:[ // 充电器电压计算 不同电压对应计算参数 type：蓄电池电压 max：最大值 min：最小值
					{ type:48,max:59,min:42 },
					{ type:60,max:74,min:52 },
				],
				batteryVoltageNumber:[ // 电池电压计算 不同电压对应计算参数 type：蓄电池电压 max：最大值 min：最小值
					{ type:48,max:56,min:42 },
					{ type:60,max:72,min:56 },
				],
        detectionItem:[ // 检测项列表 label检测项中文 value表单对象对应属性 type输入框类型 inputType文子输入框类型 disabled是否禁用 relation选择框关联渲染数据 error选择框选择不合格时的问题项信息
					{ // 基本信息
						title:'基本信息',
						list:[
							{label:'车牌号',value:'licencePlate',type:'input',inputType:'text',disabled:true,relation:''},
							{label:'品牌',value:'brand',type:'input',inputType:'text',disabled:true,relation:''},
							{label:'车辆所有人',value:'carMaster',type:'input',inputType:'text',disabled:true,relation:''},
							{label:'联系方式',value:'carMasterPhone',type:'input',inputType:'text',disabled:true,relation:''},
							{label:'流水号',value:'detectionNum',type:'input',inputType:'number',disabled:false,relation:''},
							{label:'整车编码',value:'vehicleNum',type:'input',inputType:'text',disabled:false,relation:''},
							{label:'蓄电池出厂日期',value:'batteryFactoryTime',type:'datetime',disabled:false,relation:''},
							{label:'蓄电池类型',value:'batteryType',type:'checkbox',disabled:false,relation:'batteryTypeOption',error:''},
							{label:'蓄电池品牌',value:'batteryBrand',type:'select',disabled:false,relation:'batteryBrandOption'},
							{label:'蓄电池电压',value:'batteryVoltageStandard',type:'checkbox',disabled:false,relation:'batteryVoltageStandardOption',error:''},
							{label:'蓄电池容量',value:'batteryCapacity',type:'checkbox',disabled:false,relation:'batteryCapacityOption',error:''},
						]
					},
					{ // 车体外观
						title:'车体外观',
						list:[
							{label:'电动机编码',value:'motorCode',type:'input',inputType:'text',disabled:false,relation:''},
							{label:'合格证信息码',value:'certificateCode',type:'checkbox',disabled:false,relation:'hasOrNot',error:'车辆无合格证信息;'},
							{label:'铭牌信息是否完整',value:'nameplateInfoIsFull',type:'checkbox',disabled:false,relation:"yesOrNo",error:'铭牌信息不完整;'},
							{label:'车牌有无脱落隐患',value:'licencePlatIsFallOff',type:'checkbox',disabled:false,relation:'notOrHas',error:'车牌有脱落隐患;'},
							{label:'车体宽度、鞍座长度是否合格',value:'widthLengthIsStandard',type:'checkbox',disabled:false,relation:'yesOrNo',error:'车体宽度、鞍座长度不合格;'},
							{label:'前后轮是否出现变形',value:'wheelIsAmorphosis',type:'checkbox',disabled:false,relation:'noOrYes',error:"前后轮出现变形;"},
						]
					},
					{ // 车速、制动及整车质量
						title:'车速、制动及整车质量',
						list:[
							{label:'是否安装脚蹬',value:'pedalIsInstall',type:'checkbox',disabled:false,relation:'yesOrNo',error:"未安装脚踏;"},
							{label:'是否有车速提示音',value:'speedPromptTone',type:'checkbox',disabled:false,relation:'yesOrNo',error:"无车速提示音;"},
							{label:'最高车速（km/h）',value:'topSpeed',type:'input',inputType:'number',disabled:false,relation:'',error:""},
							{label:'前轴重（kg）',value:'frontAxleWeight',type:'input',inputType:'number',disabled:false,relation:'',error:""},
							{label:'前轴制动（%）',value:'frontAxleBrake',type:'input',inputType:'number',disabled:false,relation:'',error:""},
							{label:'后轴重（kg）',value:'rearAxleWeight',type:'input',inputType:'number',disabled:false,relation:'',error:""},
							{label:'后轴制动（%）',value:'rearAxleBrake',type:'input',inputType:'number',disabled:false,relation:'',error:""},
							{label:'整车质量（kg）',value:'vehicleMass',type:'input',inputType:'number',disabled:true,relation:'',error:""},
						]
					},
					{ // 线路情况
						title:'线路情况',
						list:[
							{label:'短路保护',value:'shortCircuitProtect',type:'checkbox',disabled:false,relation:'hasOrNot',error:"无短路保护;"},
							{label:'线路是否改装、老化、脱落、裸露',value:'lineProblem',type:'checkbox',disabled:false,relation:'noOrYes',error:"线路改装、老化、脱落、裸露;"},
							{label:'电池连接线布线是否合理',value:'wiringIsRational',type:'checkbox',disabled:false,relation:'yesOrNo',error:"电池连接线布线不合理;"},
						]
					},
					{ // 电池信息
						title:'电池信息',
						list:[
							{label:'是否安装指定类型蓄电池',value:'installRightBattery',type:'checkbox',disabled:false,relation:'yesOrNo',error:"未安装指定类型蓄电池;"},
							{label:'是否有充鼓、漏液、变形',value:'batteryProblem',type:'checkbox',disabled:false,relation:'noOrYes',error:"电池存在充鼓、漏液、变形;"},
							{label:'蓄电池安装情况是否防篡改',value:'batteryTamperProof',type:'checkbox',disabled:false,relation:'yesOrNo',error:"蓄电池安装无防篡改;"},
							{label:'电压（V）',value:'batteryVoltage',type:'input',inputType:'number',disabled:false,relation:'',error:""},
						]
					},
					{ // 充电器
						title:'充电器',
						list:[
							{label:'充电器与电池是否匹配',value:'chargerMatcheBattery',type:'checkbox',disabled:false,relation:'yesOrNo',error:"充电器与电池不匹配;"},
							{label:'充电器的风扇是否正常工作',value:'fanIsWork',type:'checkbox',disabled:false,relation:'yesOrNo',error:"充电器风扇未正常工作;"},
							{label:'充电器电压(V)',value:'chargerVoltage',type:'input',inputType:'number',disabled:false,relation:'',error:""},
							{label:'充电器电流(A)',value:'chargerElectricity',type:'input',inputType:'number',disabled:false,relation:'',error:""},
						]
					},
					{ // 铅酸电池内阻特殊检测
						title:'铅酸电池内阻特殊检测',
						list:[
							{label:'一号电池(mΩ)',value:'batteryOne',type:'input',inputType:'number',disabled:false,relation:''},
							{label:'二号电池(mΩ)',value:'batteryTwo',type:'input',inputType:'number',disabled:false,relation:''},
							{label:'三号电池(mΩ)',value:'batteryThree',type:'input',inputType:'number',disabled:false,relation:''},
							{label:'四号电池(mΩ)',value:'batteryFour',type:'input',inputType:'number',disabled:false,relation:''},
							{label:'五号电池(mΩ)',value:'batteryFive',type:'input',inputType:'number',disabled:false,relation:''},
							{label:'六号电池(mΩ)',value:'batterySix',type:'input',inputType:'number',disabled:false,relation:''},
							
						]
					},
				],
        problemList:[], // 问题项
        rules:{ // 表单验证
          batteryOne:[
            { validator: checkBatteryCapacity, trigger: 'blur' }
          ],
          batteryTwo:[
            { validator: checkBatteryCapacity, trigger: 'blur' }
          ],
          batteryThree:[
            { validator: checkBatteryCapacity, trigger: 'blur' }
          ],
          batteryFour:[
            { validator: checkBatteryCapacity, trigger: 'blur' }
          ],
          batteryFive:[
            { validator: checkBatteryCapacity, trigger: 'blur' }
          ],
          batterySix:[
            { validator: checkBatteryCapacity, trigger: 'blur' }
          ],
          chargerElectricity:[
            { validator: checkBatteryCapacity, trigger: 'blur' }
          ],
          batteryVoltage:[
            { validator: checkBatteryVoltageStandard, trigger: 'blur' }
          ],
          chargerVoltage:[
            { validator: checkBatteryVoltageStandard, trigger: 'blur' }
          ],
        }
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.detailInfo.appointmentRecordId = this.$route.query.appointId;
    this.init();
    this.getBrands();
  },

  watch:{
		// 实时监测表单内容是否发生变化，同步问题项
		detailInfo:{
			handler(newVal, oldVal) {
				this.createProblems();
			},
			deep: true // 深度监听对象内部属性的变化
		}
	},

  methods: {
    // 返回
    back(){
      this.dialogVisible = false;
      this.$router.go(-1);
    },
    /**
     * input输入框类型为 number时，将字符串类型的数字转化为数字类型的数字
     * @param {*String} type 输入框类型
     * @param {*String} value 表单对象对应的属性
     */
    handleInput(type,value) {
      if(type == 'number'){
        this.detailInfo[value] = this.detailInfo[value] ? parseFloat(this.detailInfo[value]) : "";
        if(value == "frontAxleWeight" || value == "rearAxleWeight"){
          if(!this.detailInfo.frontAxleWeight || !this.detailInfo.rearAxleWeight){
            this.detailInfo.vehicleMass = null;
          }else{
            this.detailInfo.vehicleMass = (Number(this.detailInfo.frontAxleWeight) + Number(this.detailInfo.rearAxleWeight)).toFixed(2);
          }
        }
      }
    },
    // 获取电池品牌选项
    async getBrands(){
      await getDictionaryByType({type:'BATTERY_TYPE'}).then(res=>{
        if(res.success){
          this.allOptions.batteryBrandOption = res.data;
        }else{
          this.allOptions.batteryBrandOption = [];
        }
      })
    },
    // 生成问题项
		createProblems(){
			this.problemList = [];
			// 单选框选择不好的一项时,自动填充问题项
			this.detectionItem.forEach(element=>{
				element.list.forEach(ele=>{
					if(ele.type == 'checkbox' && this.detailInfo[ele.value] == 2){
						this.problemList.push(ele.error);
					}
				})
			});
			this.speedWeightJudge();
			this.brakeJudge();
			this.batteryVoltageStandardJudge();
			this.chargerJudge();
      this.internalResistance();
		},
    // 铅酸内阻检测计算,只有当蓄电池类型选择为 铅酸电池时才检测
		internalResistance(){
      if(this.detailInfo.batteryType == 1){
        for(let i=0;i<this.batteryNumber.length;i++){
				    if(this.detailInfo.batteryCapacity == this.batteryNumber[i].type){
				        this.batteryValue.forEach(element => {
							    let percent = this.detailInfo[element.name] ? (this.batteryNumber[i].value / Number(this.detailInfo[element.name])*100).toFixed(2) : null;
							    if(percent < 45 && percent){
							    	this.problemList.push(`${element.nameStr}建议更换：${percent}%`);
							    }else if(45 <= percent && percent < 60 && percent){
							    	this.problemList.push(`${element.nameStr}需注意：${percent}%`);
							    }
				        })
				        break;
				    }else{
				        this.batteryValue.forEach(element=>{
				            this.detailInfo[element.nameStr] = '';
				        })
				    }
				}
      }else{
        this.batteryValue.forEach(element=>{
            this.detailInfo[element.name] = '';
				})
      }
		},
		// 车速、重量判断
		speedWeightJudge(){
				// 车速
				if(this.detailInfo.topSpeed && Number(this.detailInfo.topSpeed) > 25){
				    this.problemList.push("车速超过25千米每小时;");
            Number(this.detailInfo.topSpeed) > 26 ? this.problemList.push("速度篡改;") : "";
				}
				// 整车质量
				if(this.detailInfo.vehicleMass && Number(this.detailInfo.vehicleMass) > 55){
					this.problemList.push("整车质量过重;");
				}
		},
		// 制动判断
		brakeJudge(){
				if(this.detailInfo.frontAxleBrake){
					if(Number(this.detailInfo.frontAxleBrake) < 60){
						this.problemList.push(`前轴制动过低：${Number(this.detailInfo.frontAxleBrake).toFixed(2)}%`);
					}
				}
				if(this.detailInfo.rearAxleBrake){
					if(Number(this.detailInfo.rearAxleBrake) < 60){
						this.problemList.push(`后轴制动过低：${Number(this.detailInfo.rearAxleBrake).toFixed(2)}%`);
					}
				}
		},
		// 电池电压判断
		batteryVoltageStandardJudge(){
        // 如果蓄电池电压选择了，并且实际电池电压也测量并填写，则将实际电压与蓄电池标准电压对应的范围进行比较，在范围内则正常，否则添加进问题项
				if(this.detailInfo.batteryVoltage != 0 && this.detailInfo.batteryVoltage){
					for(let i=0;i<this.batteryVoltageNumber.length;i++){
						if(this.detailInfo.batteryVoltageStandard == this.batteryVoltageNumber[i].type){
							if(Number(this.detailInfo.batteryVoltage) > this.batteryVoltageNumber[i].max){
								this.problemList.push("电池电压过高;");
							}else if(Number(this.detailInfo.batteryVoltage) < this.batteryVoltageNumber[i].min){
								this.problemList.push("电池电压过低;");
							}
							break;
						}
					}
				}
		},
		// 充电器判断
		chargerJudge(){
				let chargeElectricStandard = Number(this.detailInfo.batteryCapacity) / 10 ; // 标准：蓄电池容量 / 10
				// 充电器电流
				if(this.detailInfo.chargerElectricity != 0 && this.detailInfo.chargerElectricity){
					if(Number(this.detailInfo.chargerElectricity) > chargeElectricStandard + 0.5){
						this.problemList.push("充电器电流过高;");
					}else if(Number(this.detailInfo.chargerElectricity) < chargeElectricStandard - 0.5){
						this.problemList.push("充电器电流过低;");
					}
				}
				// 充电器电压
				if(this.detailInfo.chargerVoltage && this.detailInfo.chargerVoltage != 0){
					for(let i=0;i<this.chargerElectricityNumber.length;i++){
						if(this.detailInfo.batteryVoltageStandard == this.chargerElectricityNumber[i].type){
							if(Number(this.detailInfo.chargerVoltage) > this.chargerElectricityNumber[i].max){
								this.problemList.push("充电器电压过高;");
							}else if(Number(this.detailInfo.chargerVoltage) < this.chargerElectricityNumber[i].min){
								this.problemList.push("充电器电压过低;");
							}
							break;
						}
					}
				}
		},
    // 取消选择框选择事件
    cancelChoose(item){
      this.detailInfo[item.value] = 0;
    },
    // 初始化
    async init(){
        let data = {
          param:this.$route.query.detectionId,
        }
        await getDetectionDetail(data).then(res=>{
          if(res.success){
              this.detailInfo = res.data ? res.data : this.detailInfo;
              this.detailInfo.remarks = this.detailInfo.remarks ? this.detailInfo.remarks : '';
              this.detailInfo.batteryBrand = Number(this.detailInfo.batteryBrand);
              this.detailInfo.batteryType = Number(this.detailInfo.batteryType );
              if(!this.detailInfo.frontAxleWeight || !this.detailInfo.rearAxleWeight){
                this.detailInfo.vehicleMass = null;
              }else{
                this.detailInfo.vehicleMass = (Number(this.detailInfo.frontAxleWeight) + Number(this.detailInfo.rearAxleWeight)).toFixed(2);
              }
          }else{
            this.dialogVisible = true;
          }
        })
    },
    // 提交
    async onSubmit(){
      console.log(this.detailInfo,999)
      this.detailInfo.speedIsChange = this.detailInfo.topSpeed ? (this.detailInfo.topSpeed > 26 ? 2 : 1) : 0 ;
      this.detailInfo.carType = "两轮车";
      for(let key in this.detailInfo){
          if(this.detailInfo[key] == null){
            this.detailInfo[key] = '';
          }
      }
      this.$refs['ruleFormRef'].validate( async valid =>{
        if(valid){
          await addDetection({ param:this.detailInfo }).then(res=>{
            if(res.success){
              this.$message({
                message: '提交成功',
                type: 'success',
              })
              this.$router.go(-1);
            }else{
              this.$message({
                message: res.msg,
                type: 'fail',
              })
            } 
          })
        }
      })
    },
  },
};
</script>
<style scoped lang='scss'>
.el-icon-circle-close{
  color: red;
  cursor: pointer;
}
.problemList{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
  .problemItem{
      width: 50%;
      font-size: 16px;
      box-sizing: border-box;
      color: #740505!important;
      padding-left: 10px;
      line-height: 25px;
      margin-bottom: 0;
    }
}
.el-icon-back{
  float: left;
  font-size: 30px;
  cursor: pointer;
  margin-bottom: 20px;
}
p{
  text-align: left !important;
  font-weight: 600 !important;
}
.detail{
    padding: 30px;
}
.el-form{
    div{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .basic{
        .el-form-item{
            width: 25%; 
            display: flex;
            align-items:center;
            padding: 10px;
            box-sizing: border-box;
            #el-id-9334-0{
                width: 140px;
            }
            .el-input{
                flex: 1;
            }
            span{
                color: red;
            }
        }
    }
    .btyInfo{
        .el-form-item{
            width: 30%; 
            display: flex;
            align-items: center;
            padding: 10px;
            box-sizing: border-box;
            #el-id-9334-0{
                width: 140px;
            }
            .el-input{
                flex: 1;
            }
            span{
                color: red;
            }
        }
    }
    .leadAcid,.lithium{
        .batry{
            display: block;
            div{
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
            }
            .el-form-item{
                padding: 10px 30px 10px 10px;
            }
        }
    }
    .look,.insulation,.electric,.speed,.powerDevice,.protect,.charge,.result{
        .el-form-item{
            padding: 10px 100px 10px 10px;
        }
    }
    .battery{
        .tab{
          margin-bottom: 20px;
        }
        span{
            display: inline-block;
            border-radius: 5px;
            border: solid 1px #ccc;
            width: 70px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            &:hover{
                cursor: pointer;
            }
        }
    }
    p{
        width: 100%;
        margin-bottom: 30px;
        font-size: 18px;
    }
    /deep/ .el-form .look .el-form-item[data-v-5994880f], .el-form .insulation .el-form-item[data-v-5994880f], .el-form .electric .el-form-item[data-v-5994880f], .el-form .speed .el-form-item[data-v-5994880f], .el-form .powerDevice .el-form-item[data-v-5994880f], .el-form .protect .el-form-item[data-v-5994880f], .el-form .charge .el-form-item[data-v-5994880f], .el-form .result .el-form-item[data-v-5994880f]{
      align-items:center !important;
    }
    /deep/ .el-form-item__content{
      flex: 1 !important;
    }
    /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: auto !important;
    }
    .el-radio{
        margin-right: 8px;
    }
    .el-button{
        background: #28c24d;
        color:#fff;
        margin: 0 auto;
        border: 0;
    }
}

</style>
