<template>
    <div class="PRINT">
      <div class="back">
        <i class="el-icon-back" @click="back"></i>
      </div>
      <el-button type='primary' @click="print">打印</el-button>
      <div id="printTest">
          <h1>电动自行车检验报告表{{detailInfo.detectionNum}}</h1>
          <div class="basic">
              <h6>一、基本信息</h6>
              <p class="dislodge"><span>车牌号</span><span>{{detailInfo.licencePlate}}</span></p>
              <p><span>车辆类型及品牌</span><span>{{detailInfo.carType}}（{{detailInfo.brand}}）</span></p>
              <p class="dislodge"><span>整车编码</span><span>{{detailInfo.vehicleNum}}</span></p>
              <p><span>蓄电池类型及品牌</span><span>{{ detailInfo.batteryBrand != '其他' ? detailInfo.batteryBrand : '其他品牌'+ detailInfo.batteryType }}</span></p>
              <p class="dislodge"><span>蓄电池出厂日期及容量</span><span>{{detailInfo.batteryFactoryTime?detailInfo.batteryFactoryTime:''}} - {{detailInfo.batteryCapacity}}ah</span></p>
              <p><span>车辆所有人及联系方式</span><span>{{detailInfo.carMaster}} - {{ detailInfo.carMasterPhone }}</span></p>
          </div>
          <!-- 检测结果 -->
          <div class="result">
              <h6 style="border-bottom: solid #000 1px;">二、检测项</h6>
              <div class="detail">
                <div class="title">
                    <span>项目名称</span><span>检测结果</span><span>建议参考数值</span>
                </div>
                <!-- 车体外观 -->
                <div class="categoryDetail">
                    <div class="subTitle" style="display: flex;justify-content: center;align-items: center;">
                        <span>车体外观</span>
                    </div>
                    <div class="content">
                        <div>
                            <p class="text">电动机编码</p>
                            <p class="isOk">
                                {{ detailInfo.motorCode ? detailInfo.motorCode : '' }}
                            </p>
                            <p class="describe"></p>
                        </div>
                        <div>
                            <p class="text">合格证信息码</p>
                            <p class="isOk">
                                {{ detailInfo.certificateCode == 1 ? '有' : (detailInfo.certificateCode == 2 ? '无' : '') }}
                            </p>
                            <p class="describe"></p>
                        </div>
                        <div>
                            <p class="text">铭牌信息是否完整</p>
                            <p class="isOk">
                                {{ detailInfo.nameplateInfoIsFull == 1 ? '是' : (detailInfo.nameplateInfoIsFull == 2 ? '否' : '') }}
                            </p>
                            <p class="describe"></p>
                        </div>
                        <div>
                            <p class="text">车牌有无脱落隐患</p>
                            <p class="isOk">
                                {{ detailInfo.licencePlatIsFallOff == 1 ? '无' : (detailInfo.licencePlatIsFallOff == 2 ? '有' : '') }}
                            </p>
                            <p class="describe"></p>
                        </div>
                        <div>
                            <p class="text">车体宽度、鞍座长度是否合格</p>
                            <p class="isOk">
                                {{ detailInfo.widthLengthIsStandard == 1 ? '是' : (detailInfo.widthLengthIsStandard == 2 ? '否' : '') }}
                            </p>
                            <p class="describe">车体宽度≤450mm；鞍座长度≤350mm</p>
                        </div>
                        <div>
                            <p class="text">前后轮是否出现变形</p>
                            <p class="isOk">
                                {{detailInfo.wheelIsAmorphosis == 1 ? '否': (detailInfo.wheelIsAmorphosis == 2 ? '是' : '')}}
                            </p>
                            <p class="describe"></p>
                        </div>
                    </div>
                </div>
                <!-- 制动和车速 -->
                <div class="categoryDetail">
                        <div class="subTitle">
                            车速、制动及整车质量
                        </div>
                        <div class="content">
                            <div>
                                <p class="text">是否安装脚踏</p>
                                <p class="isOk">
                                    {{ detailInfo.pedalIsInstall == 1 ? '是' : (detailInfo.pedalIsInstall == 2 ? '否' : '未检测') }}
                                </p>
                                <p class="describe"> </p>
                            </div>
                            <div>
                                <p class="text">速度篡改</p>
                                <p class="isOk">
                                    {{ detailInfo.speedIsChange == 1 ? '否' : (detailInfo.speedIsChange == 2 ? '是' : '未检测') }}
                                </p>
                                <p class="describe"></p>
                            </div>
                            <div>
                                <p class="text">最高车速(km/h)</p>
                                <p class="isOk">
                                    {{detailInfo.topSpeed ? detailInfo.topSpeed : '未检测'}}
                                    <span class="remind">{{ detailInfo.topSpeed && detailInfo.topSpeed > 25 ? "↑" : '' }}</span>
                                </p>
                                <p class="describe">25</p>
                            </div>
                            <div>
                                <p class="text">车速提示音</p>
                                <p class="isOk">
                                    {{ detailInfo.speedPromptTone == 1 ? '有' : (detailInfo.speedPromptTone == 2 ? '无' : '未检测') }}
                                </p>
                                <p class="describe"></p>
                            </div>
                            <div>
                                <p class="text">前轴制动判定</p>
                                <p class="isOk">
                                    {{detailInfo.frontAxleBrake ? Number(detailInfo.frontAxleBrake).toFixed(2)+'%' : '未检测'}}
                                    <span class="remind">{{ detailInfo.frontAxleBrake && Number(detailInfo.frontAxleBrake) < 60 ?  '↓' : '' }}</span>
                                </p>
                                <p class="describe">
                                    ≥60%
                                </p>
                            </div>
                            <div>
                                <p class="text">后轴制动判定</p>
                                <p class="isOk">
                                    {{detailInfo.rearAxleBrake ?  Number(detailInfo.rearAxleBrake).toFixed(2)+'%' : '未检测'}}
                                    <span class="remind">{{ detailInfo.rearAxleBrake && Number(detailInfo.rearAxleBrake) < 60 ?  '↓' : '' }}</span>
                                </p>
                                <p class="describe"> ≥60% </p>
                            </div>
                            <div>
                                <p class="text">整车质量(KG)</p>
                                <p class="isOk">
                                   {{detailInfo.vehicleMass ? detailInfo.vehicleMass : '未检测'}}
                                   <span class="remind">{{ detailInfo.vehicleMass && detailInfo.vehicleMass > 55 ? "↑" : '' }}</span>
                                </p>
                                <p class="describe">55</p>
                            </div>
                        </div>
                </div>
                <!-- 线路 -->
                <div class="categoryDetail">
                        <div class="subTitle">
                            线路情况
                        </div>
                        <div class="content">
                            <div>
                                <p class="text">短路保护</p>
                                <p class="isOk">
                                    {{ detailInfo.shortCircuitProtect == 1 ? '有' : (detailInfo.shortCircuitProtect == 2 ? '无' : '未检测') }}
                                </p>
                                <p class="describe"></p>
                            </div>
                            <div>
                                <p class="text">线路是否改装、老化、脱落、漏电、裸露</p>
                                <p class="isOk">
                                    {{ detailInfo.lineProblem == 1 ? '否' : (detailInfo.lineProblem == 2 ? '是' : '未检测') }}
                                </p>
                                <p class="describe"> </p>
                            </div>
                            <div>
                                <p class="text">电池连接线布线是否合理</p>
                                <p class="isOk">
                                    {{ detailInfo.wiringIsRational == 1 ? '是' : (detailInfo.wiringIsRational == 2 ? '否' : '未检测') }}
                                </p>
                                <p class="describe"> </p>
                            </div>
                        </div>
                </div>
                <!-- 电池 -->
                <div class="categoryDetail">
                        <div class="subTitle">
                            电池
                        </div>
                        <div class="content">
                            <div>
                                <p class="text">是否安装指定类型蓄电池</p>
                                <p class="isOk">
                                    {{ detailInfo.installRightBattery == 1 ? '是' : (detailInfo.installRightBattery == 2 ? '否' : '未检测') }}
                                </p>
                                <p class="describe"></p>
                            </div>
                            <div>
                                <p class="text">是否有充鼓、漏液、变形</p>
                                <p class="isOk">
                                    {{ detailInfo.batteryProblem == 1 ? '否' : (detailInfo.batteryProblem == 2 ? '是' : '未检测') }}
                                </p>
                                <p class="describe"></p>
                            </div>
                            <div>
                                <p class="text">电压(V)</p>
                                <p class="isOk">
                                    {{detailInfo.batteryVoltage ? detailInfo.batteryVoltage : '未检测'}}
                                    <span class="remind" v-if="detailInfo.batteryVoltage && detailInfo.batteryVoltageStandard == 48">
                                        {{ Number(detailInfo.batteryVoltage) > 56 ? "↑" : ( Number(detailInfo.batteryVoltage) < 42 ? '↓' : '')}}
                                    </span>
                                    <span class="remind" v-if="detailInfo.batteryVoltage && detailInfo.batteryVoltageStandard == 60">
                                        {{ Number(detailInfo.batteryVoltage) > 72 ? "↑" : ( Number(detailInfo.batteryVoltage) < 52 ? '↓' : '')}}
                                    </span>
                                </p>
                                <p class="describe">
                                    {{ detailInfo.batteryVoltageStandard ? (detailInfo.batteryVoltageStandard == 48 ? '42~56' : '52~72') : '' }}
                                </p>
                            </div>
                            <div>
                                <p class="text">蓄电池安装情况是否防篡改</p>
                                <p class="isOk">
                                    {{ detailInfo.batteryTamperProof == 1 ? '是' : (detailInfo.batteryTamperProof == 2 ? '否' : '未检测') }}
                                </p>
                                <p class="describe"></p>
                            </div>
                        </div>
                </div>
                <!-- 充电器 -->
                <div class="categoryDetail">
                        <div class="subTitle">
                            充电器
                        </div>
                        <div class="content">
                            <div>
                            <p class="text">充电器与电池是否匹配</p>
                            <p class="isOk">
                                {{ detailInfo.chargerMatcheBattery == 1 ? '是' : (detailInfo.chargerMatcheBattery == 2 ? '否' : '未检测') }}
                            </p>
                            <p class="describe"></p>
                        </div>
                        <div>
                            <p class="text">充电器的风扇是否正常工作</p>
                            <p class="isOk">
                                {{ detailInfo.fanIsWork == 1 ? '是' : (detailInfo.fanIsWork == 2 ? '否' : '未检测') }}
                            </p>
                            <p class="describe"></p>
                        </div>
                        <div>
                            <p class="text">充电器电压(V)</p>
                            <p class="isOk">
                                {{detailInfo.chargerVoltage && detailInfo.chargerVoltage != 0 ? detailInfo.chargerVoltage : '未检测'}}
                                <span class="remind" v-if="detailInfo.chargerVoltage && detailInfo.chargerVoltage != 0 && detailInfo.batteryVoltageStandard == 48">
                                    {{ Number(detailInfo.chargerVoltage) > 59 ? "↑" : ( Number(detailInfo.chargerVoltage) < 42 ? '↓' : '')}}
                                </span>
                                <span class="remind" v-if="detailInfo.chargerVoltage && detailInfo.chargerVoltage != 0 && detailInfo.batteryVoltageStandard == 60">
                                    {{ Number(detailInfo.chargerVoltage) > 74 ? "↑" : ( Number(detailInfo.chargerVoltage) < 52 ? '↓' : '')}}
                                </span>
                            </p>
                            <p class="describe">
                                {{ detailInfo.batteryVoltageStandard ? (detailInfo.batteryVoltageStandard == 48 ? '42~59' : '52~74') : '' }}
                            </p>
                        </div>
                        <div>
                            <p class="text">充电器电流(A)</p>
                            <p class="isOk">
                                {{detailInfo.chargerElectricity ? detailInfo.chargerElectricity : '未检测'}}
                                <span class="remind" v-if="detailInfo.chargerElectricity">
                                    {{ Number(detailInfo.chargerElectricity) > chargeElectricStandard + 0.5 ? "↑" : (detailInfo.chargerElectricity < chargeElectricStandard - 0.5 ? '↓' : '') }}
                                </span>
                            </p>
                            <p class="describe">{{ chargeElectricStandard + '± 0.5' }}</p>
                        </div>
                        </div>
                    </div>
                </div>
          </div>
          <!-- 铅酸电池内阻特殊检测 -->
          <div class="determine">
              <h6 style="border-bottom: 0;">
                三、铅酸电池内阻特殊检测
              </h6>
              <p style="font-size: 12px;font-weight: 600;border: solid #000 1px;border-top: 0; line-height:20px;">（备注：1.一年以上电池检测项。2.参考值：80%≥ 良好；60%≥ 一般；45%≥ 需注意；45%＜ 建议更换）</p>
              <div class="right">
                  <div>
                      <p class="text">一号电池(%)</p>
                      <p class="isOk">{{ detailInfo.batteryOneStr ? detailInfo.batteryOneStr : ''}}</p>
                  </div>
                  <div>
                      <p class="text">二号电池(%)</p>
                      <p class="isOk">{{ detailInfo.batteryTwoStr ? detailInfo.batteryTwoStr : '' }} </p>
                  </div>
                  <div>
                      <p class="text">三号电池(%)</p>
                      <p class="isOk"> {{ detailInfo.batteryThreeStr ? detailInfo.batteryThreeStr : '' }}</p>
                  </div>
                  <div>
                      <p class="text">四号电池(%)</p>
                      <p class="isOk">{{ detailInfo.batteryFourStr ? detailInfo.batteryFourStr : '' }} </p>
                  </div>
                  <div>
                      <p class="text">五号电池(%)</p>
                      <p class="isOk"> {{ detailInfo.batteryFiveStr ? detailInfo.batteryFiveStr : '' }}</p>
                  </div>
                  <div>
                      <p class="text">六号电池(%)</p>
                      <p class="isOk">{{ detailInfo.batterySixStr ? detailInfo.batterySixStr : '' }} </p>
                  </div>
              </div>
          </div>
          <!-- 建议 -->
          <div class="advice">
            <h6>四、建议</h6>
            <div :class="problemList.length > 6 ? 'adviceDetail twoLine' : 'adviceDetail'">
                <p v-for="(item,index) in problemList" :key="index">{{ item }}</p>
            </div>
          </div>
          <!-- 备注 -->
          <div class="beizhu">
              <div class="left">备注</div>
              <div class="right">
                  <p>1、检测依据：GB17761-2018《电动自行车安全技术规范》</p>
                  <p>2、本检验报告是对电动助力车所有人委托车辆检验的记录，检测结果仅供参考</p>
              </div>
          </div>
          <!-- 签名 -->
          <div class="sign">
              <p>检验员：</p>
              <p>日期：{{ detailInfo.createTimeStr }}</p>
          </div>
      </div>
    </div>
  </template>
  
<script>
  import { getDetectionDetail,getDictionaryByType } from '@/api/public.js';
  import { timeChange } from '@/api/publicFun.js';
  export default {
    data() {
      return {
          detailInfo:{
            remarks:[],
          },
          Id:'',
          voltageStandsrd:"", // 电压参考值
          chargeElectricStandard:"", // 充电器电流参考值
          frontAxleBrakeStr:"", // 前轴制动运算结果
          rearAxleBrakeStr:"", // 后轴制动运算结果
          batteryValue:[ // 电池内阻计算数据
		    {name:'batteryOne',nameStr:'一号电池',label:'batteryOneStr'},
		    {name:'batteryTwo',nameStr:'二号电池',label:'batteryTwoStr'},
		    {name:'batteryThree',nameStr:'三号电池',label:'batteryThreeStr'},
		    {name:'batteryFour',nameStr:'四号电池',label:'batteryFourStr'},
		    {name:'batteryFive',nameStr:'五号电池',label:'batteryFiveStr'},
		    {name:'batterySix',nameStr:'六号电池',label:'batterySixStr'},
		  ],
		  batteryNumber:[ // 铅酸电池特殊检测不同电池容量对应计算参数 type：蓄电池容量 value：标准值
		    { type:12,value:14.4 },
		    { type:14,value:13.6 },
		    { type:20,value:10.6 },
		    { type:24,value:9.8 },
		    { type:32,value:8.6 },
		  ],
		  chargerElectricityNumber:[ // 充电器电压计算 不同电压对应计算参数 type：蓄电池电压 max：最大值 min：最小值
		  	{ type:48,max:59,min:42 },
		  	{ type:60,max:74,min:52 },
		  ],
		  batteryVoltageNumber:[ // 电池电压计算 不同电压对应计算参数 type：蓄电池电压 max：最大值 min：最小值
		  	{ type:48,max:56,min:42 },
		  	{ type:60,max:72,min:56 },
		  ],
          detectionItem:[ // 检测项列表 label检测项中文 value表单对象对应属性 type输入框类型 inputType文子输入框类型 disabled是否禁用 relation选择框关联渲染数据 error选择框选择不合格时的问题项信息
			{ // 基本信息
				title:'基本信息',
				list:[
					{label:'车牌号',value:'licencePlate',type:'input',inputType:'text',disabled:true,relation:''},
					{label:'品牌',value:'brand',type:'input',inputType:'text',disabled:true,relation:''},
					{label:'车辆所有人',value:'carMaster',type:'input',inputType:'text',disabled:true,relation:''},
					{label:'联系方式',value:'carMasterPhone',type:'input',inputType:'text',disabled:true,relation:''},
					{label:'流水号',value:'detectionNum',type:'input',inputType:'digit',disabled:false,relation:''},
					{label:'整车编码',value:'vehicleNum',type:'input',inputType:'text',disabled:false,relation:''},
					{label:'蓄电池出厂日期',value:'batteryFactoryTime',type:'datetime',disabled:false,relation:''},
					{label:'蓄电池类型',value:'batteryType',type:'checkbox',disabled:false,relation:'batteryTypeOption',error:''},
					{label:'蓄电池品牌',value:'batteryBrand',type:'select',disabled:false,relation:'batteryBrandOption'},
					{label:'蓄电池电压',value:'batteryVoltageStandard',type:'checkbox',disabled:false,relation:'batteryVoltageStandardOption',error:''},
					{label:'蓄电池容量',value:'batteryCapacity',type:'checkbox',disabled:false,relation:'batteryCapacityOption',error:''},
				]
			},
			{ // 车体外观
				title:'车体外观',
				list:[
					{label:'电动机编码',value:'motorCode',type:'input',inputType:'text',disabled:false,relation:''},
					{label:'合格证信息码',value:'certificateCode',type:'checkbox',disabled:false,relation:'hasOrNot',error:'车辆无合格证信息;'},
					{label:'铭牌信息是否完整',value:'nameplateInfoIsFull',type:'checkbox',disabled:false,relation:"yesOrNo",error:'铭牌信息不完整;'},
					{label:'车牌有无脱落隐患',value:'licencePlatIsFallOff',type:'checkbox',disabled:false,relation:'notOrHas',error:'车牌有脱落隐患;'},
					{label:'车体宽度、鞍座长度是否合格',value:'widthLengthIsStandard',type:'checkbox',disabled:false,relation:'yesOrNo',error:'车体宽度、鞍座长度不合格;'},
					{label:'前后轮是否出现变形',value:'wheelIsAmorphosis',type:'checkbox',disabled:false,relation:'noOrYes',error:"前后轮出现变形;"},
				]
			},
			{ // 车速、制动及整车质量
				title:'车速、制动及整车质量',
				list:[
					{label:'是否安装脚蹬',value:'pedalIsInstall',type:'checkbox',disabled:false,relation:'yesOrNo',error:"未安装脚踏;"},
					{label:'是否有车速提示音',value:'speedPromptTone',type:'checkbox',disabled:false,relation:'yesOrNo',error:"无车速提示音;"},
					{label:'最高车速（km/h）',value:'topSpeed',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
					{label:'前轴重（kg）',value:'frontAxleWeight',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
					{label:'前轴制动（%）',value:'frontAxleBrake',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
					{label:'后轴重（kg）',value:'rearAxleWeight',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
					{label:'后轴制动（%）',value:'rearAxleBrake',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
					{label:'整车质量（kg）',value:'vehicleMass',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
				]
			},
			{ // 线路情况
				title:'线路情况',
				list:[
					{label:'短路保护',value:'shortCircuitProtect',type:'checkbox',disabled:false,relation:'hasOrNot',error:"无短路保护;"},
					{label:'线路是否改装、老化、脱落、裸露',value:'lineProblem',type:'checkbox',disabled:false,relation:'noOrYes',error:"线路改装、老化、脱落、裸露;"},
					{label:'电池连接线布线是否合理',value:'wiringIsRational',type:'checkbox',disabled:false,relation:'yesOrNo',error:"电池连接线布线不合理;"},
				]
			},
			{ // 电池信息
				title:'电池信息',
				list:[
					{label:'是否安装指定类型蓄电池',value:'installRightBattery',type:'checkbox',disabled:false,relation:'yesOrNo',error:"未安装指定类型蓄电池;"},
					{label:'是否有充鼓、漏液、变形',value:'batteryProblem',type:'checkbox',disabled:false,relation:'noOrYes',error:"电池存在充鼓、漏液、变形;"},
					{label:'蓄电池安装情况是否防篡改',value:'batteryTamperProof',type:'checkbox',disabled:false,relation:'yesOrNo',error:"蓄电池安装无防篡改;"},
					{label:'电压（V）',value:'batteryVoltage',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
				]
			},
			{ // 充电器
				title:'充电器',
				list:[
					{label:'充电器与电池是否匹配',value:'chargerMatcheBattery',type:'checkbox',disabled:false,relation:'yesOrNo',error:"充电器与电池不匹配;"},
					{label:'充电器的风扇是否正常工作',value:'fanIsWork',type:'checkbox',disabled:false,relation:'yesOrNo',error:"充电器风扇未正常工作;"},
					{label:'充电器电压(V)',value:'chargerVoltage',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
					{label:'充电器电流(A)',value:'chargerElectricity',type:'input',inputType:'digit',disabled:false,relation:'',error:""},
				]
			},
			{ // 铅酸电池内阻特殊检测
				title:'铅酸电池内阻特殊检测',
				list:[
					{label:'一号电池(mΩ)',value:'batteryOne',type:'input',inputType:'digit',disabled:false,relation:''},
					{label:'二号电池(mΩ)',value:'batteryTwo',type:'input',inputType:'digit',disabled:false,relation:''},
					{label:'三号电池(mΩ)',value:'batteryThree',type:'input',inputType:'digit',disabled:false,relation:''},
					{label:'四号电池(mΩ)',value:'batteryFour',type:'input',inputType:'digit',disabled:false,relation:''},
					{label:'五号电池(mΩ)',value:'batteryFive',type:'input',inputType:'digit',disabled:false,relation:''},
					{label:'六号电池(mΩ)',value:'batterySix',type:'input',inputType:'digit',disabled:false,relation:''},
					
				]
			},
		],
        problemList:[],
      }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.Id = this.$route.query.id;
      this.init();
    },
  
    methods: {
      back(){
        this.$router.go(-1)
      },
      // 获取电池品牌选项
      async getBrands(){
        await getDictionaryByType({type:'BATTERY_TYPE'}).then(res=>{
            if(res.success){
              for(let i=0;i<res.data.length;i++){
                if(res.data[i].id == this.detailInfo.batteryBrand){
                    this.detailInfo.batteryBrand = res.data[i].dicValue;
                }
              }
            }else{
              this.batteryBrands = [];
            }
        })
      },
      // 初始化
      async init(){
        let data = {
          param:this.$route.query.detectionId,
        }
        await getDetectionDetail(data).then(res=>{
          if(res.success){
              this.getBrands();
              if(!res.data){
                  this.dialogVisible = true;
              }else{
                  this.detailInfo = res.data;
                  this.detailInfo.remarks = this.detailInfo.remarks ? this.detailInfo.remarks : '';
                  // 初始化电池类型，并显示对应选项
                  this.detailInfo.batteryType = this.detailInfo.batteryType == 0 ? '' : (this.detailInfo.batteryType == 1 ? '铅酸电池':'锂电池');
                  this.chargeElectricStandard = Number(this.detailInfo.batteryCapacity) / 10 ;
              }
              this.detailInfo.createTimeStr = timeChange(this.detailInfo.createTime,'day');
              this.detailInfo.batteryFactoryTime = this.detailInfo.batteryFactoryTime ? timeChange(this.detailInfo.batteryFactoryTime,'day') : '未填写';
              if(!this.detailInfo.frontAxleWeight || !this.detailInfo.rearAxleWeight){
                this.detailInfo.vehicleMass = null;
              }else{
                this.detailInfo.vehicleMass = (Number(this.detailInfo.frontAxleWeight) + Number(this.detailInfo.rearAxleWeight)).toFixed(2);
              }
              this.createProblems();
          }else{
              this.dialogVisible = true;
          }
        })
      },
      // 生成问题项
	  createProblems(){
		this.problemList = [];
		// 单选框选择不好的一项时,自动填充问题项
		this.detectionItem.forEach(element=>{
			element.list.forEach(ele=>{
				if(ele.type == 'checkbox' && this.detailInfo[ele.value] == 2){
					this.problemList.push(ele.error);
				}
			})
		})
		this.speedWeightJudge();
		this.brakeJudge();
		this.batteryVoltageStandardJudge();
		this.chargerJudge();
        this.internalResistance();
	  },
      // 铅酸内阻检测计算
	  internalResistance(){
		for(let i=0;i<this.batteryNumber.length;i++){
		    if(this.detailInfo.batteryCapacity == this.batteryNumber[i].type){
		        this.batteryValue.forEach(element => {
					let percent = Number(this.detailInfo[element.name]) ? (this.batteryNumber[i].value / Number(this.detailInfo[element.name])*100).toFixed(2) : null;
                    this.detailInfo[element.label] = percent;
					if(percent < 45 && percent){
						this.problemList.push(`${element.nameStr}建议更换；`);
					}else if(45 <= percent && percent < 60 && percent){
						this.problemList.push(`${element.nameStr}需注意；`);
					}
		        })
		        break;
		    }else{
		        this.batteryValue.forEach(element=>{
		            this.detailInfo[element.nameStr] = '';
		        })
		    }
		}
	  },
	  // 车速、重量判断
	  speedWeightJudge(){
		// 车速
		if(this.detailInfo.topSpeed && Number(this.detailInfo.topSpeed) > 25){
		    this.problemList.push("车速超过25千米每小时;");
            Number(this.detailInfo.topSpeed) > 26 ? this.problemList.push("速度篡改;") : "";
		}
		// 整车质量
		if(this.detailInfo.vehicleMass && Number(this.detailInfo.vehicleMass) > 55){
			this.problemList.push("整车质量过重;");
		}
	  },
	  // 制动判断
	  brakeJudge(){
		if(this.detailInfo.frontAxleBrake){
			if(Number(this.detailInfo.frontAxleBrake) < 60){
				this.problemList.push(`前轴制动过低：${Number(this.detailInfo.frontAxleBrake).toFixed(2)}%`);
			}
		}
		if(this.detailInfo.rearAxleBrake){
			if(Number(this.detailInfo.rearAxleBrake) < 60){
				this.problemList.push(`后轴制动过低：${Number(this.detailInfo.rearAxleBrake).toFixed(2)}%`);
			}
		}
	  },
	  // 电池电压判断
	  batteryVoltageStandardJudge(){
		if(this.detailInfo.batteryVoltage != 0 && this.detailInfo.batteryVoltage){
			for(let i=0;i<this.batteryVoltageNumber.length;i++){
				if(this.detailInfo.batteryVoltageStandard == this.batteryVoltageNumber[i].type){
					if(Number(this.detailInfo.batteryVoltage) > this.batteryVoltageNumber[i].max){
						this.problemList.push("电池电压过高;");
					}else if(Number(this.detailInfo.batteryVoltage) < this.batteryVoltageNumber[i].min){
						this.problemList.push("电池电压过低;");
					}
					break;
				}
			}
		}
	  },
	  // 充电器判断
	  chargerJudge(){
		// 充电器电流
		if(this.detailInfo.chargerElectricity != 0 && this.detailInfo.chargerElectricity){
			if(Number(this.detailInfo.chargerElectricity) > this.chargeElectricStandard + 0.5){
				this.problemList.push("充电器电流过高;");
			}else if(Number(this.detailInfo.chargerElectricity) < this.chargeElectricStandard - 0.5){
				this.problemList.push("充电器电流过低;");
			}
		}
		// 充电器电压
		if(this.detailInfo.chargerVoltage && this.detailInfo.chargerVoltage != 0){
			for(let i=0;i<this.chargerElectricityNumber.length;i++){
				if(this.detailInfo.batteryVoltageStandard == this.chargerElectricityNumber[i].type){
					if(Number(this.detailInfo.chargerVoltage) > this.chargerElectricityNumber[i].max){
						this.problemList.push("充电器电压过高;");
					}else if(Number(this.detailInfo.chargerVoltage) < this.chargerElectricityNumber[i].min){
						this.problemList.push("充电器电压过低;");
					}
					break;
				}
			}
		}
	  },
      print(){
          var oldstr = document.body.innerHTML;
          var newstr = document.getElementById("printTest").innerHTML; // 得到需要打印的元素HTML
          document.body.innerHTML = newstr;
          window.print();
          window.location.reload(); // 页面刷新
          document.body.innerHTML = oldstr;
      }
    },
  };
  </script>
<style scoped lang='scss'>
p,span,b,div,h6{
      box-sizing: border-box;
      font-size: 12px;
  }
.categoryDetail{
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    border-left: solid #000 1px;
    border-bottom: solid #000 1px;
    .subTitle{
        width: 15%;
        position: relative;
        height: 100%;
        margin: auto;
        border-top: 0;
        text-align: center;
    }
    .remind{
        display: inline-block;
        margin-left: 5px;
        color: #ff0000;
    }
    .content{
        flex: 1;
        display: flex;
        line-height: 20px;
        flex-direction: column;
       div{
        display: flex;
        width: 100%;
        border-bottom: #000 solid 1px;
       }
       div:nth-last-child(1){
        border: 0;
       }
        .text{
            width: 47%;
            box-sizing: border-box;
            padding-left: 5px;
            text-align: center;
            border-right: #000 solid 1px;
            border-left: #000 solid 1px;
            display:flex;
            align-items:center;
            justify-content:center;
        }
        .isOk{
            box-sizing: border-box;
            width: 26.5%;
            display:flex;
            align-items:center;
            justify-content:center;
            border-right: #000 solid 1px;
        }
        .describe{
            width: 26.5%;
            box-sizing: border-box;
            display:flex;
            align-items:center;
            justify-content:center;
        }
    }
}
  
  h1{
      margin-bottom: 10px;
      text-align: center;
      font-size: 30px;
  }
  #printTest{
      margin-bottom: 20px;
  }
  .remark{
      height: 20px;
      line-height: 20px;
      text-align: center;
      border: solid #000 1px;
      border-top: 0;
  }
  .el-button{
      margin: 50px;
  }
  .basic{
      display: flex;
      flex-wrap: wrap;
      h6{
          width: 100%;
          border: solid 1px #000;
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          border-bottom: none;
          padding-left: 5px;
          margin: 0;
          text-align: left;
      }
      .dislodge{
          border-right: 0;
      }
      p{
          border: solid 1px #000;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          display: flex;
          margin: 0;
          width: 50%;
          box-sizing: border-box;
          border-bottom: none;
          span{
              height: 20px;
              line-height: 20px;
              display: inline-block;
              width: 50%;
              box-sizing: border-box;
              &:nth-child(1){
                  border-right: #000 solid 1px;
              }
              padding-left: 5px;
          }
      }
  }
  p{margin: 0;}
  .result{
      h6{
          width: 100%;
          border: solid 1px #000;
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          border-bottom: none;
          padding-left: 5px;
          margin: 0;
          text-align: left;
      }
      .title{
          border-bottom: solid #000 1px;
          border-left:  #000 solid 1px;
          height: 20px;
          line-height: 20px;
          margin: 0;
          box-sizing: border-box;
          display: flex;
          box-sizing: border-box;
          font-weight: 600;
          span{
              box-sizing: border-box;
              border-right: #000 solid 1px;
              text-align: center;
              width: 22.5%;
              &:nth-child(1){
                width: 55%;
              }
              &:nth-child(3){
                  border-right: 0;
              }
          }
      }
      .detail{
          text-align: center;
          box-sizing: border-box;
          border-right: #000 1px solid;
              .classify{
                display: flex;
                width: 100%;
                align-items: center;
                border-left: solid 1px #000;
                border-bottom: solid 1px #000;
                
            }
      }
  }
  .determine{
          border: #000 solid 0  1px;
          h6{
              width: 100%;
              border: solid 1px #000;
              border-top: 0;
              height: 20px;
              line-height: 20px;
              font-size: 14px;
              padding-left: 5px;
              margin: 0;
              text-align: left;
          }
          .right{
              display: flex !important;
              flex-wrap: wrap;
              div{
                  width:50%;
                  text-align: center;
                  display: flex;
                  p{
                      height: 20px;
                      line-height: 20px;
                      border-right:#000 1px solid;
                      border-bottom: #000 1px solid;
                      width: 50%;
                  }
                  &:nth-child(1){
                      border-left:#000 1px solid;
                  }
                  &:nth-child(3){
                      border-left:#000 1px solid;
                  }
                  &:nth-child(5){
                      border-left:#000 1px solid;
                  }
              }
          }
  }
  .advice{
    h6{
        width: 100%;
        border: solid 1px  #000;
        border-top: 0;
        border-bottom: 0;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        padding-left: 5px;
        margin: 0;
        text-align: left;
    }
    .adviceDetail{
        width: 100%;
        border: solid 1px  #000;
        padding: 5px 5px;
        
    }
    .twoLine{
        display: flex;
        flex-wrap: wrap;
        p{
            width: 50%;
            text-align: left;
        }
    }
  }
  .sign{
      margin-top: 20px;
      display: flex;
      p{
          width: calc(100% / 2);
      }
      
  }
  .beizhu{
      border: solid 1px #000;
      border-top: 0;
      .left{
          height: 20px;
          line-height: 20px;
          border-bottom:solid 1px #000;
          padding-left: 5px;
          text-align: left;
      }
      .right{
          p{
              height: 20px;
              padding-left: 5px;
              text-align: left;
          }
      }
  }
  .seal{
      margin-top: 30px;
      float: right;
      padding-right: 30%;
  }
      
  </style>
  